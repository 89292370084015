import axios from '@/axios'

const RESOURCE_NAME = '/admin/auth'
import store from '@/store'

export default {

    login: function (LoginForm, code, token) {
        return axios.post(`${RESOURCE_NAME}/login`, {LoginForm, code, token})
    },

    checkToken: function () {
        let token = store.state.token
        return axios.post(`${RESOURCE_NAME}/check-token`, {token: token})
    }
}
