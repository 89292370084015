<template>
    <div>
        <div class="topbar">
            <div class="tabs tabs-primary">
                <b-nav tabs>
                    <b-nav-item @click="viewType = 'ecosystems'" :active="viewType === 'ecosystems'">
                        Ecosystems
                    </b-nav-item>

                    <b-nav-item @click="viewType = 'ecosystemAccounts'" :active="viewType === 'ecosystemAccounts'">
                        Ecosystem Accounts
                    </b-nav-item>
                </b-nav>
            </div>
            <br>

            <div class="topbar__inner">
                <div class="topbar__key">
                    <header class="buttons-container">
                        <button class="button button--sm" @click="addEcosystem">Add ecosystem</button>
                        <button class="button button--sm" @click="importEcosystems">Import ecosystems via csv</button>

                        <template v-if="selectedIds.length">
                            <select v-model="approveStatus" class="multiselect multiselect-mid">
                                <option value=""></option>
                                <option v-for="(name, id) in constants.ecosystemApproveStatuses" :key="id" :value="id">
                                    {{name}}
                                </option>
                            </select>

                            <button class="button button--sm" @click="changeApproveStatus" :disabled="!approveStatus">Change approve status</button>
                        </template>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table" style="position: relative">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"></pulse-loader>
                <Grid
                    :columns="actualColumns"
                    :data="ecosystems"
                    :has-filters="true"
                    :hasCheckboxColumn="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:selectedRowsChanged="selectedRowsChanged"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    Total number of ecosystems: {{totalRowsCount}}
                </template>
            </div>
        </div>

        <edit-ecosystem
            ref="editEcosystem"
            v-on:ecosystem-created="getList"
        />

        <import-ecosystems
            ref="importEcosystems"
            v-on:ecosystems-imported="getList"
        >
        </import-ecosystems>

        <ecosystem-relations
            ref="ecosystemRelations"
            v-on:delete="deleteEcosystem"
        >
        </ecosystem-relations>

        <funding-rounds
            ref="fundingRounds"
        />

    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import EditEcosystem from "@/components/ecosystems/EditEcosystem"
import ImportEcosystems from "@/components/ecosystems/ImportEcosystems"
import EcosystemRelations from "@/components/ecosystems/Relations"
import ecosystems from "@/services/ecosystems.service"
import locations from "@/services/locations.service";

import Vue from "vue";
import FundingRounds from "@/components/ecosystems/FundingRounds.vue";
import {defaultColumns} from "@/ecosystemsIndexConstants";
import PulseLoader from "vue-spinner/src/MoonLoader.vue";
import constants from "../../../../frontend/src/constants/constants";

export default {
    computed: {
        constants() {
            return constants
        }
    },

    components: {
        FundingRounds,
        Grid,
        EditEcosystem,
        ImportEcosystems,
        EcosystemRelations,
        PulseLoader,
    },

    mixins: [grid],

    data() {
        return {
            viewType: 'ecosystems',
            loading: false,
            ecosystems: [],
            selectedIds: [],
            status: '',
            approveStatus: '',
            sort: {
                field: 'created_at',
                dir: 'desc',
            },

            actualColumns: [],

            filters: {},
        }
    },

    watch: {
        viewType() {
            this.ecosystems = []
            this.getActualColumns()
            this.getList()
        }
    },

    mounted() {
        this.getActualColumns()
        this.getList()

        locations.getCountriesList().then(response => {
            defaultColumns[5].options = response.data
        })

        defaultColumns.find(item => item.name === "status").options =
            Object.entries(constants.ecosystemStatuses).map(item => { return {'id': item[0], 'name': item[1]}})

        defaultColumns.find(item => item.name === "approve_status").options =
            Object.entries(constants.ecosystemApproveStatuses).map(item => { return {'id': item[0], 'name': item[1]}})

        defaultColumns.find(item => item.name === "account_type").options =
            Object.entries(constants.ecosystemAccountTypes).map(item => { return {'id': item[0], 'name': item[1]}})
    },

    methods: {
        getActualColumns() {
            this.actualColumns = []

            defaultColumns.forEach(column => {
                if (
                    (['status','last_crawled'].indexOf(column.name) >= 0 && this.viewType === 'ecosystemAccounts') ||
                    (['admin_email'].indexOf(column.name) >= 0 && this.viewType === 'ecosystems') ||
                    (['enterprise_access_for_startups'].indexOf(column.name) >= 0 && this.viewType === 'ecosystems') ||
                    (['actions'].indexOf(column.name) >= 0 && this.viewType === 'ecosystemAccounts' && this.$store.state.userType === 'freelancer')
                ) {
                    return
                }

                this.actualColumns.push(column)
            })
        },

        getList() {
            let columns = this.arrayHelper.arrayColumn(this.actualColumns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort
            filters.view_type = this.viewType
            this.loading = true

            ecosystems.getEcosystems({columns, sort, currentPage, pageLength, filters: filters}).then(response => {
                this.ecosystems = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        addEcosystem() {
            this.$refs.editEcosystem.open(0)
        },

        importEcosystems() {
            this.$refs.importEcosystems.open()
        },

        selectedRowsChanged(ids) {
            this.selectedIds = ids
        },

        changeApproveStatus() {
            ecosystems.changeApproveStatus(this.selectedIds, this.approveStatus).then(() => {
                this.approveStatus = ''
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: "Status changed"
                })

                this.getList()
            })
        },

        gridAction(params) {
            if (params.action === 'edit') {
                this.$refs.editEcosystem.open(params.rowId)
            } else if (params.action === 'relations') {
                this.$refs.ecosystemRelations.open(params.rowId)
            } else if (params.action === 'funding-rounds') {
                this.$refs.fundingRounds.open(params.rowId)
            } else if (params.action === 'sync-cb') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Sync in progress...'
                })

                ecosystems.syncCB(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            } else if (params.action === 'delete') {
                if (confirm('Delete ecosystem?')) {
                    ecosystems.delete(params.rowId).then(() => {
                        this.getList();
                    })
                }
            }
        },

        deleteEcosystem(id) {
            if (confirm("Delete ecosystem?")) {
                ecosystems.delete(id).then(() => {
                    this.getList()
                    this.$refs.ecosystemRelations.close()
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
