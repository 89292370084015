<template>
    <div class="key__filter">
        <template v-for="(rule, ruleIndex) in promptRules">
            <template v-if=" rule.name !== 'word' ">
                <span
                    :key="rule.id"
                    class="key__filter__wrapper"
                >
                    <span class="key__filter__tag" :class="{
                        group__rule: typeof rule.groupWords !== 'undefined' && rule.groupWords.length > 0,
                        negative: rule.negative
                    }" >
                        <KeywordRule
                            :rule="rule"
                            :init-group-words="rule.groupWords"
                            :ai-text="query.params.text"
                            bar="prompt"
                            v-on:delete-rule="deleteRule"
                            v-on:invert-rule="invertRule"
                            v-on="$listeners"
                        />
                    </span>
                </span>
            </template>

            <template v-else >
                <span class="key__filter__simple-text" v-for="(word, wordIndex) in rule.value.split(' ').filter(word => word.trim() !== '')"  :key="`${ruleIndex}-${wordIndex}`"> {{ word }}  </span>
            </template>

        </template>

    </div>

</template>

<script>
    import KeywordRule from "./KeywordRule.vue";

    export default {
        name: 'EquationBar',

        props : {
            query: Object,
        },

        components: {
            KeywordRule,
        },

        emits: ['rule-deleted', 'invert-rule'],

        computed: {
            promptRules() {
                let rules = [];

                if (this.query.params.text) {
                    let text = this.query.params.text;

                    this.query.rules.forEach((rule, index) => {
                        text = text.replace(new RegExp(`\\b${rule.value}\\b`, 'i'), `{group-${index}}`);
                    });

                    let template = "(\\{group-\\d+\\})";
                    let parts = text.split(new RegExp(template, 'g'));

                    let regexp = /\{group-\d+}/;
                    let hasGroups = [];

                    parts.forEach(part => {
                        if (regexp.test(part)) {
                            let index = parseInt(part.match(/\d+/)[0]);

                            rules.push(this.query.rules[index]);
                            hasGroups.push(index);
                        } else {
                            rules.push({
                                name: 'word',
                                value: part,
                            });
                        }
                    });

                    this.query.rules.forEach((rule, index) => {
                        if (!hasGroups.includes(index)) {
                            rules.push(rule);
                        }
                    })
                }

                return rules;
            }
        },

        methods: {
            deleteRule: function(rule) {
                this.$emit('rule-deleted', rule, true);
            },

            invertRule: function(rule) {
                this.$emit('invert-rule', rule, true);
            },
        }
    }
</script>
