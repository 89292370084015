<template>
    <div>
        <Chart :options="chartOptions" ref="chart"/>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
export default {
    name: "SingleChart",

    props: {
        chartOptions: Object
    },

    components: {Chart}
}
</script>

<style scoped>

</style>
