<template>
    <div>
        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :columns="columns"
                    :data="jobs"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of jobs: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import startups from "@/services/startups.service";
import Vue from "vue";

export default {
    components: {
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            jobs: [],
            selectedIds: [],

            columns: [
                {
                    label: 'ID',
                    name: 'startup_id',
                    filter: 'text',
                    component: 'RawColumn',
                    sortable: true,
                },
                {
                    label: 'Name',
                    name: 'name',
                },
                {
                    label: 'URL domain',
                    name: 'url_domain',
                    component: 'RawColumn',
                },
                {
                    label: 'Created Date',
                    name: 'created_at',
                    sortable: true,
                    component: 'Date',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            addStartupData: {},
            filters: {},
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            filters['present_in_trackers'] = this.filterInTrackers

            startups.getActiveJobs({columns, sort, currentPage, pageLength, filters}).then(response => {
                this.jobs = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action == 'check-job-status') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Request in progress...'
                })

                startups.checkJobStatus(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: "Job status: " + response.data,
                    })
                })
            }

            if (params.action == 'remove-job') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Request in progress...'
                })

                startups.removeSyncJob(params.rowId).then((response) => {

                    if (response.data == 1) {
                        this.getList()

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: "Job removed",
                        })
                    }
                    else {
                        Vue.notify({
                            group: 'app',
                            type: 'error',
                            duration: 5000,
                            text: "Job not removed",
                        })
                    }
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
