<template>
    <div class="main-inner">
        <template v-if="this.startups.length">
            <h3 class="heading3">Merge startups</h3>

            <div class="overflow-auto">
                <table class="table table--stripe" style="min-width: 600px;">
                    <tr>
                        <td>Select startup that will overwrite the others</td>
                        <td v-for="startup in startups" :key="startup.id">
                            <!--<div class="input-radio">-->
                            <input type="radio" name="selectedId" :value="startup.id" v-model="selectedId">

                            <a class="link ml-2" v-if="selectedId !== startup.id && startups.length > 2" @click="() => {exclude(startup.id)}">Exclude from merge</a>
                                <!--<label for=""></label>-->
                            <!--</div>-->
                        </td>
                    </tr>

                    <tr v-for="(field, index) in startupsfields" :key="index">
                        <td>
                            {{field.name}}
                        </td>
                        <td v-for="startup in startups" :key="startup.id">
                            <a class="link" v-if="field.field == 'contactsDetailsCount'" @click="showContactsDetails(startup.id)">{{field.startups[startup.id]}}</a>

                            <a class="link" v-else-if="field.field == 'name'" target="_blank" :href="mainDomain + '/startups/' + startup.id">{{field.startups[startup.id]}}</a>

                            <template v-else>{{field.startups[startup.id]}}</template>
                        </td>
                    </tr>

                    <tr v-if="conflictFieldsCount">
                        <td>Conflicting Custom Fields</td>
                        <td :colspan="startups.length"><a class="link" @click="$bvModal.show('modal-custom-fields')">{{conflictFieldsCount}}</a></td>
                    </tr>

                    <tr v-if="conflictReviewsCount">
                        <td>Conflicting Reviews</td>
                        <td :colspan="startups.length">{{conflictReviewsCount}} <span style="color: red"><br>Conflicting reviews will not be merged</span></td>
                    </tr>

                    <tr>
                        <td></td>
                        <td v-for="startup in startups" :key="startup.id">
                            <a @click="showRelations(startup.id)">Relations</a>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="text-center">
                <b-button variant="primary" size="lg" @click="merge">Merge</b-button>
            </div>

            <b-modal id="modal-custom-fields" :hide-footer="true" modal-class="modal-w-full" title="Conflicting Custom Fields">
                <div class="overflow-auto">
                    <div v-for="(enterprise, enterpriseId) in conflictFields" :key="enterpriseId">
                        {{enterprise.name}}
                        <table class="table">
                            <tr>
                                <th></th>
                                <th v-for="startup in startups" :key="startup.id">{{startup.name}}</th>
                            </tr>
                            <tr v-for="(field, fieldId) in enterprise.fields" :key="fieldId">
                                <td>{{field.name}}</td>
                                <td v-for="startup in startups" :key="startup.id">
                                    <div v-if="typeof field.values[startup.id] != 'undefined'">
                                        <custom-field
                                            :field="field.values[startup.id].field"
                                            :value="field.values[startup.id].value"
                                            :editable="false"
                                            :disabled="true"
                                        >
                                        </custom-field>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </b-modal>

            <b-modal id="modal-contacts-details" :hide-footer="true" modal-class="modal-w-md" title="Custom Contact Details">
                <div class="pb-3" v-if="currentId">
                    <div v-for="(contact, index) in contactsDetails[currentId]" :key="index">
                        <h4>{{contact.name}}</h4>
                        <table class="table">
                            <tr>
                                <th>Company</th>
                                <th>Type</th>
                                <th>Value</th>
                            </tr>
                            <tr v-for="(detail, index) in contact.details" :key="index">
                                <td>
                                    {{detail.enterprise}}
                                </td>
                                <td>
                                    {{detail.type}}
                                </td>
                                <td>
                                    {{detail.value}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </b-modal>
        </template>

        <startup-relations
            ref="startupRelations"
            v-on:delete-startup="deleteStartup"
        />
    </div>
</template>

<script>
import mergeStartups from "@/services/merge-startups.service"
import CustomField from "../../../../frontend/src/components/customFields/CustomField";
import Vue from "vue";
import StartupRelations from "@/components/startups/Relations.vue";
import startupsService from "@/services/startups.service";

export default {
    components: {
        StartupRelations,
        CustomField
    },

    data() {
        return {
            url: this.$route.query.url ? this.$route.query.url : '',
            mergeIds: this.$route.query.id ? this.$route.query.id : '',
            startups: [],
            startupsfields: [],
            conflictFields: [],
            conflictFieldsCount: 0,
            conflictReviewsCount: 0,
            contactsDetails: {},
            selectedId: 0,
            currentId: 0,
        }
    },

    computed: {
        registeredStartupsIds() {
            return this.startups.filter(startup => startup.has_admin).map(startup => startup.id)
        }
    },

    mounted() {
        this.getStartups()
    },

    methods: {
        getStartups() {
            if (this.url || this.mergeIds) {
                mergeStartups.getMergeData(this.url, this.mergeIds).then(response => {
                    this.startups = response.data.startups_data
                    this.startupsfields = response.data.startups_fields
                    this.conflictFields = response.data.conflict_fields
                    this.conflictFieldsCount = response.data.conflict_fields_count
                    this.conflictReviewsCount = response.data.conflict_reviews_count
                    this.contactsDetails = response.data.contacts_details
                    if (this.startups.length) {
                        this.selectedId = this.startups[0].id
                    }
                })
            }
        },

        merge() {
            if (this.registeredStartupsIds.length && !this.registeredStartupsIds.includes(this.selectedId)) {
                alert("You can only select registered company: #" + this.registeredStartupsIds[0]);
            } else {
                let otherIds = this.startups.filter(startup => startup.id !== this.selectedId).map(startup => startup.id)

                mergeStartups.merge(this.selectedId, otherIds).then(() => {
                    this.startups = [];

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: 'Startups have been merged.'
                    })
                })
            }
        },

        showContactsDetails(id) {
            this.currentId = id
            this.$bvModal.show('modal-contacts-details')
        },

        showRelations(id) {
            this.$refs.startupRelations.open(id)
        },

        exclude(id) {
            this.startups = this.startups.filter(item => item.id !== id);
        },

        deleteStartup(id) {
            if (confirm("Delete startup?")) {
                startupsService.delete(id).then(() => {
                    this.getStartups();
                    this.$refs.startupRelations.close()
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
