import axios from '@/axios';

const RESOURCE_NAME = '/files';

export default {

    downloadCustomFile(id) {
        return axios.get(`${RESOURCE_NAME}/download-custom-file?id=${id}`, {
            responseType: 'blob',
        })
    },

    getFileName(id)  {
        return axios.get(`${RESOURCE_NAME}/get-file-name?id=${id}`)
    },

    deleteCustomFile(id) {
        return axios.post(`${RESOURCE_NAME}/delete-custom-file?id=${id}`)
    },

    deleteTempLogoFiles(fileNames) {
        return axios.post(`${RESOURCE_NAME}/delete-temp-logo-files`, {fileNames: fileNames})
    },
}
