<template>
    <SingleChart v-if="loaded" :chart-options="chartOptions"/>

    <div v-else class="d-flex justify-content-center">
        <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
    </div>
</template>

<script>
import SingleChart from "@/components/SingleChart.vue";
import consoleCommandsService from "@/services/console-commands.service";

export default {
    name: "ConsoleCommandChart",
    props: {
        commands: Array,
        groupName: String,
        period: Array,
    },

    components: {SingleChart},
    data() {
        return {
            chartOptions: {
                title: {
                    text: this.groupName,
                },

                xAxis: {
                    categories: [],
                },

                plotOptions: {
                    series: {
                        connectNulls: true,
                    }
                },

                series: [],
            },

            loaded: false,
        }
    },

    watch: {
        period() {
            this.loadData();
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        loadData() {
            let period = [
                this.dateHelper.encodeDateForServer(this.period[0]),
                this.dateHelper.encodeDateForServer(this.period[1]),
            ];

            consoleCommandsService.getChartData(this.commands, period).then(response => {
                let [series, categories] = response.data;
                this.chartOptions.series = series;
                this.chartOptions.xAxis.categories = categories;

                this.loaded = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
