<template>
    <b-modal id="modal-enterprise-form" modal-class="modal-w-md" title="Enterprise Profile">
        <form class="form pb-5">

            <div class="d-flex align-items-center mb-1">
                <div class="image-block image-block--rounded--lg mb-4">
                    <img v-if="this.form.logo" :src="this.form.logo" alt="img">
                </div>
                <div>
                    <a @click="showLogoUpload = true" class="btn btn-primary">Upload Logo</a>
                    <p class="modal-text m-0">Image size: 200 X 200 pixels, max file size 5 mb</p>
                </div>
            </div>

            <image-upload
                v-model="showLogoUpload"
                :width="200"
                :height="200"
                :noSquare="true"
                langType="en"
                @crop-success="logoCropped"
            >
            </image-upload>

            <div v-if="id === 0" class="form__item input-checkbox">
                <input id="createAccount" type="checkbox" v-model="createAccount">
                <label for="createAccount">Create Account</label>
            </div>

            <template v-if="createAccount">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Admin Email*</h5>
                    <span class="form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
                    <input class="form-control--mid form-control" type="text" v-model="form.email">
                </div>

                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Admin Password*</h5>
                    <span class="form__error" v-for="(error, index) in errors.password" :key="index">{{error}}</span>
                    <input class="form-control--mid form-control" type="password" v-model="form.password">
                </div>

                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Confirm Password*</h5>
                    <span class="form__error" v-for="(error, index) in errors.confirm_password" :key="index">{{error}}</span>
                    <input class="form-control--mid form-control" type="password" v-model="form.confirm_password">
                </div>
            </template>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Company Name*</h5>
                <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="text" v-model="form.name">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Company URL*</h5>
                <span class="form__error" v-for="(error, index) in errors.company_url" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="text" v-model="form.company_url">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Description</h5>
                <textarea class="form-control--mid form-control" v-model="form.description"></textarea>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Account Type</h5>
                <select class="form-control--mid form-control" v-model="form.account_type">
                    <option v-for="(label, accountType) in licenseTypes" :key="accountType" :value="accountType">{{label}}</option>
                </select>
            </div>

            <div v-if="form.account_type === 'event_limited'" class="form__item">
                <h5 class="heading5 heading5--secondary">Ecosystem*</h5>
                <multiselect
                    v-model="eventEcosystem"
                    placeholder="Select Ecosystem"
                    :options="ecosystemsList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateEcosystemsList"
                >
                </multiselect>
            </div>

            <div v-else class="form__item">
                <h5 class="heading5 heading5--secondary">Maximum Users Allowed:</h5>
                <span class="form__error" v-for="(error, index) in errors.max_users" :key="index">{{error}}</span>
                <input class="form-control--mid form-control"  v-model="form.max_users">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Country</h5>
                <multiselect
                    v-model="country"
                    placeholder="Select Country"
                    :options="countriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item" v-if="form.country_id === 1">
                <h5 class="heading5 heading5--secondary">State</h5>

                <multiselect
                    v-model="state"
                    placeholder="Select State"
                    :options="statesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Categories</h5>
                <multiselect
                    v-model="categories"
                    :multiple="true"
                    placeholder="Select Categories"
                    :options="categoriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item input-checkbox">
                <input id="search_big_companies" type="checkbox" v-model="form.search_big_companies">
                <label for="search_big_companies">Search Big Companies</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="show_fields_history" type="checkbox" v-model="form.show_fields_history">
                <label for="show_fields_history">Show fields History</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="hidden" type="checkbox" v-model="form.hidden">
                <label for="hidden">Hidden from Ecosystems</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="hidden_for_startups" type="checkbox" v-model="form.hidden_for_startups">
                <label for="hidden_for_startups">Hidden from Startups</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="disable_analyst_hours" type="checkbox" v-model="form.disable_analyst_hours">
                <label for="disable_analyst_hours">Disable Analyst Hours</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="disable_external_messaging" type="checkbox" v-model="form.disable_external_messaging">
                <label for="disable_external_messaging">Disable External Messaging</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="disable_connections_by_nonadmins" type="checkbox" v-model="form.disable_connections_by_nonadmins">
                <label for="disable_connections_by_nonadmins">Disable Connections by Non-Admins</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="allow_empty_review_comments" type="checkbox" v-model="form.allow_empty_review_comments">
                <label for="allow_empty_review_comments">Allow empty review comments</label>
            </div>

            <div class="form__item input-checkbox" v-if="['paid','trial','test'].includes(form.account_type)">
                <input id="premium_charts" type="checkbox" v-model="form.premium_charts">
                <label for="premium_charts">Premium Charts</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="custom_charts" type="checkbox" v-model="form.custom_charts">
                <label for="custom_charts">Custom Charts</label>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Users Feed Access</h5>
                <select class="form-control--mid form-control" v-model="form.users_feed_access">
                    <option value="full">Full</option>
                    <option value="limited">Limited</option>
                    <option value="disabled">Disabled</option>
                </select>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Profile text</h5>
                <textarea class="form-control--mid form-control" v-model="form.profile_text">
                </textarea>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Client of Agency</h5>
                <multiselect
                    v-model="agencyEnterprise"
                    placeholder="Select Agency"
                    :options="agenciesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

        </form>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">
                <span v-if="!loading">Save</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import enterprises from "@/services/enterprises.service"
import multiselect from "vue-multiselect";
import categories from "@/services/categories.service";
import locations from "@/services/locations.service";
import imageUpload from "vue-image-crop-upload";
import {ACCOUNT_TYPE_EVENT_LIMITED, licenseTypes} from "../../../../frontend/src/constants/accountTypes";
import ecosystemsService from "@/services/ecosystems.service";
import enterprisesService from "@/services/enterprises.service";

export default {
    components: {
        multiselect,
        imageUpload
    },

    data() {
        return {
            id: 0,
            form: {
                logo: "",
                name: "",
                company_url: "",
                description: "",
                account_type: "",
                max_users: "",
                country_id: "",
                state_id: "",
                categories: [],
                search_big_companies: false,
                hidden: false,
                hidden_for_startups: false,
                users_feed_access: "limited",
                profile_text: "",
                disable_analyst_hours: false,
                disable_external_messaging: false,
                disable_connections_by_nonadmins: false,
                allow_empty_review_comments: false,
                premium_charts: false,
                custom_charts: false,
                email: "",
                password: "",
                confirm_password: "",
            },

            country: null,
            state: null,
            eventEcosystem: null,
            agencyEnterprise: null,

            showLogoUpload: false,
            logoChanged: false,

            categories: [],
            categoriesList: [],
            countriesList: [],
            statesList: [],
            ecosystemsList: [],
            agenciesList: [],
            createAccount: false,

            loading: false,
            errors: {
                name: [],
                company_url: [],
                max_users: [],
                email: [],
                password: [],
                confirm_password: [],
            },
        }
    },

    watch: {
        country() {
            if (this.country) {
                this.form.country_id = this.country.id
            } else {
                this.form.country_id = 0
            }
        },

        state() {
            if (this.state) {
                this.form.state_id = this.state.id
            }
            else {
                this.form.state_id = 0
            }
        },

        createAccount() {
            if (!this.createAccount) {
                this.form.email = ""
                this.form.password = ""
                this.form.confirm_password = ""
            }
        },

        'form.account_type'() {
            if (this.form.account_type === ACCOUNT_TYPE_EVENT_LIMITED) {
                this.form.search_big_companies = true;
            }
        }
    },

    computed: {
        licenseTypes() {
            return licenseTypes
        },

        canSave() {
            if (this.loading) {
                return false
            } else {
                return this.form.name.length && this.form.company_url.length && (
                    !this.createAccount || (this.form.email.length && this.form.password.length && this.form.confirm_password.length)
                ) && !(this.form.account_type === 'event_limited' && !this.eventEcosystem);
            }
        }
    },

    mounted() {
        categories.getList().then(response => {
            this.categoriesList = response.data
            this.setCategories()
        })

        locations.getCountriesList().then(response => {
            this.countriesList = response.data
            this.setCountry()
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
            this.setState()
        })

        this.getAgenciesList();
    },

    methods: {
        init() {
            this.form.logo = "";
            this.form.name = "";
            this.form.company_url = "";
            this.form.description = "";
            this.form.account_type = "";
            this.form.max_users = "";
            this.form.country_id = "";
            this.form.state_id = "";
            this.form.categories = [];
            this.form.search_big_companies = false;
            this.form.hidden = false;
            this.form.disable_analyst_hours = false;
            this.form.disable_external_messaging = false;
            this.form.disable_connections_by_nonadmins = false;
            this.form.premium_charts = false;
            this.form.custom_charts = false;
            this.form.users_feed_access = "limited";
            this.logoChanged = false;
            this.profile_text = "";
            this.createAccount = false;
            this.eventEcosystem = null;
            this.agencyEnterprise = null;

            this.country = null;
            this.state = null;

            this.loading = false;

            this.clearErrors();

            if (this.id > 0) {
                this.getData();
            }
        },

        open(id) {
            this.id = id

            this.init()
            this.$bvModal.show('modal-enterprise-form')
        },

        async getData() {
            const {data} = await enterprises.getEdit(this.id);
            this.form = data;

            if (this.form.event_ecosystem_id) {
                this.setEcosystem(this.form.event_ecosystem_id);
            }

            if (this.form.agency_id) {
                this.setAgency(this.form.agency_id);
            }

            this.setCategories();
            this.setCountry();
            this.setState();
        },

        clearErrors() {
            for (let i in this.errors) {
                this.errors[i] = ""
            }
        },

        save() {
            this.loading = true
            this.form.categories = this.categories

            if (this.logoChanged) {
                this.form.newLogo = this.form.logo
            }

            this.form.event_ecosystem_id = this.eventEcosystem?.id;
            this.form.agency_id = this.agencyEnterprise?.id;

            enterprises.save(this.id, this.form).then(response => {
                this.clearErrors()

                for (let attr in response.data.errors) {
                    this.errors[attr] = response.data.errors[attr]
                }

                if (!response.data.has_errors) {
                    this.$emit('saved')
                    this.$bvModal.hide('modal-enterprise-form')
                }
            }).finally(() => {
                this.loading = false
            })
        },

        setCategories() {
            this.categories = []

            if (!this.categories.length && this.form.categories.length) {
                this.form.categories.forEach(id => {
                    let category = this.categoriesList.find(item => id === item.id);

                    if (category) {
                        this.categories.push(category)
                    }
                })
            }
        },

        setCountry() {
            if (!this.country && this.form.country_id) {
                this.countriesList.forEach(item => {
                    if (item.id === this.form.country_id) {
                        this.country = item
                        return
                    }
                })
            }
        },

        setState() {
            if (!this.state && this.form.state_id) {
                this.statesList.forEach(item => {
                    if (item.id === this.form.state_id) {
                        this.state = item
                        return
                    }
                })
            }
        },

        async setEcosystem(ecosystemId) {
            const {data} = await ecosystemsService.getData(ecosystemId);
            this.eventEcosystem = data;
        },

        async setAgency(agencyId) {
            const {data} = await enterprisesService.getData(agencyId);
            this.agencyEnterprise = data;
        },

        logoCropped(imageDataUrl) {
            this.form.logo = imageDataUrl
            this.showLogoUpload = false
            this.logoChanged = true
        },

        async updateEcosystemsList(query) {
            const {data} = await ecosystemsService.search(query);
            this.ecosystemsList = data;
        },

        async getAgenciesList() {
            const {data} = await enterprisesService.getAgencies();
            this.agenciesList = data;
        }
    }
}
</script>

<style scoped>

</style>
