<template>
    <div>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="requests"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>

        <b-modal id="view-request" modal-class="modal-w-md" title="Invite Enterprise">
            <form class="form">
                <span class="form__error" v-for="(error, index) in arrayHelper.getSingleArrayFromAssoc(errors)" :key="index">{{error}}</span>

                <template v-if="request.inviter === 'ecosystem'">
                    <h6 class="heading6">Enter an email to contact {{inviteEnterpriseName}}</h6>
                    <b-form-input class="form-control--mid" v-model="inviteEmail" placeholder="Enter Email"></b-form-input>
                </template>

                <template v-else>
                    <h6 class="heading6">Startup Message</h6>

                    <p class="modal-text mb-2">{{requestData.message}}</p>

                    <div v-if="requestData.files?.length" class="tags-list tags-list--light">
                        Attachments:
                        <span v-for="(file, index) in requestData.files" :key="index" class="tags-list__tag ml-2">
                            <a :title="file.file_name" @click="() => {downloadAttachment(file.id, file.file_name)}">{{file.file_name}}</a>
                        </span>
                    </div>

                    <p><button v-if="!loading" type="button" class="button button--red" @click="reject">Reject</button></p>

                    <h6 class="heading6">Add Message</h6>
                    <b-form-textarea class="form-control--mid" v-model="adminMessage"></b-form-textarea>

                    <template v-if="requestData.users?.length">
                        <h6 class="heading6">Select Users</h6>
                        <ul class="select-chat-list">
                            <li v-for="user in requestData.users" :key="user.id">
                                <div class="input-checkbox">
                                    <input :id="'user-'+user.id" type="checkbox" :value="user"
                                           v-model="selectedUsers">
                                    <label :for="'user-'+user.id"></label>
                                </div>

                                <div>
                                    <h4 class="heading4"><template>{{ user.name ?? user.email }}</template></h4>
                                    <p v-if="user.name">{{ user.email }}</p>
                                    <p>{{ user.job_title }}</p>
                                </div>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <h6 class="heading6">Enter an email to contact {{inviteEnterpriseName}}</h6>
                        <b-form-input class="form-control--mid" v-model="inviteEmail" placeholder="Enter Email"></b-form-input>
                    </template>
                </template>
            </form>

            <template #modal-footer>
                <primary-button
                    @click="invite"
                    :loading="loading"
                    :disabled="!canInvite"
                    caption="Invite"
                    loading-caption="Sending..."
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import connectionRequests from "@/services/connection-requests.service"
import enterprises from "@/services/enterprises.service";
import Vue from "vue";
import connectionRequestsService from "@/services/connection-requests.service";
import files from "@/services/files.service";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";

export default {
    components: {
        PrimaryButton,
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            requests: [],

            columns: [
                {
                    label: 'Ecosystem',
                    name: 'ecosystem_name',
                    filter: 'text',
                    component: 'RawColumn',
                },
                {
                    label: 'Startup',
                    name: 'startup_name',
                    filter: 'text',
                    component: 'RawColumn',
                },
                {
                    label: 'Enterprise domain',
                    name: 'enterprise_domain',
                    component: 'RawColumn',
                },
                {
                    label: 'Enterprise name',
                    name: 'enterprise_name',
                    component: 'RawColumn',
                },
                {
                    label: 'Requested By',
                    name: 'user_name',
                },
                {
                    label: 'Requested By Email',
                    name: 'user_email',
                },
                {
                    label: 'Data',
                    name: 'date',
                },
                {
                    label: 'Contact Email',
                    name: 'contact_email',
                },
                {
                    label: 'Contact Name',
                    name: 'contact_name',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},
            inviteEmail: "",
            request: {},
            requestData: {},
            inviteEnterpriseName: "",
            loading: false,
            errors: {},

            adminMessage: '',
            selectedUsers: [],
        }
    },

    mounted() {
        this.getList()
    },

    computed: {
        canInvite() {
            return this.stringHelper.validEmail(this.inviteEmail) || this.selectedUsers.length;
        },
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name');
            let currentPage = this.currentPage;
            let pageLength = this.pageLength;
            let filters = {...this.filters, inviter: ['startup','ecosystem']};
            let sort = this.sort;

            connectionRequests.getRequests({columns, sort, currentPage, pageLength, filters}).then(response => {
                this.requests = response.data.data;
                this.totalRowsCount = parseInt(response.data.total_count);
                this.currentPage = response.data.current_page;
            })
        },

        changeFilters(filters) {
            this.filters = filters;
            this.getList();
        },

        gridAction(params) {
            this.errors = {}

            if (params.action === 'invite') {
                this.request = this.requests.find(item => item.id === params.rowId)

                if (this.request.inviter === 'startup') {
                    connectionRequestsService.getRequest(this.request.id).then(response => {
                        this.requestData = response.data
                        this.$bvModal.show('view-request')
                    })
                } else {
                    this.inviteEnterpriseName = this.request.name
                    this.inviteEmail = this.request.contact_email
                    this.$bvModal.show('view-request')
                }
            }

            else if (params.action === 'delete') {
                if (confirm("Delete connection request?")) {
                    this.deleteRequest(params.rowId)
                }
            }
        },

        invite() {
            this.loading = true;
            let data = {
                request_id: this.request.id,
                email: this.inviteEmail
            };

            if (this.request.inviter === 'startup') {
                data = {
                    ...data,
                    startup_message: this.request.message,
                    admin_message: this.adminMessage,
                    users: this.selectedUsers.map(item => item.id),
                }
            }

            enterprises.invite(data).then(response => {
                this.errors = response.data.errors

                if (!Object.keys(this.errors).length) {
                    this.$bvModal.hide('view-request')

                    connectionRequests.setProcessed(this.request.id).then(() => {
                        this.getList()
                    })

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 1000,
                        text: 'Invitation Sent'
                    })
                }
            }).finally(() => {
                this.loading = false;
            })
        },

        reject() {
            this.loading = true;

            connectionRequests.setRejected(this.request.id).then(() => {
                this.$bvModal.hide('view-request')
                this.getList()

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Request Rejected'
                })
            }).finally(() => {
                this.loading = false;
            })
        },

        deleteRequest(id) {
            connectionRequests.delete(id).then(() => {
                this.getList()
            })
        },

        downloadAttachment(id, name) {
            files.downloadAttachment(id).then(response => {
                this.fileHelper.download(response.data, name)
            })
        }
    }
}
</script>

<style scoped>

</style>
