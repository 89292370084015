import axios from '@/axios';

const RESOURCE_NAME = '/admin/coupons';

export default {

    getCoupons(data) {
        return axios.post(`${RESOURCE_NAME}/get-coupons`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },
}
