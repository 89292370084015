import axios from '@/axios';

const RESOURCE_NAME = '/admin/ecosystems';

export default {

    getEcosystems(data) {
        return axios.post(`${RESOURCE_NAME}/get-ecosystems`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    getData(id) {
        return axios.get(`${RESOURCE_NAME}/get-data?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    getStartups(ecosystemId, data) {
        return axios.post(`${RESOURCE_NAME}/get-startups?id=${ecosystemId}`, data)
    },

    changeApproveStatus(ids, status) {
        return axios.post(`${RESOURCE_NAME}/change-approve-status`, {ids: ids, status: status})
    },

    syncCB(id) {
        return axios.post(`${RESOURCE_NAME}/sync-cb?id=${id}`)
    },

    addMultipleEcosystems(data) {
        return axios.post(`${RESOURCE_NAME}/add-multiple-ecosystems`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    getRelations(id) {
        return axios.get(`${RESOURCE_NAME}/get-relations?id=${id}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-ecosystem?id=${id}`)
    },

    removeStartup(id, startupId) {
        return axios.post(`${RESOURCE_NAME}/remove-startup?id=${id}`, {startup_id: startupId})
    },

    invite(data) {
        return axios.post(`${RESOURCE_NAME}/invite`, data)
    },

    search(query) {
        return axios.get(`${RESOURCE_NAME}/search?query=${query}`)
    },

    getFundingRounds(id, filters, currentPage, pageLength) {
        return axios.post(`${RESOURCE_NAME}/get-funding-rounds?id=${id}`, {
            filters,
            pageLength,
            currentPage
        });
    },
}
