<template>
    <!--<div :class="{'main-table-wrapper': needWrapper, 'main-table-wrapper--light': isStartupsCompanyType}">-->
    <div  :class="{'main-table-wrapper': needWrapper, 'main-table-wrapper--light':isLightTheme}">
        <table class="main-table" id="main-grid" ref="grid">
            <thead>
                <tr>
                    <th v-if="hasCheckboxColumn && data.length" class="main-table__check">
                        <div class="input-checkbox">
                            <input id="input-checkbox__all" type="checkbox" v-model="selectAllChecked">
                            <label for="input-checkbox__all"></label>
                            <div id="selectAllCheckbox">-</div>
                        </div>
    
                        <b-popover placement="bottom" ref="selectAllCheckboxPopover" target="selectAllCheckbox">
                            <div class="popover__content" v-click-outside="closeAllCheckboxPopover">
                                <div class="popover__content__body">
                                    <ul class="simple-list">
                                        <li>
                                            <a @click="selectAll(false)">
                                                <font-awesome-icon :icon="['fa', 'check-circle']" />
                                                Select all {{data.length}} results on this page
                                            </a>
                                        </li>
    
                                        <li v-if="totalRowsCount > data.length && !notSelectWhole">
                                            <a  @click="selectAll(true)">
                                                <font-awesome-icon :icon="['fa', 'check-circle']" />
                                                <template v-if="totalRowsCount > 1000 && !selectAllRows">
                                                    Select first 1000 results
                                                </template>
                                                <template v-else>
                                                    Select all {{totalRowsCount}} results
                                                </template>
    
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </b-popover>
                    </th>
    
                    <th :class="{'main-table__title__sortable': sort.field === column.name}" v-for="column in columns" :key="column.name" :title="column.label">
                        <div v-if="column.sortable" class="main-table__title" @click="changeSort(column.name)">
                            {{column.label}}

                            <div v-if="column.name === 'irl_score'" class="hint-question" @click.stop="showIRLExplainModal"></div>
                            <a v-if="sort.field === column.name && sort.dir === 'asc'"><i class="chevron-up"></i></a>
                            <a v-if="sort.field === column.name && sort.dir === 'desc'"><i class="chevron-down"></i></a>
                            <a class="main-table__title__arrow" v-if="sort.field != column.name"><i class="chevron-down"></i></a>
                        </div>
    
                        <div v-else>
                            {{column.label}}
                        </div>
                    </th>
                    <th class="main-table__empty-col"></th>
                </tr>
    
                <tr v-if="hasFilters">
                    <th v-if="hasCheckboxColumn && data.length"></th>
    
                    <th v-for="(filter, index) in filters" :key="index">
                        <input class="form-control form-control--mid" v-if="filter.type === 'text'" v-model="filters[index].value" @keyup="filtersChanged(filter.type)">
    
                        <multiselect v-if="filter.type === 'select'"
                             v-model="filters[index].value"
                             :options="filter.options"
                             :multiple="true"
                             select-label=""
                             deselect-label=""
                             label="name"
                             track-by="name"
                             @input="filtersChanged(filter.type)"
                             class=""
                        >
                        </multiselect>
    
                        <date-picker @change="filtersChanged(filter.type)" class="form-control" v-if="filter.type === 'date-range'" v-model="filters[index].value" range :default-value="[new Date(Date.now()-1000*60*60*24*365), new Date(Date.now())]"></date-picker>
    
                        <select @change="filtersChanged(filter.type)" class="multiselect multiselect-mid" v-if="filter.type === 'single-select' && filter.options.length" v-model="filters[index].value">
                            <option value=""></option>
                            <option v-for="option in filter.options" :key="option.id" :value="option.id">
                                {{option.name}}
                            </option>
                        </select>
                    </th>
                </tr>
            </thead>

            <draggable v-if="draggable" tag="tbody" v-model="draggableData">
                <tr v-for="(row, index) in draggableData" :key="index" class="main-table__dragable">
                    <td  v-for="column in columns" :key="column.name">

                        <component v-if="typeof column.component !== 'undefined'"
                                   :is="column.component"
                                   :columnData="row[column.name]"
                                   :addData="addCellData"
                                   :rowId="parseInt(row.id)"
                                   v-on:action="action"
                                   :columnRawData="row"
                        />

                        <!--<template v-else>
                            <div v-if="typeof row[column.name] != 'undefined'" :style="{'min-width': columnWidth(column)}">
                                <v-clamp autoresize :max-lines="3"> {{row[column.name]}}
                                    <template #after="{ toggle, expanded, clamped }">
                                        <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                        <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                    </template>
                                </v-clamp>
                            </div>
                        </template>-->
                        <template v-else>
                            <div class="main-table__dragable" v-if="typeof row[column.name] != 'undefined'">
                                <v-clamp autoresize :max-lines="3"> {{row[column.name]}}
                                    <template #after="{ toggle, expanded, clamped }">
                                        <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                        <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                    </template>
                                </v-clamp>
                            </div>
                        </template>
                    </td>
                </tr>
            </draggable>

            <tbody v-else>
                <template v-if="isStartupsCompanyType">
                    <template v-for="(row, index) in data">
                        <tr :key="'empty-' + index" class="main-table__empty-row">
                            <td colspan="100%"></td>
                        </tr>

                        <tr :key="index" :class="{'main-table__row-joined': row.ecosystem_account}">
                            <td v-if="hasCheckboxColumn" class="main-table__check">
                                <div class="input-checkbox">
                                    <input :id="'t' + index" type="checkbox" v-model="selectedRows[index]">
                                    <label :for="'t' + index"></label>
                                </div>
                            </td>

                            <td v-for="column in columns" :key="column.name">
                                <component v-if="row[column.name] && typeof row[column.name].component !== 'undefined'"
                                           :is="row[column.name].component"
                                           :columnData="row[column.name].data"
                                           :addData="addCellData"
                                           :rowId="parseInt(row.id)"
                                           v-on:action="action"
                                           :columnRawData="row"
                                />

                                <component v-else-if="typeof column.component !== 'undefined' && row[column.name]"
                                           :is="column.component"
                                           :columnData="row[column.name]"
                                           :addData="addCellData"
                                           :rowId="parseInt(row.id)"
                                           v-on:action="action"
                                           :columnRawData="row"
                                />

                                <div v-else-if="typeof row[column.name] != 'undefined'" :style="{'min-width': columnWidth(column)}">
                                    <v-clamp autoresize :max-lines="6"> {{row[column.name]}}
                                        <template #after="{ toggle, expanded, clamped }">
                                            <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                            <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                        </template>
                                    </v-clamp>
                                </div>
                            </td>
                            <td class="main-table__empty-col"></td>
                        </tr>

                        <tr v-if="row.ecosystem_account" :key="'ecosystem-account-' + index">
                            <td>&nbsp;</td>
                            <td class="startup-send-message-row" :colspan="columns.length">
                                <div class="startup-send-message-wrapper">
                                    <div class="startup-send-message">
                                        <p>
                                            <span class="startup-send-message__inner">
                                                {{ row.name.name }} <span class="startup-send-message__inner__text"></span> <a :href="'/ecosystems/' + row.ecosystem_account.id" target="_blank">{{ row.ecosystem_account.name }}</a> <span class="startup-send-message__inner__text2"></span>
                                            </span>
                                        </p>

                                        <button v-if="!$store.state.user.settings.disable_external_messaging" @click="$emit('action', {action: 'send-message', 'rowId': row.ecosystem_account.id})" class="button button--bordered--with--icon button--send_a_message w-auto">Send A Message </button>
                                    </div>
                                </div>
                            </td>
                            <td class="main-table__empty-col">&nbsp;</td>
                        </tr>
                    </template>
                </template>

                <template v-else>
                    <template v-for="(row, index) in data">
                        <tr :key="'empty-' + index" class="main-table__empty-row">
                            <td colspan="100%"></td>
                        </tr>
                        <tr :key="index" :class="{'main-table__row-joined': row.greeting, 'alert-danger': row.red}">
                            <td v-if="hasCheckboxColumn" class="main-table__check">
                                <div class="input-checkbox">
                                    <input :id="'t' + index" type="checkbox" v-model="selectedRows[index]">
                                    <label :for="'t' + index"></label>
                                </div>
                            </td>

                            <td v-for="column in columns" :key="column.name">
                                <template v-if="row[column.name]">
                                    <component v-if="typeof row[column.name].component !== 'undefined'"
                                               :is="row[column.name].component"
                                               :columnData="row[column.name].data"
                                               :addData="addCellData"
                                               :rowId="parseInt(row.id)"
                                               v-on:action="action"
                                               :columnRawData="row"
                                    ></component>
                                    <component v-else-if="typeof column.component !== 'undefined'"
                                               :is="column.component"
                                               :columnData="row[column.name]"
                                               :addData="addCellData"
                                               :rowId="parseInt(row.id)"
                                               v-on:action="action"
                                               :columnRawData="row"
                                    ></component>

                                    <template v-else>
                                        <template v-if="typeof row[column.name] === 'object' && 'child' in row[column.name]">
                                            <span class="nested-tag"></span>
                                            <span class="text-ellipsis">{{row[column.name].value}}</span>
                                        </template>

                                        <v-clamp v-else autoresize :max-lines="6"> {{row[column.name]}}
                                            <template #after="{ toggle, expanded, clamped }">
                                                <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                            </template>
                                        </v-clamp>
                                    </template>
                                </template>

                                <template v-else>
                                    <div v-if="typeof row[column.name] != 'undefined'" :style="{'min-width': columnWidth(column)}">
                                        <v-clamp autoresize :max-lines="3"> {{row[column.name]}}
                                            <template #after="{ toggle, expanded, clamped }">
                                                <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                            </template>
                                        </v-clamp>
                                    </div>
                                </template>
                            </td>
                            <td class="main-table__empty-col"></td>
                        </tr>

                        <tr v-if="!!row.greeting" :key="'greeting-' + index">
                            <td>&nbsp;</td>
                            <td class="startup-send-message-row" :colspan="columns.length">
                                <div class="startup-send-message-wrapper startup-send-message-wrapper--secondary">
                                    <div class="startup-send-message">
                                        <div v-if="row.greeting_user_name" class="image-block image-block--rounded">
                                            <Avatar
                                                :username="row.greeting_user_name"
                                                :src="row.greeting_user_avatar"
                                                :size="30"
                                            />
                                        </div>
                                        <div>
                                            <v-clamp autoresize :max-lines="3"> {{row.greeting}}
                                                <template #after="{ toggle, expanded, clamped }">
                                                    <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                    <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                                </template>
                                            </v-clamp>

                                            <span v-if="row.greeting_user_name" class="fw-bold d-block mt-2">{{ row.greeting_user_name }} <template v-if="row.greeting_user_post">, {{row.greeting_user_post}}</template></span>
                                        </div>

                                        <button v-if="!$store.state.user.settings.disable_external_messaging" @click="$emit('action', {action: 'send-message', 'rowId': row.id, 'receiver': row.greeting_created_by})" class="button button--bordered--with--icon button--send_a_message w-auto ml-5">Send A Message </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>

        <pager v-if="data.length" :pages-count="pagesCount" :init-page="currentPage" v-on:changePage="changePage"></pager>

        <IRLExplainModal ref="IRLExplainModal"/>
    </div>
</template>

<script>

import VClamp from "vue-clamp"
import pager from '@/components/TablePager'
import multiselect from "vue-multiselect";
import draggable from "vuedraggable";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "Grid",
    components: {
        pager,
        VClamp,
        multiselect,
        draggable,
        DatePicker,
        'SearchCompanyNameColumn': () => import('./customColumns/SearchCompanyName'),
        'SearchCompanyLocationColumn': () => import('./customColumns/SearchCompanyLocation'),
        'RawColumn': () => import('./customColumns/RawColumn'),
        'RawClampColumn': () => import('./customColumns/RawClampColumn'),
        'ImageColumn': () => import('./customColumns/ImageColumn'),
        'Actions': () => import('./customColumns/Actions'),
        'Permissions': () => import('./customColumns/Permissions'),
        'CustomField': () => import('./customColumns/CustomField'),
        'Date': () => import('./customColumns/Date'),
        'ReviewsColumn': () => import('./customColumns/Reviews'),
        'RouterLinkColumn': () => import('./customColumns/RouterLinkColumn'),
        'UserColumn': () => import('./customColumns/User'),
        'InviteStatusColumn': () => import('./customColumns/InviteStatus'),
        'StatusColumn': () => import('./customColumns/Status'),
        'InlineEdit': () => import('./customColumns/InlineEdit'),
        'Link': () => import('./customColumns/LinkColumn'),
        'IRLScore': () => import('./customColumns/IRLScore.vue'),
        'TagsColumn': () => import('./customColumns/TagsColumn.vue'),
        'Avatar': () => import("vue-avatar"),
        'IRLExplainModal': () => import("./insights/IRLExplainModal"),
    },

    props: {
        hasCheckboxColumn: {
            type: Boolean,
            default: false
        },

        hasFilters: {
            type: Boolean,
            default: false
        },

        notSelectWhole: {
            type: Boolean,
            default: false
        },

        delayFilterInput: {
            type: Boolean,
            default: true
        },

        data: Array,
        addCellData: Object,
        columns: Array,
        dataUrl: String,

        currentPage: {
            typeof: Number,
            default: 1,
        },

        pageLength: {
            type: Number,
            default: 25,
        },

        totalRowsCount: Number,
        sort: {
            type: Object,
            default: () => ({
                field: '',
                dir: '',
            })
        },

        draggable: {
            type: Boolean,
            default: false
        },

        needWrapper: {
            type: Boolean,
            default: true
        },

        selectAllRows: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedRows: [],
            selectAllChecked: false,
            selectedAll: false, //whether all results have been checked

            fixed: false,
            filterColumns: [],
            filters: {},
            draggableData: [],

            filtersTimer: null,

            gridWidth: 0
        }
    },

    mounted() {
        this.updateCheckboxes()
        this.initDraggableData()
        this.createFilters()

        if (this.isStartupsCompanyType) {
            setTimeout(() => {
                this.setGridWidth()
            }, 3000)
        }
    },

    computed: {
        pagesCount() {
            return  Math.ceil(this.totalRowsCount / this.pageLength)
        },
        isStartupsCompanyType() {
            return this.addCellData && this.addCellData.companyType === 'startups'
        },
        isLightTheme() {
            //Light theme only for Startups and Ecosystems search pages
            return this.addCellData && this.addCellData.fromSearch
        }

    },

    watch: {
        pageLength() {
            this.updateCheckboxes()
        },

        data: {
            deep: true,
            handler()
            {
                this.updateCheckboxes()
                this.initDraggableData()
                this.selectAllChecked = false
            }
        },

        draggableData: {
            deep: true,
            handler()
            {
                if (JSON.stringify(this.draggableData) != JSON.stringify(this.data)) {
                    this.$emit('dragged', this.arrayHelper.arrayColumn(this.draggableData, 'id'))
                }
            }
        },

        selectAllChecked() {
            if (this.selectAllChecked) {
                this.$refs.selectAllCheckboxPopover.$emit('open')
            }
            else {
                this.$refs.selectAllCheckboxPopover.$emit('close')
                this.unselectAll()
            }
        },

        selectedRows() {
            let ids = []

            let hasEmpty = false
            for (let i in this.data) {
                if (this.selectedRows[i]) {
                    ids.push(this.data[i].id)
                }
                else {
                    hasEmpty = true
                }
            }

            this.$emit('selectedRowsChanged', ids);

            if (hasEmpty) {
                this.setSelectedAll(false)
            }
        },

        columns: {
            deep: true,
            handler() {
                this.createFilters()

                if (this.isStartupsCompanyType) {
                    setTimeout(() => {
                        this.setGridWidth()
                    }, 1000)
                }
            }
        }
    },

    methods: {
        createFilters() {
            this.filters = []

            if (this.hasFilters) {
                this.columns.forEach(column => {
                    if (typeof column.filter !== 'undefined') {
                        let value = ""

                        if (column.filter === 'date-range') {
                            value = null
                        }

                        let filter = {
                            type: column.filter,
                            name: column.name,
                            value: value,
                            options: [],
                        }

                        if (typeof column.options !== 'undefined') {
                            filter.options = column.options
                        }

                        this.filters.push(filter)
                    }
                    else {
                        this.filters.push(false)
                    }
                })
            }
        },

        setGridWidth() {
            if (this.$refs.grid) {
                this.gridWidth = this.$refs.grid.clientWidth
            }
        },

        filtersChanged(filterType) {

            if (this.delayFilterInput) {
                clearTimeout(this.filtersTimer)

                if (filterType === 'text') {
                    this.filtersTimer = setTimeout(
                        () => {
                            this.parseFilters()
                        }, 1000
                    )
                } else {
                    this.parseFilters()
                }
            }
            else {
                this.parseFilters()
            }
        },

        parseFilters() {
            let filters = {}
            this.filters.forEach(filter => {
                if (filter && filter.value) {
                    if (filter.type === 'select') {
                        let values = this.arrayHelper.arrayColumn(filter.value, 'id')
                        if (values.length) {
                            filters[filter.name] = this.arrayHelper.arrayColumn(filter.value, 'id')
                        }
                    } else if (filter.type === 'date-range') {
                        let interval = this.dateHelper.encodeIntervalForServer(filter.value)

                        if (interval[0] && interval[1]) {
                            filters[filter.name] = interval
                        }
                    } else {
                        filters[filter.name] = filter.value
                    }
                }
            })

            this.$emit('changeFilters', filters)
        },

        initDraggableData() {
            if (this.draggable) {
                this.draggableData = this.arrayHelper.cloneArray(this.data)
            }
        },

        columnWidth(column) {
            if (typeof column.minWidth !== "undefined") {
                return column.minWidth
            }
            else {
                return 'auto'
            }
        },

        calculateHeaderColumnsWidths() {
            let table = document.getElementById('main-grid')
            let header = table.tHead

            for (let i in header.rows[0].cells) {
               if (typeof header.rows[0].cells[i] === "object") {
                   let width = table.tBodies[0].rows[0].cells[i].offsetWidth
                   header.rows[0].cells[i].style.width = width + 'px'
               }
            }
        },

        changePage(pageNumber) {
            this.$emit('changePage', pageNumber);
        },

        changeSort(field) {
            let sort = {
                field: "",
                dir: "",
            }
            if (this.sort.field === field) {
                if (this.sort.dir === 'asc') {
                    sort.field = field
                    sort.dir = "desc"
                }
                else {
                    sort.field = ""
                    sort.dir = ""
                }
            }
            else {
                sort.field = field
                sort.dir = "asc"
            }

            this.$emit('changeSort', sort)
        },

        updateCheckboxes() {
            this.selectedRows = []
            this.data.forEach(() => {
                this.selectedRows.push(false)
            })
            this.setSelectedAll(false)
        },

        selectAll(all) {
            this.selectedRows = []
            this.data.forEach(() => {
                this.selectedRows.push(true)
            })

            if (all) {
                this.setSelectedAll(true)
            }

            this.$refs.selectAllCheckboxPopover.$emit('close')
        },

        unselectAll() {
            this.selectedRows = []
            this.data.forEach(() => {
                this.selectedRows.push(false)
            })
        },

        setSelectedAll(result) {
            this.$emit('selectAllResults', result);
        },

        closeAllCheckboxPopover() {
            this.$refs.selectAllCheckboxPopover.$emit('close')
            this.selectAllChecked = false
        },

        action(params) {
            this.$emit('action', params)
        },

        showIRLExplainModal() {
            this.$refs.IRLExplainModal.openModal()
        }
    }
}
</script>

<style scoped>
    .fixed {
        position:fixed;
        top:0px;
        width: 100%;
    }
    div.popover.bs-popover-bottom,
    div.popover.bs-popover-top {
        /*left: -17px !important;*/
        margin: 0;
        max-width: 350px;
    }
    @media (max-width: 424px) {
        div.popover.bs-popover-bottom,
        div.popover.bs-popover-top {
            max-width: 200px;
        }
    }
    div.popover .arrow {
        background: red !important;
        left: 13px !important;
    }
</style>
