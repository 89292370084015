var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{staticClass:"key__filter",attrs:{"group":"g1"},model:{value:(_vm.localQuery.rules),callback:function ($$v) {_vm.$set(_vm.localQuery, "rules", $$v)},expression:"localQuery.rules"}},[_vm._l((_vm.query.rules),function(rule,index){return [(!_vm.isGroup(rule))?_c('span',{key:rule.id,staticClass:"key__filter__wrapper"},[_c('span',{staticClass:"key__filter__tag",class:{
                group__rule: typeof rule.groupWords !== 'undefined' && rule.groupWords.length > 0,
                negative: rule.negative
            }},[(rule.name === 'keyword')?_c('KeywordRule',{attrs:{"rule":rule,"is-nested":false,"bar":"main"},on:{"delete-rule":function($event){return _vm.deleteRule(rule)},"invert-rule":function($event){return _vm.invertRule(rule)}}}):_c('FilterRule',{attrs:{"rule":rule},on:{"delete-rule":function($event){return _vm.deleteRule(rule)},"invert-rule":function($event){return _vm.invertRule(rule)}}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCond(index, _vm.query.rules) && _vm.query.rules.length),expression:"showCond(index, query.rules) && query.rules.length"}],staticClass:"key__filter__tag key__filter__connection",on:{"click":function($event){return _vm.toggleCondition(rule, _vm.query, false)}}},[_vm._v(" "+_vm._s(_vm.getCondAlias(_vm.query))+" ")])]):_c('span',{key:rule.id,staticClass:"d-flex flex-wrap"},[_c('draggable',{staticClass:"key__filter__group",attrs:{"group":"g1"},model:{value:(rule.rules),callback:function ($$v) {_vm.$set(rule, "rules", $$v)},expression:"rule.rules"}},_vm._l((rule.rules),function(nestedRule,nestedIndex){return _c('span',{key:nestedRule.id,staticClass:"key__filter__wrapper"},[_c('span',{staticClass:"key__filter__tag",class:{
                        group__rule: typeof nestedRule.groupWords !== 'undefined' && nestedRule.groupWords.length > 0,
                        negative: nestedRule.negative
                    }},[(nestedRule.name === 'keyword')?_c('KeywordRule',{attrs:{"rule":nestedRule,"is-nested":true,"bar":"main"},on:{"delete-rule":function($event){return _vm.deleteRule(nestedRule)},"invert-rule":function($event){return _vm.invertRule(nestedRule)}}}):_c('FilterRule',{attrs:{"rule":nestedRule},on:{"delete-rule":function($event){return _vm.deleteRule(nestedRule)},"invert-rule":function($event){return _vm.invertRule(nestedRule)}}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCond(nestedIndex, rule.rules) && rule.rules.length),expression:"showCond(nestedIndex, rule.rules) && rule.rules.length"}],staticClass:"key__filter__tag key__filter__connection",on:{"click":function($event){return _vm.toggleGroupCondition(nestedRule, false, 'OR')}}},[_vm._v(" "+_vm._s(_vm.getCondAlias(rule))+" ")])])}),0),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCond(index, _vm.query.rules)),expression:"showCond(index, query.rules)"}],staticClass:"key__filter__tag key__filter__connection",on:{"click":function($event){return _vm.toggleCondition(rule, _vm.query, true)}}},[_vm._v(" "+_vm._s(_vm.getCondAlias(_vm.query))+" ")])],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }