<template>
    <b-modal id="modal-ecosystem-relations" :hide-footer="true" modal-class="modal-w-md" title="Ecosystem Relations">
        <div class="pb-3">
            <div v-for="(relationType, index) in relations" :key="index">

                <template v-if="relationType.name == 'Registered account'">
                    <h5 class="heading5 heading5--secondary" style="color: #a50000">{{relationType.name}}: {{ relationType.email }}</h5>
                </template>

                <template v-else>
                    <h5 class="heading5 heading5--secondary">{{relationType.name}}</h5>
                    <span v-for="(relation, index) in relationType.relations" :key="index">
                        {{relation.enterprise_name}}<template v-if="typeof relation.count !== 'undefined'">: {{relation.count}}</template><template v-if="index != relationType.relations.length - 1">, </template>
                    </span>
                    <br>
                    <br>
                </template>
            </div>

            <div class="text-center">
                <b-button variant="danger" class="mt-3" size="lg" @click="$emit('delete', id)">Delete ecosystem</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import ecosystems from "@/services/ecosystems.service"

export default {
    data() {
        return {
            id: 0,
            relations: [],
        }
    },

    methods: {
        init() {
            this.relations = []
            ecosystems.getRelations(this.id).then(response => {
                this.relations = response.data
            })
        },

        open(id) {
            this.id = id

            this.init()
            this.$bvModal.show('modal-ecosystem-relations')
        },

        close() {
            this.$bvModal.hide('modal-ecosystem-relations')
        },
    }
}
</script>

<style scoped>

</style>