<template>
    <div>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="requests"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>

        <b-modal id="send-invitation" title="Invite Ecosystem">
            Enter an email to contact {{inviteEcosystemName}}

            <form>
                <span class="form__error" v-for="(error, index) in arrayHelper.getSingleArrayFromAssoc(errors)" :key="index">{{error}}</span>

                <b-form-input class="form-control--mid" v-model="inviteEmail" placeholder="Enter Email"></b-form-input>
            </form>

            <template #modal-footer>
                <button v-if="!loading" :disabled="!canInvite" type="button" class="button" @click="invite">Invite</button>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import connectionRequests from "@/services/connection-requests.service"
import ecosystems from "@/services/ecosystems.service";
import Vue from "vue";

export default {
    components: {
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            requests: [],

            columns: [
                {
                    label: 'Enterprise',
                    name: 'enterprise_name',
                    filter: 'text',
                },
                {
                    label: 'Ecosystem domain',
                    name: 'ecosystem_domain',
                    component: 'RawColumn',
                },
                {
                    label: 'Ecosystem name',
                    name: 'ecosystem_name',
                    component: 'RawColumn',
                },
                {
                    label: 'Requested By',
                    name: 'user_name',
                },
                {
                    label: 'Requested By Email',
                    name: 'user_email',
                },
                {
                    label: 'Data',
                    name: 'date',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},
            inviteEmail: "",
            requestId: 0,
            inviteEcosystemName: "",
            loading: false,
            errors: {},
        }
    },

    mounted() {
        this.getList()
    },

    computed: {
        canInvite() {
            return this.stringHelper.validEmail(this.inviteEmail)
        },
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort
            filters.inviter = "enterprise"

            connectionRequests.getRequests({columns, sort, currentPage, pageLength, filters}).then(response => {
                this.requests = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            this.errors = {}

            if (params.action == 'invite') {
                this.requestId = params.rowId
                let index = this.arrayHelper.findIndexByColumnValue(this.requests,'id', this.requestId)
                this.inviteEcosystemName = this.requests[index].name
                this.$bvModal.show('send-invitation')
            }

            else if (params.action == 'delete') {
                if (confirm("Delete connection request?")) {
                    this.deleteRequest(params.rowId)
                }
            }
        },

        invite() {
            ecosystems.invite({connectionRequestId: this.requestId,  email: this.inviteEmail}).then(response => {
                this.loading = false
                this.errors = response.data.errors

                if (!Object.keys(this.errors).length) {
                    this.$bvModal.hide('send-invitation')
                    this.deleteRequest(this.requestId)
                    this.inviteEmail = ""

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 1000,
                        text: 'Invitation Sent'
                    })
                }
            })
        },

        deleteRequest(id) {
            connectionRequests.delete(id).then(() => {
                this.getList()
            })
        }
    }
}
</script>

<style scoped>

</style>