<template>
    <div>
        <div class="topbar pl-sm-5">
            <h4>Merge by ID:</h4>
            
            <div class="form-controls-inline w-75">
                <input class="form-control form-control--mid form-control--big" v-for="(mergeId, index) in mergeIds" :key="index" v-model="mergeIds[index]">
                <button class="button" @click="mergeByIDs" :disabled="!mergeIds[0].length || !mergeIds[1].length">Merge</button>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="startups"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :addCellData="addStartupData"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import mergeStartups from "@/services/merge-startups.service"

export default {
    components: {
        Grid
    },

    mixins: [grid],

    data() {
        return {
            startups: [],
            mergeIds: ['', ''],

            columns: [
                {
                    label: 'URL',
                    name: 'url_domain',
                    orderable: true,
                    filter: 'text',
                },
                {
                    label: 'Count',
                    orderable: true,
                    name: 'cnt',
                },
                {
                    name: 'actions',
                    label: '',
                    component: 'Actions',
                }
            ],

            addStartupData: {},
            filters: {},

            getGridDataMethodName: 'getList',
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            mergeStartups.getStartups({columns, sort, currentPage, pageLength, filters: filters}).then(response => {
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action === 'merge') {
                window.open("/merge-startups?url=" + this.startups[params.rowId].url_domain, '_blank').focus();
            }
        },

        mergeByIDs() {
            this.$router.push("/merge-startups?id=" + this.mergeIds.join('&id=') + '&redirectTo=merge')
        }
    }
}
</script>

<style scoped>

</style>
