import Vue from 'vue'
import Vuex from 'vuex'
import analyst from "@/store/analyst";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        analyst
    },
    state: {
        token: localStorage.getItem('token') || '',
        user : {
            id: 0,
            name: "",
            avatar: "",
            type: "",
        },
        userType: localStorage.getItem('userType') || null,

        pageNotFound: false,
        categories: [],
    },

    mutations: {
        authSuccess(state, user) {
            state.user = user
            state.token = user.token
            localStorage.setItem('token', user.token)
            localStorage.setItem('userType', user.type)
        },

        authError(state){
            state.token = ''
            localStorage.removeItem('token');
        },

        setUser(state, user) {
            state.user = user
            localStorage.setItem('userType', user.type)
        },

        logout(state) {
            state.user = {}
            state.token = ''
            localStorage.removeItem('token')
        },

        setPageNotFound(state, value) {
            state.pageNotFound = value
        },

        setNeedLogout(state, value) {
            state.needLogout = value
        },

        setCategories(state, value) {
            state.categories = value
        },
    },

    getters : {
        isLoggedIn: state => state.token.length > 0,
    }
})
