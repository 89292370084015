import axios from '@/axios';

const RESOURCE_NAME = '/admin/users';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getAdminToken(id) {
        return axios.get(`${RESOURCE_NAME}/get-admin-token?id=${id}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-user?id=${id}`)
    },

    sendActivationEmail(id) {
        return axios.post(`${RESOURCE_NAME}/send-activation-email?id=${id}`)
    },

    saveUser(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-user?id=${id}`, data)
    },

    getCompaniesList(accountType, companyName) {
        return axios.get(`${RESOURCE_NAME}/get-companies-list?company_type=${accountType}&company_name=${companyName}`)
    },

    export(usersIds) {
        return axios.post(`${RESOURCE_NAME}/export`, {usersIds})
    },

    exportAll(filters) {
        return axios.post(`${RESOURCE_NAME}/export`, {filters})
    },

    resetLessons(id) {
        return axios.post(`${RESOURCE_NAME}/reset-lessons?id=${id}`)
    },
}
