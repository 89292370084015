<template>
    <b-modal id="modal-startup-users" :hide-footer="true" modal-class="modal-w-md" title="Users">
        <form class="form pb-5">

            <div v-for="(user, index) in users" :key="index">
                <b>{{user.name}}</b>
                <div>
                    Custom contact details: {{user.details_count}}
                </div>
                <div>
                    Applier Challenges: {{user.applies_count}}
                </div>
                <div v-if="user.primary_contacts_count">
                    Primary Contact
                </div>

                <button type="button" class="button" @click="deleteUser(user.id)">Delete</button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import startups from "@/services/startups.service"
import users from "@/services/users.service"

export default {
    data() {
        return {
            id: 0,
            users: [],
        }
    },

    methods: {
        init() {
            this.getUsers()
        },

        open(id) {
            this.id = id

            this.init()
            this.$bvModal.show('modal-startup-users')
        },

        getUsers() {
            this.users = []
            startups.getUsers(this.id).then(response => {
                this.users = response.data
            })
        },

        deleteUser(userId) {
            if (confirm("Delete user?")) {
                users.delete(userId).then(() => {
                    this.getUsers()
                })
            }
        }
    }
}
</script>

<style scoped>

</style>