<template>
    <b-modal id="edit-note-form" modal-class="modal-w-md" :title="title" @shown="showEditor = true"  @hidden="showEditor = false">
        <form class="form pb-5">

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Version *</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.version">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Status *</h5>
                <select v-model="form.status" class="selectbox selectbox-mid w-100">
                    <option v-for="(label, value) in statuses" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Release Date *</h5>
                <date-picker v-model="releaseDate" placeholder="Select a date" format = "MM/DD/YYYY" class="mb-2 w-100"></date-picker>
            </div>

            <editor
                v-if="showEditor"
                :apiKey="apiKeys.tinyMCE"
                v-model="form.content"
                :init="editorOptions"
            />
        </form>

        <template #modal-footer>
            <primary-button
                :disabled="!canSave"
                caption="Save"
                loading-caption="Saving..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import {statuses} from "../../../../frontend/src/constants/releaseNotes";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import Editor from "@tinymce/tinymce-vue";
import releaseNotesService from "@/services/release-notes.service";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        PrimaryButton,
        Editor,
        DatePicker,
    },

    data() {
        return {
            id: 0,
            loading: false,
            releaseDate: null,

            form: {
                version: "",
                status: null,
                release_date: null,
                content: "",
            },

            editorOptions: {
                menubar: false,
                statusbar: false,
                plugins: ['lists','link'],
                toolbar:
                    'bold italic | alignleft aligncenter alignright | bullist numlist | link | image | removeformat',
                link_assume_external_targets: 'http'
            },

            showEditor: false,
        }
    },

    computed: {
        statuses() {
            return statuses
        },

        title() {
            if (this.id) {
                return "Edit Release Note"
            } else {
                return "Add Release Note"
            }
        },

        canSave() {
            return this.form.version.length && this.form.status && this.releaseDate;
        },
    },

    methods: {
        init() {
            this.form.version = "";
            this.form.content = "";
            this.form.status = null;
            this.form.release_date = null;

            if (this.id) {
                releaseNotesService.getEdit(this.id).then(response => {
                    this.form = response.data;
                    this.releaseDate = this.dateHelper.decodeDateFromServer(this.form.release_date);
                })
            } else {
                this.releaseDate =  this.dateHelper.decodeDateFromServer(new Date());
            }
        },

        open(id = 0) {
            this.id = id;

            this.init()
            this.$bvModal.show('edit-note-form')
        },

        save() {
            this.loading = true
            this.form.release_date = this.dateHelper.encodeDateForServer(this.releaseDate);

            releaseNotesService.save(this.id, this.form).then(response => {
                if (! Object.keys(response.data.errors).length) {
                    this.$emit('saved')
                    this.$bvModal.hide('edit-note-form')
                }
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
