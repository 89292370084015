export default {
    computed : {
        operatorAlias: function() {
            if (this.rule.negative) {
                return '≠';
            }

            return '';
        },
    },

    methods: {
        deleteRule() {
            this.$emit('delete-rule', this.rule);
        },

        invertRule() {
            this.$emit('invert-rule', this.rule);
        },
    },
}
