<template>
    <b-modal id="modal-add-hours" title="Add analyst hours" modal-class="modal-w-md">

        <p>{{enterpriseName}}</p>
        <form class="form" id="time-spent-form">

            <div class="form__item">
                <h6 class="heading6 mb-1">Plan</h6>
                <multiselect
                    v-model="selectedPlan"
                    placeholder="Select Plan"
                    :options="formattedPlansList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Hours</h6>
                <b-form-input type="number" class="form-control--mid mb-3" v-model="addHours.hours"></b-form-input>
            </div>

        </form>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save" :disabled="hasErrors">Add</b-button>
        </template>
    </b-modal>
</template>

<script>
import analyst from "@/services/analyst.service"
import {mapActions, mapGetters} from "vuex";
import multiselect from "vue-multiselect";

export default {
    components: {multiselect},
    data() {
        return {
            id: 0,
            enterpriseName: "",

            addHours: {
                enterpriseId: 0,
                planId: null,
                hours: null,
            },

            selectedPlan: null,

            errors: {},
        }
    },

    watch: {
        selectedPlan() {
            if (this.selectedPlan) {
                this.addHours.planId = this.selectedPlan.id
                this.addHours.hours = this.selectedPlan.hours
            }
            else {
                this.addHours.planId = null
                this.addHours.hours = null
            }
        }
    },

    computed: {
        ...mapGetters(["plansList"]),

        formattedPlansList() {
            return this.plansList.map(item => {return {
                id: item.id,
                name: item.name + ' - ' + item.hours + ' ' + this.stringHelper.getNoun('hr', item.hours) + ' - ' + item.price + '$',
                hours: item.hours
            }})
        },

        hasErrors() {
            return this.addHours.hours <= 0 || !this.addHours.planId
        }
    },

    mounted() {
        this.fetchPlans()
    },

    methods: {
        ...mapActions(['fetchPlans']),

        init() {
            this.selectedPlan = null
        },

        open(enterprise) {
            this.addHours.enterpriseId = enterprise.id
            this.enterpriseName = enterprise.name
            this.init()
            this.$bvModal.show('modal-add-hours')
        },

        save() {
            analyst.addHours(this.addHours).then(() => {
                this.$bvModal.hide('modal-add-hours')
                this.$emit('hours-added')
            })
        },
    }
}
</script>

<style scoped>

</style>
