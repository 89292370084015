<template>
    <div class="key__filter__tag__inner">
        <span :id="'main-rule-'+rule.id" @click="invertRule">
            <span class="key__filter__tag__label">
                <template v-if="typeof filterNames[rule.label] !== 'undefined'">{{ filterNames[rule.label] }}</template>
                <template v-else>{{rule.label}}</template>
            </span>
            <i class="key__filter__tag__text">
                {{operatorAlias}}
                {{rule.valueAlias}}
            </i>
        </span>

        <span class="key__filter__close" @click.stop="deleteRule"></span>
    </div>
</template>

<script>
    import equationRule from "./equationRule";

    export default {
        props: {
            rule: Object,
        },

        mixins: [equationRule],

        data() {
            return {
                filterNames: {
                    'fundingSeries': "Funding Series",
                    'fundingRange': "Funding Range",
                    'revenueRange': "Revenue Range",
                    'employeeCount': "Employee Count",
                    'lastFundingDate': "Last Funding Date",
                    'foundedYear': "Founded Year",
                    'lastInteraction': "Last Interaction",
                    'tags': "Tags",
                    'categories': "Categories",
                    'locations': "Locations",
                    'ecosystems': "Ecosystems",
                    'clients': "Clients",
                    'trackers': "Trackers",
                    'investors': "Investors",
                    'ecosystemTypes': "Type",
                },
            }
        },
    }
</script>
