<template>
    <main class="enter-page">
        <header class="header">
            <span class="logo"></span>
        </header>
        <form class="form form--enter" @submit.prevent="login">
            <header class="form--enter__header mb-3">
                <h1 class="heading1">Sign in</h1>
            </header>
            <div class="form--enter__container">
                <fieldset class="form--enter__fieldset">
                    <div v-for="error in errors" :key="error">
                        {{error}}
                    </div>
                    <template v-if="need2FA">
                        <img v-if="qrcodeUrl" :src="qrcodeUrl">

                        <div class="form__item">
                            <label class="form__label">Verification Code</label>
                            <input class="form__input" required v-model="mfaCode" type="text" placeholder="Verification Code"/>
                        </div>
                    </template>

                    <div v-show="!need2FA">
                        <div class="form__item">
                            <label class="form__label">Username</label>
                            <input class="form__input" required v-model="username" type="text" placeholder="Username"/>
                        </div>
                        <div class="form__item">
                            <label class="form__label">Password</label>
                            <input class="form__input" required v-model="password" type="password" placeholder="Password"/>
                        </div>
                    </div>
                    <footer class="form__footer">
                        <button class="button" @click="recaptcha" type="submit">Login</button>
                    </footer>
                </fieldset>
            </div>
        </form>
    </main>
</template>

<script>

import auth from '@/services/auth.service';

export default {
    name: "Login",

    data () {
        return {
            username: "",
            password: "",
            errors: [],
            need2FA: false,
            qrcodeUrl: null,
            recaptchaToken: null,
            mfaCode: '',
        }
    },

    methods: {
        login() {
            let username = this.username
            let password = this.password

            auth.login({ username, password }, this.mfaCode, this.recaptchaToken)
                .then(response => {
                    this.errors = response.data.errors

                    if (this.errors.length) {
                        this.$store.commit('authError')
                    } else if (response.data.need_2fa) {
                        this.need2FA = true;

                        if ('qrcode_url' in response.data) {
                            this.qrcodeUrl = response.data.qrcode_url;
                        }
                    } else if (response.data.user.token) {
                        this.$store.commit('authSuccess', response.data.user)
                        this.$router.push('/')
                    }
                })
                .catch(error => {
                    this.$store.commit('authError')
                    console.log(error)
                })
        },

        async recaptcha() {
            await this.$recaptchaLoaded()
            this.recaptchaToken = await this.$recaptcha()
            this.login()
        }
    }
}
</script>

<style scoped>

</style>
