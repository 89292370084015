<script setup>
import {ref, defineExpose, watch, computed, reactive, getCurrentInstance} from "vue";
import multiselect from "vue-multiselect";
import enterprisesService from "@/services/enterprises.service";
import trackersService from "@/services/trackers.service";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import {trackerTypes} from "../../../../frontend/src/constants/trackers";

const showModal = ref(false);
const srcEnterprise = ref(null);
const srcEnterprises = ref([]);
const destEnterprise = ref(null);
const destEnterprises = ref([]);
const srcTracker = ref(null);
const srcTrackers = ref([]);
const loading = ref(false);
const notify = getCurrentInstance().proxy.$notify;

const form = reactive({
    name: '',
    copy_startups: false,
});

const canSave = computed(() =>
    srcTracker.value &&
    form.name.length &&
    destEnterprise.value &&
    destEnterprise.value.id !== srcEnterprise.value.id
);

watch(srcEnterprise, value => {
    srcTracker.value = null;

    if (value) {
        updateSrcTrackers();
    } else {
        srcTrackers.value = [];
    }
});

watch(srcTracker, value => {
    if (value) {
        form.name = value.name;
    } else {
        form.name = '';
    }
});

async function updateSrcEnterprises(search) {
    const {data} = await enterprisesService.searchWithTrackers(search);
    srcEnterprises.value = data;
}

async function updateDestEnterprises(search) {
    const {data} = await enterprisesService.search(search);
    destEnterprises.value = data;
}

async function updateSrcTrackers(search = '') {
    const {data} = await trackersService.search(srcEnterprise.value.id, search);
    srcTrackers.value = data;
}

async function save() {
    loading.value = true;
    try {
        await trackersService.duplicate(srcTracker.value.id, destEnterprise.value.id, form);
        notify({
            group: 'app',
            type: 'success',
            duration: 10000,
            text: 'Tracker created',
        });
    } finally {
        loading.value = false;
    }
}

function open() {
    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" title="Duplicate Tracker">
        <form class="form">
            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Select Source Enterprise</h5>
                <multiselect
                    v-model="srcEnterprise"
                    placeholder="Select Company"
                    :options="srcEnterprises"
                    label="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateSrcEnterprises"
                >
                </multiselect>
            </div>

            <div v-if="srcEnterprise" class="form__item">
                <h5 class="heading5 heading5--secondary">Select Source Tracker</h5>
                <multiselect
                    v-model="srcTracker"
                    placeholder="Select Tracker"
                    :options="srcTrackers"
                    label="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateSrcTrackers"
                >
                    <template slot="option" slot-scope="{ option }">{{option.name}}
                        <template v-if="option.startups_count">({{option.startups_count}})</template>
                    </template>
                </multiselect>
            </div>

            <template v-if="srcTracker">
                <h6 class="heading6 mb-1">Tracker Name*</h6>
                <b-form-input class="form-control--mid mb-3" v-model="form.name"></b-form-input>

                <template v-if="srcTracker.type === trackerTypes.PRIVATE">
                    <div class="input-checkbox">
                        <input id="copy-startups" type="checkbox" v-model="form.copy_startups">
                        <label for="copy-startups">Include startups in tracker</label>
                    </div>
                </template>
            </template>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Select Destination Enterprise</h5>
                <multiselect
                    v-model="destEnterprise"
                    placeholder="Select Company"
                    :options="destEnterprises"
                    label="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateDestEnterprises"
                >
                </multiselect>
            </div>
        </form>

        <template #modal-footer>
            <PrimaryButton
                :disabled="!canSave"
                caption="Create Duplicate Tracker"
                loading-caption="Creating Duplicate..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
