<template>
    <div class="main-table-wrapper overflow-auto">
        <div class="main-table">
            <pulse-loader :loading="loading" :color="constants.spinnerColor"/>
            <slot/>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/MoonLoader.vue";

export default {
    name: "TableWrapper",
    components: {PulseLoader},
    props: {
        loading: Boolean,
    }
}
</script>

<style scoped>

</style>
