<template>
    <draggable class="key__filter" v-model="localQuery.rules" group="g1">
        <template v-for="(rule, index) in query.rules">
            <span v-if="!isGroup(rule)" class="key__filter__wrapper" :key="rule.id">
                <span class="key__filter__tag" :class="{
                    group__rule: typeof rule.groupWords !== 'undefined' && rule.groupWords.length > 0,
                    negative: rule.negative
                }">
                    <KeywordRule
                        v-if="rule.name === 'keyword'"
                        :rule="rule"
                        :is-nested="false"
                        bar="main"
                        v-on:delete-rule="deleteRule(rule)"
                        v-on:invert-rule="invertRule(rule)"
                    />

                    <FilterRule
                        v-else
                        :rule="rule"
                        v-on:delete-rule="deleteRule(rule)"
                        v-on:invert-rule="invertRule(rule)"
                    />
                </span>

                <span class="key__filter__tag key__filter__connection"
                      @click="toggleCondition(rule, query, false)"
                      v-show="showCond(index, query.rules) && query.rules.length">
                            {{ getCondAlias(query) }}
                </span>
            </span>

            <span class="d-flex flex-wrap" style="" v-else :key="rule.id">
                <draggable class="key__filter__group" v-model="rule.rules" group="g1">
                    <span v-for="(nestedRule, nestedIndex) in rule.rules"
                          class="key__filter__wrapper"
                          :key="nestedRule.id"
                    >
                        <span class="key__filter__tag" :class="{
                            group__rule: typeof nestedRule.groupWords !== 'undefined' && nestedRule.groupWords.length > 0,
                            negative: nestedRule.negative
                        }">
                            <KeywordRule
                                v-if="nestedRule.name === 'keyword'"
                                :rule="nestedRule"
                                :is-nested="true"
                                bar="main"
                                v-on:delete-rule="deleteRule(nestedRule)"
                                v-on:invert-rule="invertRule(nestedRule)"
                            />

                            <FilterRule
                                v-else
                                :rule="nestedRule"
                                v-on:delete-rule="deleteRule(nestedRule)"
                                v-on:invert-rule="invertRule(nestedRule)"
                            />
                        </span>

                        <span class="key__filter__tag key__filter__connection"
                              @click="toggleGroupCondition(nestedRule, false, 'OR')"
                              v-show="showCond(nestedIndex, rule.rules) && rule.rules.length">
                                    {{ getCondAlias(rule) }}
                        </span>
                    </span>
                </draggable>

                <span class="key__filter__tag key__filter__connection"
                      @click="toggleCondition(rule, query, true)"
                      v-show="showCond(index, query.rules)">
                            {{ getCondAlias(query) }}
                </span>
            </span>
        </template>
    </draggable>
</template>

<script>
    import FilterRule from './FilterRule.vue'
    import KeywordRule from './KeywordRule.vue'
    import Draggable from "vuedraggable";

    export default {
        name: 'EquationBar',

        props : {
            query: Object,

            isNested: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            FilterRule,
            KeywordRule,
            Draggable
        },

        data: function() {
            return {
                localQuery: this.query,
            };
        },

        methods: {
            isGroup : function(query) {
                return (typeof query.condition !== 'undefined');
            },

            isAnd: function(query) {
                if (!this.isGroup(query)) {
                    return true;
                }
                else {
                    return query.condition === 'And';
                }
            },

            isOr: function(query) {
                if (!this.isGroup(query)) return false;
                return query.condition === 'OR';
            },

            getCond: function(query) {
                return this.isAnd(query) ? 'AND' : 'OR';
            },

            getCondAlias: function(query) {
                return this.isAnd(query) ? 'And' : 'Or';
            },

            showCond: function (index, rules) {
                if (index === rules.length - 1) {
                    return false;
                } else {
                    return true;
                }
            },

            /**** Event Listeners ****/
            toggleCondition: function(rule, query, inGroup) {
                let oldCond = this.getCond(query)
                this.$emit('toggle-condition', rule, inGroup, oldCond)
            },

            toggleGroupCondition: function (rule, inGroup, oldCond) {
                this.$emit('toggle-condition', rule, inGroup, oldCond)
            },

            deleteRule: function(rule) {
                this.$emit('rule-deleted', rule, false);
            },

            invertRule: function(rule) {
                if (rule.name === 'trackers' && rule.value[0] === 'all') {
                    return
                }

                this.$emit('invert-rule', rule);
            },
        }
    }
</script>
