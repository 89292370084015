export const trackerTypes = {
    PRIVATE: 'private',
    PUBLIC: 'public',
    ECOSYSTEMS: 'ecosystems',
    POC: 'POC',
};

export const trackerTypesLabels = {
    'private': 'Private',
    'public': 'Public',
    'ecosystems': 'Ecosystems',
    'POC': 'POC',
};

export const visualizationTypesLabels = {
    'list': 'List',
    'pipeline': 'Pipeline',
    'timeline': 'Timeline',
};
