import analyst from "@/services/analyst.service"

export default {
    state: {
        plans: [],
    },
    actions: {
        async fetchPlans({commit, state}, reload = false){
            if (state.plans.length && !reload) {
                return state.plans
            }

            const response = await analyst.getPlans()
            commit('setPlans', response.data)
        }
    },

    mutations: {
        setPlans(state, plans) {state.plans = plans}
    },

    getters: {
        plansList: state => state.plans
    }
}
