import axios from '@/axios';

const RESOURCE_NAME = '/search';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getRelatedKeywords(word, text = '') {
        return axios.get(`${RESOURCE_NAME}/get-related-keywords?word=${word}&text=${text}`)
    },

    getSimilar(data) {
        return axios.post(`${RESOURCE_NAME}/get-similar`, data)
    },

    suggest(term) {
        return axios.get(`${RESOURCE_NAME}/suggest?term=${term}`)
    },

    getAIQueryParams(term, model) {
        return axios.get(`${RESOURCE_NAME}/get-ai-query-params?text=${term}&model=${model}`);
    },
}
