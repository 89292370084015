import axios from '@/axios';

const RESOURCE_NAME = '/admin/contacts';

export default {

    setDeleted(id) {
        return axios.post(`${RESOURCE_NAME}/set-deleted?id=${id}`)
    },

}
