<template>
    <b-modal id="modal-import-enterprises" title="Import Enterprises">
        <div class="form">
        <template v-if="typeof errors.file !== 'undefined'">
            <div class="form__error" v-for="(error, index) in errors.file" :key="index">{{error}}</div>
        </template>

        <p v-if="!file" class="modal-text mt-3">Download and use <a :href="baseURL + '/files/add_enterprises.csv'" target="_blank">this file</a> for best results</p>
            <div class="input-file">
                <template v-if="!file">
                    <label for="input-file__upload" class="w-100 w-sm-auto">Choose File</label>
                    <input type="file" ref="newFile" id="input-file__upload" accept=".csv" v-on:change="handleFileUpload()">
                </template>

                <div v-else class="tags-list tags-list--light">
                    <span class="tags-list__tag mt-3 w-100 w-sm-auto">
                    <span class="line-clamp line-clamp1">{{file.name}}</span>
                    <a @click="file = null" class="tags-list__tag__close"></a>
                    </span>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canAdd" @click="add" class="w-100 w-sm-auto">
                <span v-if="!loading">Import</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import enterprises from "@/services/enterprises.service"
import Vue from 'vue';
import { baseURL } from "@/axios";

export default {
    data() {
        return {
            errors: {},
            result: "",
            file: null,
            loading: false,
            baseURL
        }
    },

    computed: {
        canAdd() {
            if (this.loading) {
                return false
            }
            else {
                return this.file !== null
            }
        }
    },

    methods: {
        open() {
            this.errors = {}
            this.$bvModal.show('modal-import-enterprises')
        },

        handleFileUpload() {
            if (this.$refs['newFile'].files.length) {
                this.file = this.$refs['newFile'].files[0]
            }
        },

        add() {
            this.loading = true
            this.errors = {}
            this.result = ""

            let formData = new FormData();
            formData.append('file', this.file)

            enterprises.addMultipleEnterprises(formData).then(response => {
                this.loading = false
                this.errors = response.data.errors

                if (!Object.keys(this.errors).length) {
                    this.file = null
                    this.$bvModal.hide('modal-import-enterprises');

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        text: response.data.result.join(', ')
                    })

                    this.$emit('enterprises-imported')
                }
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
