<template>
    <div>
        <div class="topbar pl-sm-5">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header>
                        <button class="button w-100 w-sm-auto" @click="add">Add Release Note</button>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :has-filters="true"
                    :columns="columns"
                    :data="items"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                />
            </div>
        </div>

        <edit-note
            ref="editNote"
            @saved="getList"
        />

    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import EditNote from "@/components/releaseNotes/EditNote.vue";
import releaseNotesService from "@/services/release-notes.service";
import {statuses} from "../../../../frontend/src/constants/releaseNotes";

export default {
    components: {
        EditNote,
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            getListMethod: releaseNotesService.getList,

            columns: [
                {
                    label: 'Version',
                    name: 'version',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Status',
                    name: 'status',
                    filter: 'single-select',
                    options: Object.entries(statuses).map(([id, name]) => ({id, name})),
                    sortable: true,
                },
                {
                    label: 'Content',
                    name: 'content',
                    filter: 'text',
                    sortable: true,
                    component: 'RawColumn',
                },
                {
                    label: 'Created At',
                    name: 'created_at',
                    component: 'Date',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                },
            ],
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        add() {
            this.$refs.editNote.open();
        },

        gridAction(params) {
            if (params.action === 'edit') {
                this.$refs.editNote.open(params.rowId);
            } else if (params.action === 'delete') {
                if (confirm("Delete note?")) {
                    releaseNotesService.delete(params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
