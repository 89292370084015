import axios from '@/axios';

const RESOURCE_NAME = '/admin/dashboard';

export default {
    getCounts(filter) {
        return axios.get(`${RESOURCE_NAME}/get-counts`, {
            params: {
                filter
            }
        })
    },

    getStartups(metric, params) {
        return axios.get(`${RESOURCE_NAME}/get-startups-list`, {
            params: {
                ...params,
                metric,
            }
        })
    },

    getStartupsForChart(chart, options, params) {
        return axios.get(`${RESOURCE_NAME}/get-startups-list-for-chart`, {
            params: {
                ...params,
                chart,
                options,
            }
        })
    },

    getAccessLevelsChartData() {
        return axios.get(`${RESOURCE_NAME}/get-access-levels-chart-data`)
    },

    getSourceChartData() {
        return axios.get(`${RESOURCE_NAME}/get-source-chart-data`)
    },
}
