import axios from '@/axios';

const RESOURCE_NAME = '/admin/analyst';

export default {

    getEnterprises(data) {
        return axios.post(`${RESOURCE_NAME}/get-enterprises`, data)
    },

    getHoursAdded(id) {
        return axios.get(`${RESOURCE_NAME}/get-hours-added?enterprise_id=${id}`)
    },

    getHoursSpent(id) {
        return axios.get(`${RESOURCE_NAME}/get-hours-spent?enterprise_id=${id}`)
    },

    addHours(data) {
        return axios.post(`${RESOURCE_NAME}/add-hours`, data)
    },

    addHoursSpent(data) {
        return axios.post(`${RESOURCE_NAME}/add-hours-spent`, data)
    },

    getPlans() {
        return axios.get(`${RESOURCE_NAME}/get-plans`)
    },

    removeHoursAdded(id) {
        return axios.post(`${RESOURCE_NAME}/remove-hours-added?hours_added_id=${id}`)
    },

    removeHoursSpent(id) {
        return axios.post(`${RESOURCE_NAME}/remove-hours-spent?hours_spent_id=${id}`)
    },
}
