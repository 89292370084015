import { render, staticRenderFns } from "./DuplicateTracker.vue?vue&type=template&id=2bf76b76&scoped=true&"
import script from "./DuplicateTracker.vue?vue&type=script&setup=true&lang=js&"
export * from "./DuplicateTracker.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf76b76",
  null
  
)

export default component.exports