<template>
    <b-modal id="modal-startup-form" modal-class="modal-w-xxxl" title="Startups" hide-footer>
        <div class="main-table-wrapper overflow-auto mb-5">
            <div class="main-table">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"/>

                <Grid
                    :columns="columns"
                    :data="items"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                />
            </div>
        </div>
    </b-modal>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import PulseLoader from "vue-spinner/src/MoonLoader.vue";
import clientsService from "@/services/clients.service";

export default {
    components: {
        Grid,
        PulseLoader,
    },

    mixins: [grid],

    data() {
        return {
            id: 0,
            type: '',

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                },
                {
                    label: 'Name',
                    name: 'name',
                    component: 'RawColumn',
                },
                {
                    label: 'URL',
                    name: 'url_domain',
                },
                {
                    label: 'Short Description',
                    name: 'short_description',
                },
            ],
        }
    },

    methods: {
        open(id, type) {
            this.items = [];
            this.id = id;
            this.type = type;
            this.$bvModal.show('modal-startup-form');
            this.filters = {};
            this.getList()
        },

        getList() {
            let data = this.getRequestParams();

            this.loading = true
            this.totalRowsCount = 0

            clientsService.getRelatedStartups(this.id, this.type, data).then(response => {
                this.items = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>

<style scoped>

</style>
