<template>
    <div>
        <b-modal id="startups-modal" centered modal-class="modal--chat-details modal-w-xxxl" :title="title" hide-footer>
            <div class="main-table-wrapper overflow-auto">
                <div class="main-table" style="position: relative">
                    <div v-if="loading" class="loading__spinner">
                        <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                    </div>

                    <Grid
                        v-else
                        :columns="actualColumns"
                        :data="items"
                        :page-length="parseInt(pageLength)"
                        :total-rows-count="totalRowsCount"
                        :currentPage="currentPage"
                        :notSelectWhole="true"
                        v-on:changePage="changePage"
                        v-on:action="gridAction"
                        v-on:selectedRowsChanged="changeSelectedRows"
                    >
                    </Grid>
                </div>
            </div>
        </b-modal>

        <edit-startup
            ref="editStartup"
            v-on:saved="getList"
            v-on:modal-closed="editStartupClosed"
        />

        <edit-ecosystem
            ref="editEcosystem"
            v-on:modal-closed="editStartupClosed"
        />
    </div>
</template>

<script>
    import grid from "@/mixins/grid";
    import dashboardService from "@/services/dashboard.service";
    import Grid from "../../../../frontend/src/components/Grid.vue";
    import {defaultColumns as startupsDefaultColumns} from "@/startupsIndexConstants";
    import {defaultColumns as ecosystemsDefaultColumns} from "@/ecosystemsIndexConstants";
    import Vue from "vue";
    import startupsService from "@/services/startups.service";
    import EditStartup from "@/components/startups/EditStartup.vue";
    import EditEcosystem from "@/components/ecosystems/EditEcosystem.vue";

    export default {
        components: {EditEcosystem, EditStartup, Grid},

        props: {
            filter: Object,
        },

        mixins: [grid],

        data() {
            return {
                metric: null,
                chart: null,
                options: null,
                title: null,
                pageLength: 10,
            }
        },

        computed: {
            isEcosystems() {
                return ['ecosystems_cb_synced','ecosystems_relations_cb_synced'].includes(this.metric);
            },

            actualColumns() {
                if (this.isEcosystems) {
                    return ecosystemsDefaultColumns.filter(column => [
                        'id',
                        'name',
                        'url_domain',
                        'short_description',
                        'startups_count',
                        'approve_status',
                        'actions',
                    ].includes(column.name)).map(column => ({
                        ...column,
                        sortable: false,
                    }));
                } else {
                    return startupsDefaultColumns.filter(column => ![
                        'website_status',
                        'website_keywords',
                        'created_at',
                        'registered_at',
                        'admin_email',
                    ].includes(column.name)).map(column => ({
                        ...column,
                        sortable: false,
                    }));
                }
            }
        },

        methods: {
            open(metric) {
                this.currentPage = 1;
                this.items = [];
                this.metric = metric;
                this.chart = null;
                this.$bvModal.show('startups-modal');
                this.getList();
            },

            openForChart(chart, options) {
                this.currentPage = 1;
                this.items = [];
                this.metric = null;
                this.chart = chart;
                this.options = options;
                this.$bvModal.show('startups-modal');
                this.getList();
            },

            getList() {
                let currentPage = this.currentPage
                let pageLength = this.pageLength
                let columns = this.arrayHelper.arrayColumn(this.actualColumns, 'name')
                this.loading = true
                let responsePromise;

                if (this.chart) {
                    responsePromise = dashboardService.getStartupsForChart(this.chart, this.options, {
                        columns,
                        currentPage,
                        pageLength,
                    })
                } else {
                    let filter = this.filter

                    responsePromise = dashboardService.getStartups(this.metric, {
                        columns,
                        currentPage,
                        pageLength,
                        filter
                    });
                }

                responsePromise.then(response => {
                    this.items = response.data.data
                    this.totalRowsCount = parseInt(response.data.total_count)
                    this.currentPage = response.data.current_page
                }).finally(() => {
                    this.loading = false
                })
            },

            editStartupClosed() {
                this.$bvModal.show('startups-modal');
            },

            gridAction(params) {
                if (params.action === 'edit') {
                    this.$bvModal.hide('startups-modal');

                    if (this.isEcosystems) {
                        this.$refs.editEcosystem.open(params.rowId)
                    } else {
                        this.$refs.editStartup.open(params.rowId)
                    }
                } else if (params.action === 'sync-cb') {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 1000,
                        text: 'Sync in progress...'
                    })

                    startupsService.syncCB(params.rowId).then((response) => {
                        this.getList()

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data
                        })
                    })
                } else if (params.action === 'sync-kg') {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 1000,
                        text: 'Sync in progress...'
                    })

                    startupsService.syncKG(params.rowId).then((response) => {
                        this.getList()

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data
                        })
                    })
                } else if (params.action === 'sync-ppx') {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 1000,
                        text: 'Sync in progress...'
                    })

                    startupsService.syncPPXDescription(params.rowId).then((response) => {
                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data
                        })
                    })
                }
            }
        }
    }
</script>
