<template>
    <b-modal id="modal-hours-spent" :hide-footer="true" modal-class="modal-w-md" :title="'Log time spent for ' + enterpriseName">
        <div class="pb-3">

            <div v-for="(item, index) in items" :key="index">
                {{item.project_title}}
                {{item.hours}}
                {{item.created_at}}

                <a @click="remove(item.id)" class="link">Remove</a>
            </div>
        </div>
    </b-modal>
</template>

<script>
import analyst from "@/services/analyst.service"

export default {
    data() {
        return {
            enterpriseId: 0,
            enterpriseName: "",
            items: [],
        }
    },

    methods: {
        init() {
            this.items = []
            this.getHoursSpent()
        },

        getHoursSpent() {
            analyst.getHoursSpent(this.enterpriseId).then(response => {
                this.items = response.data.items
                this.enterpriseName = response.data.enterprise_name
            })
        },

        open(id) {
            this.enterpriseId = id
            this.init()
            this.$bvModal.show('modal-hours-spent')
        },

        close() {
            this.$bvModal.hide('modal-hours-spent')
        },

        remove(id) {
            if (confirm("Remove record?")) {
                analyst.removeHoursSpent(id).then(() => {
                    this.getHoursSpent()
                    this.$emit('spent-hours-removed')
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
