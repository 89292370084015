import Ecosystems from "@/views/ecosystems/Ecosystems";
import Ecosystem from "@/views/ecosystems/Ecosystem";

export default [
    {
        path: '/ecosystems',
        component: Ecosystems,
    },
    {
        path: '/ecosystems/:id',
        component: Ecosystem,
    },
]