<template>
    <div>
        <div class="main-table-wrapper">
            <div class="main-table overflow-auto" style="position: relative">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"></pulse-loader>
                <Grid
                    :columns="columns"
                    :data="startups"
                    :has-filters="true"
                    :delay-filter-input="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of startups: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>

        <b-modal id="modal-crawl-jsons" :hide-footer="true" modal-class="modal-w-md" title="Crawled Data">
            <table class="table">
                <tr>
                    <th>Crawl Date</th>
                    <th></th>
                </tr>
                <tr v-for="(json, index) in crawlJsons" :key="index">
                    <td>
                        {{dateHelper.decodeDate(json.created_at)}}
                    </td>
                    <td>
                        <a @click="downloadCrawlJson(json.id)" class="link">Download</a>
                    </td>
                </tr>
            </table>
        </b-modal>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import startups from "@/services/startups.service"
import Vue from 'vue';
import PulseLoader from "vue-spinner/src/MoonLoader";

export default {
    components: {
        Grid,
        PulseLoader,
    },

    mixins: [grid],

    data() {
        return {
            loading: false,
            startups: [],
            selectedIds: [],

            columns: [
                {
                    label: 'ID',
                    name: 'link',
                    filter: 'text',
                    component: 'RawColumn',
                    sortable: true,
                },
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'URL domain',
                    name: 'url_domain',
                    filter: 'text',
                    component: 'RawColumn',
                    sortable: true,
                },
                {
                    label: 'Keywords Status',
                    name: 'keywords_status',
                    filter: 'single-select',
                    options: [
                        {
                            id: "PENDING",
                            name: 'Pending'
                        },
                        {
                            id: "IN_PROGRESS",
                            name: 'In progress'
                        },
                        {
                            id: "DONE",
                            name: 'Done'
                        },
                        {
                            id: "CRAWLED",
                            name: 'Crawled'
                        },
                        {
                            id: "FAILED_TO_CRAWL",
                            name: 'Failed to crawl'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'Keywords Status Date',
                    name: 'keywords_status_date',
                    sortable: true,
                    component: 'Date',
                },
                {
                    label: 'Last Crawled',
                    name: 'last_crawled_date',
                    sortable: true,
                    component: 'Date',
                },
                {
                    label: 'Keywords',
                    name: 'website_keywords',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Keywords Orig',
                    name: 'keywords_orig',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Diffbot NLP Keywords',
                    name: 'keywords_diffbot',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Watson Keywords',
                    name: 'keywords_watson',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Diffbot Descriptors',
                    name: 'diffbot_descriptors',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Taxonomy',
                    name: 'taxonomy',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Taxonomy FinTech',
                    name: 'taxonomy_fin',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Taxonomy FinTech (All Words)',
                    name: 'taxonomy_fin2',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Taxonomy Category Fintech',
                    name: 'taxonomy_cat_fin',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Taxonomy Category Fintech (All Words)',
                    name: 'taxonomy_cat_fin2',
                    filter: 'text',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},

            crawlJsons: [],
        }
    },

    mounted() {
        this.getList()
    },

    watch: {
        filterInTrackers() {
            this.getList()
        }
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            filters['present_in_trackers'] = this.filterInTrackers

            this.loading = true
            this.startups = []
            this.totalRowsCount = 0

            startups.getStartups('crawling', {columns, sort, currentPage, pageLength, filters}).then(response => {
                this.loading = false
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {

            if (params.action == 'crawl') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Request in progress...'
                })

                startups.crawl(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            }

            if (params.action == 'check-job-status') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Request in progress...'
                })

                startups.checkJobStatus(params.rowId).then((response) => {

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: "Job status: " + response.data,
                    })


                    if (response.data !== 'Failed to get response from server: 200') {
                        this.getList()
                    }
                })
            }

            if (params.action == 'download-crawl-json' || params.action == 'show-crawled-data') {
                startups.getCrawlJsons(params.rowId).then((response) => {
                    this.crawlJsons = response.data

                    if (this.crawlJsons.length == 1) {
                        this.downloadCrawlJson(this.crawlJsons[0].id)
                    }
                    else {
                        this.$bvModal.show('modal-crawl-jsons')
                    }
                })
            }

            if (params.action == 'translate') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Request in progress...'
                })

                startups.translateKeywords(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data,
                    })
                })
            }
        },

        downloadCrawlJson(id) {
            startups.downloadCrawlJson(id).then((response) => {
                this.fileHelper.download(response.data.data, response.data.name)
            })
        },

        selectedRowsChanged(ids) {
            this.selectedIds = ids
        },
    }
}
</script>

<style scoped>

</style>
