import axios from '@/axios';

const RESOURCE_NAME = '/trackers-companies';

export default {

    getList(trackerId, data) {
        return axios.post(`${RESOURCE_NAME}/get-list?tracker_id=${trackerId}`, data)
    },

    getTimelineList(trackerId, data) {
        return axios.post(`${RESOURCE_NAME}/get-timeline-list?tracker_id=${trackerId}`, data)
    },

    getListForOpportunity(trackerId, filters) {
        return axios.post(`${RESOURCE_NAME}/get-list-for-opportunity?tracker_id=${trackerId}`, {filters})
    },

    getStagesData(trackerId, data) {
        return axios.post(`${RESOURCE_NAME}/get-stages-data?tracker_id=${trackerId}`, data)
    },

    getStageCompanies(trackerId, stageId, lastLinkId, data) {
        return axios.post(`${RESOURCE_NAME}/get-stage-companies?tracker_id=${trackerId}&stage_id=${stageId}&last_link_id=${lastLinkId}`, data)
    },

    getNewCompanies(trackerId, lastLinkId, data) {
        return axios.post(`${RESOURCE_NAME}/get-new-companies?tracker_id=${trackerId}&last_link_id=${lastLinkId}`, data)
    },

    getStartupData(trackerId, startupId) {
        return axios.get(`${RESOURCE_NAME}/get-startup-data?tracker_id=${trackerId}&company_id=${startupId}`)
    },

    savePipelineCompanies(trackerId, data) {
        return axios.post(`${RESOURCE_NAME}/save-pipeline-companies?tracker_id=${trackerId}`, data)
    },

    setPinned(trackerId, companyId, pinned) {
        return axios.post(`${RESOURCE_NAME}/set-pinned?tracker_id=${trackerId}&company_id=${companyId}`, {pinned})
    },

    getStartupsListToAdd(trackerId, search = '') {
        return axios.get(`${RESOURCE_NAME}/get-startups-list-to-add?tracker_id=${trackerId}&search=${search}`)
    },

    addCompanies(trackersIds, items) {
        return axios.post(`${RESOURCE_NAME}/add-companies`, {trackersIds, items})
    },

    addCompaniesByQuery(query, params, sort, trackersIds) {
        return axios.post(`${RESOURCE_NAME}/add-companies-by-query`, {query, params, sort, trackersIds})
    },

    addCompaniesToSharedTracker(trackerId, items) {
        return axios.post(`${RESOURCE_NAME}/add-companies-to-shared-tracker?tracker_id=${trackerId}`, {items})
    },

    removeCompanies(trackerId, companiesIds) {
        return axios.post(`${RESOURCE_NAME}/remove-companies?tracker_id=${trackerId}`, {companies_ids: companiesIds})
    },

    removeAllCompanies(trackerId, filters) {
        return axios.post(`${RESOURCE_NAME}/remove-all-companies?tracker_id=${trackerId}`, {filters})
    },

    saveDiligenceValues(trackerId, companyId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-diligence-values?tracker_id=${trackerId}&company_id=${companyId}&field_id=${fieldId}`, data)
    },

    saveDiligenceFiles(trackerId, companyId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-diligence-files?tracker_id=${trackerId}&company_id=${companyId}&field_id=${fieldId}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    saveTimelineDetails(trackerId, companyId, data) {
        return axios.post(`${RESOURCE_NAME}/save-timeline-details?tracker_id=${trackerId}&company_id=${companyId}`, {data})
    },

    getDiligenceFieldValue(trackerId, companyId, fieldId) {
        return axios.get(`${RESOURCE_NAME}/get-diligence-field-value?tracker_id=${trackerId}&company_id=${companyId}&field_id=${fieldId}`)
    },

    getApplicationData(trackerId, companyId) {
        return axios.get(`${RESOURCE_NAME}/get-application-data?tracker_id=${trackerId}&company_id=${companyId}`)
    },

    getCompanyDetails(trackerId, companyId) {
        return axios.get(`${RESOURCE_NAME}/get-company-details?tracker_id=${trackerId}&company_id=${companyId}`)
    },

    getTimelineDetails(trackerId, companyId) {
        return axios.get(`${RESOURCE_NAME}/get-timeline-details?tracker_id=${trackerId}&company_id=${companyId}`)
    },

    saveNote(trackerId, companyId, data) {
        return axios.post(`${RESOURCE_NAME}/save-note?tracker_id=${trackerId}&company_id=${companyId}`, data)
    },

    saveAnswers(trackerId, data) {
        return axios.post(`${RESOURCE_NAME}/save-answers?tracker_id=${trackerId}`, data)
    },

    saveAnswerFiles(trackerId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-answer-files?tracker_id=${trackerId}&field_id=${fieldId}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    deleteAnswers(trackerId) {
        return axios.post(`${RESOURCE_NAME}/delete-answers?tracker_id=${trackerId}`)
    },

    exportTrackersStartupsStages(filters) {
        return axios.post(`${RESOURCE_NAME}/export-trackers-startups-stages`, {filters})
    },

    exportTrackedStartups(filters, profileFields, trackerFields) {
        return axios.post(`${RESOURCE_NAME}/export-tracked-startups`, {filters, profileFields, trackerFields})
    },
}
