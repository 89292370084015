import CompaniesConnections from "@/views/connections/CompaniesConnections";
import Invites from "@/views/connections/Invites";

export default [
    {
        path: '/companies-connections',
        component: CompaniesConnections,
        meta: {
            access: ['admin'],
        }
    },

    {
        path: '/invites',
        component: Invites,
        meta: {
            access: ['admin'],
        }
    },
]