<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center">
            <b-spinner></b-spinner>
        </div>

        <Chart v-else :options="chartOptions"/>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'

    export default {
        props: {
            loading: Boolean,
        },
        components: {
            Chart
        },
        data() {
            return {
                chartName: "StartupsAccessLevels",
                chartType: "pie",
                chartOptions: {
                    chart: {
                        type: "pie",
                        options3d: {
                            enabled: true,
                            alpha: 45
                        },
                    },
                    title: {
                        text: ""
                    },
                    plotOptions: {
                        pie: {
                            innerSize: '50%',
                            depth: 45,
                            colors: ["#0083b2", "#44bf2e", "#ffbf1c", "#a50000"],
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.y:,.0f}'
                            },
                        },
                    },
                    xAxis: {
                        categories: [],
                    },
                    series: [
                        {
                            name: "Startups count",
                            data: [],
                            colorByPoint: true,
                            colors: ["#0083b2", "#44bf2e", "#ffbf1c", "#a50000"]
                        }
                    ],
                },
            }
        },

        methods: {
            draw([categories, series]) {
                this.chartOptions.series = series.map(item => ({
                    ...item,
                    events: {
                        click: (e) => {
                            this.$emit('openStartupsModal', e.point.name)
                        }
                    }
                }));
                this.chartOptions.xAxis.categories = categories
            }
        },
    }
</script>
