export const helperTypes = {
    walkthrough: 'Walkthrough',
    knowledge_base: 'Knowledge Base',
    video: 'Video',
}

export const pages = {
    homepage: 'Homepage',
    search: 'Search',
    trackers: 'Trackers',
    startup_profile: 'Startup Profile',
    ecosystems: 'Ecosystems',
    settings: 'Settings',
    platform_overview: 'Platform Overview',
}

export const pagesIconsClasses = {
    homepage: 'card-hints__header--home',
    search: 'card-hints__header--search',
    trackers: 'card-hints__header--track',
    startup_profile: 'card-hints__header--startups',
    ecosystems: 'card-hints__header--ecosystem',
    settings: 'card-hints__header--general',
}

export const statuses = {
    draft: 'Draft',
    live: 'Live',
}
