<template>
    <b-modal id="modal-edit-time-spent" title="Log time spent" modal-class="modal-w-md">
        <form class="form" id="time-spent-form">

            <div class="form__item">
                <h6 class="heading6 mb-1">Project Title</h6>
                <b-form-input class="form-control--mid mb-3" v-model="hoursSpent.title"></b-form-input>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Notes</h6>
                <b-form-textarea class="form-control--mid mb-3" v-model="hoursSpent.notes"></b-form-textarea>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Hours Spent</h6>
                <b-form-input type="number" class="form-control--mid mb-3" v-model="hoursSpent.hours"></b-form-input>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Minutes Spent</h6>
                <b-form-input type="number" step="10" class="form-control--mid mb-3" v-model="hoursSpent.minutes"></b-form-input>
            </div>
        </form>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="hasErrors" @click="save">Add</b-button>
        </template>
    </b-modal>
</template>

<script>
import analyst from "@/services/analyst.service"

export default {
    data() {
        return {
            id: 0,

            hoursSpent: {
                enterpriseId: 0,
                title: "",
                notes: "",
                hours: 0,
                minutes: 0,
            },

            errors: {},
        }
    },

    computed: {
        hasErrors() {
            return this.hoursSpent.hours <= 0 && this.hoursSpent.minutes <= 0
        }
    },

    methods: {

        init() {
            this.hoursSpent.title = ""
            this.hoursSpent.notes = ""
            this.hoursSpent.hours = 0
            this.hoursSpent.minutes = 0
        },

        open(enterpriseId) {
            this.hoursSpent.enterpriseId = enterpriseId
            this.init()
            this.$bvModal.show('modal-edit-time-spent')
        },

        save() {
            analyst.addHoursSpent(this.hoursSpent).then(() => {
                this.$bvModal.hide('modal-edit-time-spent')
                this.$emit('time-added')
            })
        },
    }
}
</script>

<style scoped>

</style>
