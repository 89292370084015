<template>
    <div>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="connections"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of connections: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import connections from "@/services/connections.service"

export default {
    components: {
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            connections: [],

            columns: [
                {
                    label: 'Company Type',
                    name: 'company_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'enterprise',
                            name: 'Enterprise'
                        },
                        {
                            id: 'ecosystem',
                            name: 'Ecosystem'
                        },
                    ],
                },
                {
                    label: 'Company Name',
                    name: 'company_name',
                    filter: 'text',
                    component: 'RawColumn',
                },
                {
                    label: 'Inviter Company Type',
                    name: 'inviter_company_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'enterprise',
                            name: 'Enterprise'
                        },
                        {
                            id: 'ecosystem',
                            name: 'Ecosystem'
                        },
                        {
                            id: 'startup',
                            name: 'Startup'
                        },
                    ],
                },
                {
                    label: 'Inviter Company Name',
                    name: 'inviter_company_name',
                    filter: 'text',
                },
                {
                    label: 'Status',
                    name: 'status',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'new',
                            name: 'New'
                        },
                        {
                            id: 'accepted',
                            name: 'Accepted'
                        },
                        {
                            id: 'rejected',
                            name: 'Rejected'
                        },
                    ],
                },
                {
                    label: 'Invited By',
                    name: 'created_by',
                },
                {
                    label: 'Created at',
                    name: 'created_at',
                    component: 'Date',
                },
            ],

            sort: {},

            filters: {},
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)

            connections.getConnections({columns, currentPage, pageLength, filters: filters}).then(response => {
                this.connections = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },
    }
}
</script>

<style scoped>

</style>
