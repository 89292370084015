<template>
    <div>
        <div class="topbar">
            <div class="tabs tabs-primary">
                <b-nav tabs>
                    <b-nav-item @click="viewType = 'enterpriseAccounts'" :active="viewType == 'enterpriseAccounts'">
                        Enterprise Accounts
                    </b-nav-item>

                    <b-nav-item @click="viewType = 'enterprises'" :active="viewType == 'enterprises'">
                        Enterprises
                    </b-nav-item>
                </b-nav>
            </div>

            <br>

            <div class="topbar__inner">
                <div class="topbar__key">
                    <header class="buttons-container">
                        <button class="button button--sm" @click="addEnterprise">Add profile</button>
                        <button class="button button--sm" @click="importEnterprises">Import enterprises via csv</button>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :columns="columns"
                    :data="enterprises"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of enterprises: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>

        <edit-enterprise
            ref="editEnterprise"
            v-on:saved="getList"
        >
        </edit-enterprise>

        <import-enterprises
            ref="importEnterprises"
            v-on:enterprises-imported="getList"
        >
        </import-enterprises>

        <relations
            ref="relations"
            v-on:delete-enterprise="deleteEnteprise"
            v-on:convert="convert"
        >
        </relations>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import enterprises from "@/services/enterprises.service"
import EditEnterprise from "@/components/enterprises/EditEnterprise"
import ImportEnterprises from "@/components/enterprises/ImportEnterprises"
import Vue from "vue";
import Relations from "@/components/enterprises/Relations";

export default {
    components: {
        EditEnterprise,
        ImportEnterprises,
        Grid,
        Relations
    },

    mixins: [grid],

    data() {
        return {
            enterprises: [],
            selectedIds: [],
            viewType: 'enterpriseAccounts',

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'URL domain',
                    name: 'url_domain',
                    filter: 'text',
                    component: 'RawColumn',
                    sortable: true,
                },
                {
                    label: 'Account Type',
                    name: 'account_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'free',
                            name: 'Free'
                        },
                        {
                            id: 'paid',
                            name: 'Premuim'
                        },
                        {
                            id: 'test',
                            name: 'Test'
                        },
                        {
                            id: 'pilot',
                            name: 'Pilot'
                        },
                        {
                            id: 'trial',
                            name: 'Trial'
                        },
                        {
                            id: 'event_limited',
                            name: 'Event Limited'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'Admin Email',
                    name: 'admin_email',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Description',
                    name: 'description',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Users',
                    name: 'users_count',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},
        }
    },

    watch: {
        viewType() {
            this.enterprises = []
            this.getList()
        },
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            filters.view_type = this.viewType

            enterprises.getEnterprises({columns, sort, currentPage, pageLength, filters: filters}).then(response => {
                this.enterprises = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        addEnterprise() {
            this.$refs.editEnterprise.open(0)
        },

        importEnterprises() {
            this.$refs.importEnterprises.open()
        },

        gridAction(params) {
            if (params.action === 'edit') {
                this.$refs.editEnterprise.open(params.rowId)
            }

            else if (params.action === 'sync-cb') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Sync in progress...'
                })

                enterprises.syncCB(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            }

            else if (params.action === 'relations') {
                this.$refs.relations.open(params.rowId)
            }

            else if (params.action === 'delete') {
                this.deleteEnteprise(params.rowId)
            }

            else if (params.action === 'export-custom-values') {
                enterprises.exportStartupsWithCustomValues(params.rowId).then(response => {
                    this.fileHelper.download(response.data, `company_${params.rowId}_startups_custom_values.csv`)
                })
            }

            else if (params.action === 'export-startups-stages') {
                enterprises.exportStartupsStages(params.rowId).then(response => {
                    this.fileHelper.download(response.data, `company_${params.rowId}_startups_stages.csv`)
                })
            }
        },

        deleteEnteprise(id) {
            if (confirm("Delete enterprise?")) {
                enterprises.delete(id).then(() => {
                    this.getList()
                })
            }
        },

        convert(id) {
            if (confirm("Enterprise account will be removed with all relations. Only users and general company information will be migrated. Do you want to proceed?")) {
                enterprises.convertToStartup(id).then(() => {
                    this.getList()
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
