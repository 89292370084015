<template>
    <div>
        <multiselect
            v-model="selectedMetrics"
            :options="metrics"
            :multiple="true"
        />

        <div v-if="loading" class="d-flex justify-content-center">
            <b-spinner></b-spinner>
        </div>

        <Chart v-else :options="chartOptions"/>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import VennChart from "highcharts/modules/venn"
    import multiselect from "vue-multiselect";

    VennChart(Highcharts);

    export default {
        props: {
            loading: Boolean,
        },

        components: {
            Chart,
            multiselect,
        },

        data() {
            return {
                chartName: "StartupsAccessLevels",
                selectedMetrics: [
                    'Public',
                    'Public Not Approved',
                    'Private',
                    'Acquired',
                    'Closed',
                    'SP Inactive',
                    'Hidden',
                    '> 500 employees',
                ],

                metrics: [
                    'Public',
                    'Public Not Approved',
                    'Private',
                    'Acquired',
                    'Closed',
                    'SP Inactive',
                    'Hidden',
                    '> 500 employees',
                ],

                items: [],

                chartOptions: {
                    title: {
                        text: ""
                    },
                    series: [{
                        type: 'venn',
                        data: []
                    }],
                },
            }
        },

        watch: {
            selectedMetrics: {
                deep: true,

                handler() {
                    this.redraw();
                }
            }
        },

        computed: {
            filteredItems() {
                let items = [];

                for (let i in this.items) {
                    let metrics = this.items[i].sets.filter(metric => this.selectedMetrics.includes(metric));

                    if (metrics.length) {
                        let index = items.findIndex(item => JSON.stringify(item.sets.sort()) === JSON.stringify(metrics.sort()))

                        if (index >= 0) {
                            if (metrics.length > 1) {
                                items[index].value += this.items[i].value;
                            }
                        } else {
                            items.push({
                                sets: metrics,
                                value: this.items[i].value,
                                events: {
                                    click: (e) => {
                                        this.$emit('openStartupsModal', e.point.options.sets)
                                    }
                                }
                            });
                        }
                    }
                }

                return items;
            }
        },

        methods: {
            draw(chartData) {
                this.items = chartData;
                this.redraw();
            },

            redraw() {
                this.chartOptions.series[0].data = this.filteredItems;
            }
        },
    }
</script>
