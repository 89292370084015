import { render, staticRenderFns } from "./BlacklistedClientsWildcard.vue?vue&type=template&id=0eed57ff&scoped=true&"
import script from "./BlacklistedClientsWildcard.vue?vue&type=script&lang=js&"
export * from "./BlacklistedClientsWildcard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eed57ff",
  null
  
)

export default component.exports