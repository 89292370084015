const grid = {
    data() {
        return {
            items: [],
            columns: [],

            currentPage: 1,
            pageLength: 25,
            getListMethod: null,

            sort: {
                field: '',
                dir: '',
            },

            filters: {},

            totalRowsCount: 0,

            selectedRows: [],
            selectedAllResults: false,
            loading: false,

            getGridDataMethodName: "getList"
        }
    },

    watch: {
        pageLength() {
            let lastPage = Math.ceil(this.totalRowsCount / this.pageLength)

            if (this.currentPage > lastPage) {
                this.currentPage = lastPage
            }

            this.updateList()
        },
    },

    methods: {
        getList() {
            let data = this.getRequestParams();

            this.loading = true
            this.items = []
            this.totalRowsCount = 0

            this.getListMethod(data).then(response => {
                this.items = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false;
            })
        },

        updateList() {
            this[this.getGridDataMethodName]()
        },

        changePage(page) {
            this.currentPage = page
            this.updateList()
        },

        changeSort(sort) {
            this.sort = sort
            this.updateList()
        },

        changeSelectedRows(ids) {
            this.selectedRows = ids
        },

        selectAllResults(result) {
            this.selectedAllResults = result
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        getRequestParams() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            return {columns, sort, currentPage, pageLength, filters};
        }
    }
}

export default grid
