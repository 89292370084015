<template>
    <b-modal id="modal-user-form" modal-class="modal-w-md" title="Add User">
        <form class="form pb-5">

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">First Name</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.first_name">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Last Name</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.last_name">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Email*</h5>
                <span class="form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="text" v-model="form.email">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Company Type</h5>
                <select class="form-control--mid form-control" v-model="companyType">
                    <option value="enterprise">Enterprise</option>
                    <option value="ecosystem">Ecosystem</option>
                    <option value="startup">Startup</option>
                </select>

                <div class="form__error" v-for="(error, index) in errors.company_id" :key="index">{{error}}</div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Select Company*</h5>
                <multiselect
                    v-model="company"
                    placeholder="Select Company"
                    :options="companies"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateCompanies"
                >
                </multiselect>
            </div>

            <div class="form__item" v-if="company && company.admin_id > 0">
                <label><input type="checkbox" v-model="form.is_admin"> Is Admin</label>
            </div>

            <div class="input-radio mb-2">
                <input type="radio" v-model="form.action" value="send_email" id="send_email">
                <label for="send_email">Send activation email</label>
            </div>
            <div class="input-radio mb-2">
                <input type="radio" v-model="form.action" value="set_password" id="set_password">
                <label for="set_password">Set up password</label>
            </div>

            <template v-if="form.action === 'set_password'">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Password*</h5>
                    <input class="form-control--mid form-control" type="password" v-model="form.password">
                    <div class="form__error" v-for="(error, index) in errors.password" :key="index">{{error}}</div>
                </div>

                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Confirm Password*</h5>
                    <input class="form-control--mid form-control" type="password" v-model="form.confirm_password">
                </div>
            </template>

        </form>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">
                <span v-if="!loading">Save</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import enterprises from "@/services/enterprises.service"
import startups from "@/services/startups.service"
import ecosystems from "@/services/ecosystems.service"
import users from "@/services/users.service"
import multiselect from "vue-multiselect";

export default {
    components: {
        multiselect,
    },

    data() {
        return {
            id: 0,
            form: {
                first_name: "",
                last_name: "",
                email: "",
                company_type: "enterprise",
                company_id: 0,
                is_admin: true,
                action: 'send_email',
                password: "",
                confirm_password: "",
            },

            companyType: "enterprise",
            company: null,

            companies: [],

            loading: false,

            errors: {
                email: [],
                company_id: [],
                password: [],
            },
        }
    },

    watch: {
        companyType() {
            this.form.company_type = this.companyType
            this.company = null
            this.companies = []
        },

        company() {
            if (this.company) {
                this.form.company_id = this.company.id
            } else {
                this.form.company_id = 0
            }
        },
    },

    computed: {
        canSave() {
            if (this.loading) {
                return false
            }
            else {
                return this.form.email.length && this.form.company_id && (this.form.action === 'send_email' || (this.form.password && this.form.confirm_password))
            }
        }
    },

    methods: {
        init() {
            this.form.first_name = ""
            this.form.last_name = ""
            this.form.email = ""
            this.form.company_id = 0
            this.form.is_admin = true
            this.form.password = ""
            this.form.confirm_password = ""

            this.companyType = "enterprise"
            this.company = null
            this.companies = []

            this.loading = false

            this.clearErrors()
        },

        open(id) {
            this.id = id

            this.init()
            this.$bvModal.show('modal-user-form')
        },

        clearErrors() {
            for (let i in this.errors) {
                this.errors[i] = ""
            }
        },

        save() {
            //this.loading = true
            this.form.categories = this.categories

            if (this.logoChanged) {
                this.form.newLogo = this.form.logo
            }

            users.saveUser(this.id, this.form).then(response => {
                this.loading = false
                this.clearErrors()

                for (let attr in response.data.errors) {
                    this.errors[attr] = response.data.errors[attr]
                }

                if (!Object.keys(response.data.errors).length) {
                    this.$emit('saved')
                    this.$bvModal.hide('modal-user-form')
                }
            })
        },

        updateCompanies(query) {

            if (query.length) {
                if (this.companyType === 'enterprise') {
                    enterprises.search(query).then(response => {
                        this.companies = response.data
                    })
                }
                else if (this.companyType === 'ecosystem') {
                    ecosystems.search(query).then(response => {
                        this.companies = response.data
                    })
                }
                else {
                    startups.search(query).then(response => {
                        this.companies = response.data
                    })
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
