<template>
    <b-modal id="modal-startup-contacts" :hide-footer="true" modal-class="modal-w-md" title="Startup Contacts">

        <div class="main-table-wrapper overflow-auto pb-3">
            <table class="table">
                <tr>
                    <th>Name</th>
                    <th>Source</th>
                    <th class="text-center">Notes</th>
                    <th class="text-center">Custom Details</th>
                    <th></th>
                </tr>
                <tr v-for="(contact, index) in contacts" :key="index">
                    <td :class="{'deleted': contact.deleted}">{{contact.name}}</td>
                    <td :class="{'deleted': contact.deleted}">
                        <template v-if="contact.source in constants.contactSources">
                            {{constants.contactSources[contact.source]}}
                        </template>
                        <template v-else>
                            {{contact.source}}
                        </template>
                    </td>
                    <td :class="{'deleted': contact.deleted}" class="text-center"><template v-if="contact.notes">{{contact.notes}}</template></td>
                    <td :class="{'deleted': contact.deleted}" class="text-center"><template v-if="contact.custom_details">{{contact.custom_details}}</template></td>
                    <td><a v-if="!contact.deleted" class="btn btn-danger" @click="setDeleted(contact.id)">Delete</a></td>
                </tr>
            </table>
            <br>
        </div>
    </b-modal>
</template>

<script>
import startups from "@/services/startups.service"
import contacts from "@/services/contacts.service"

export default {
    data() {
        return {
            id: 0,
            contacts: [],
        }
    },

    methods: {
        init() {
            this.contacts = []
            startups.getContacts(this.id).then(response => {
                this.contacts = response.data
            })
        },

        open(id) {
            this.id = id

            this.init()
            this.$bvModal.show('modal-startup-contacts')
        },

        close() {
            this.$bvModal.hide('modal-startup-contacts')
        },

        setDeleted(id) {
            if (confirm("Mark contact as deleted?")) {
                contacts.setDeleted(id).then(() => {
                    this.init()
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
