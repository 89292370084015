import axios from '@/axios';

const RESOURCE_NAME = '/investors';

export default {
    getInvestorsList(search) {
        return axios.get(`${RESOURCE_NAME}/get-list?search=${search}`);
    },

    getListForSearch(search) {
        return axios.get(`${RESOURCE_NAME}/list-for-search?search=${search}`)
    },

    getTopListForSearch() {
        return axios.get(`${RESOURCE_NAME}/list-for-search`)
    },
}
