import KeywordsBlacklist from "@/views/keywords/KeywordsBlacklist";
import SuggestionKeywordsBlacklist from "@/views/keywords/SuggestionKeywordsBlacklist";
import KeywordsWhitelist from "@/views/keywords/KeywordsWhitelist";

export default [
    {
        path: '/blacklist',
        component: KeywordsBlacklist,
        meta: {
            access: ['admin', 'startup_manager'],
        }
    },
    {
        path: '/suggestion-blacklist',
        component: SuggestionKeywordsBlacklist,
        meta: {
            access: ['admin', 'startup_manager'],
        }
    },
    {
        path: '/whitelist',
        component: KeywordsWhitelist,
        meta: {
            access: ['admin', 'startup_manager'],
        }
    },
]
