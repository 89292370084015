import SharedTrackers from "@/views/trackers/SharedTrackers";

export default [
    {
        path: '/shared-trackers',
        component: SharedTrackers,
        meta: {
            access: ['admin'],
        }
    },
]