<template>
    <b-modal id="modal-edit-coupon" :title="title" modal-class="modal-w-md">
        <form class="form">


            <div class="form__item">
                <h6 class="heading6 mb-1">Coupon Code</h6>
                <b-form-input class="form-control--mid mb-3" v-model="coupon.code"></b-form-input>

                <template v-if="typeof errors.code !== 'undefined'">
                    <div class="form__error" v-for="(error, index) in errors.code" :key="index">{{error}}</div>
                </template>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Description</h6>
                <b-form-textarea class="form-control--mid mb-3" v-model="coupon.description"></b-form-textarea>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Duration</h6>
                <b-form-input type="number" min="8" class="form-control--mid mb-3" v-model="coupon.duration"></b-form-input>
                <template v-if="typeof errors.duration !== 'undefined'">
                    <div class="form__error" v-for="(error, index) in errors.duration" :key="index">{{error}}</div>
                </template>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Expire Date</h6>
                <date-picker v-model="expDate" placeholder="Select a date" format = "MM/DD/YYYY" class="mb-2 w-100"></date-picker>
            </div>

            <div class="form__item">
                <h6 class="heading6 mb-1">Usage Limit</h6>
                <b-form-input type="number" class="form-control--mid mb-3" v-model="coupon.usage_limit"></b-form-input>

                <template v-if="typeof errors.usage_limit !== 'undefined'">
                    <div class="form__error" v-for="(error, index) in errors.usage_limit" :key="index">{{error}}</div>
                </template>
            </div>

            <div v-if="false" class="form__item">
                <h6 class="heading6 mb-1">Subscription Plan</h6>
                <b-form-select class="form-control--mid mb-3" v-model="coupon.plan_name">
                    <option value="" disabled>Select Plan</option>
                    <option v-for="plan in plansList" :key="plan.id" :value="plan.id">
                        {{plan.name}}
                    </option>
                </b-form-select>

                <template v-if="typeof errors.plan_name !== 'undefined'">
                    <div class="form__error" v-for="(error, index) in errors.plan_name" :key="index">{{error}}</div>
                </template>
            </div>
        </form>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save"><template v-if="id">Update</template><template v-else>Add</template></b-button>
        </template>
    </b-modal>
</template>

<script>
import coupons from "@/services/coupons.service"
import DatePicker from "vue2-datepicker";

export default {
    components: {
        DatePicker
    },

    data() {
        return {
            id: 0,

            coupon: {
                code: "",
                description: "",
                duration: 8,
                exp_date: null,
                usage_limit: 1,
                plan_name: "",
            },

            expDate: null,
            plansList: [
                {
                    id: 'monthly',
                    name: 'Monthly'
                },
                {
                    id: 'annually',
                    name: 'Annually'
                },
            ],

            errors: {},
        }
    },

    mounted() {

    },

    watch: {
        expDate() {
            if (this.expDate) {
                this.coupon.exp_date = this.dateHelper.encodeDateForServer(this.expDate)
            }
            else {
                this.coupon.exp_date = null
            }
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Coupon"
            } else {
                return "Add New Coupon"
            }
        },
    },

    methods: {

        init() {
            this.expDate = null
            this.errors = {}

            if (this.id) {
                coupons.getEdit(this.id).then(response => {
                    this.coupon = response.data

                    if (this.coupon.exp_date) {
                        this.expDate = this.dateHelper.decodeDateFromServer(this.coupon.exp_date)
                    }
                })
            }
        },

        open(id = 0) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-edit-coupon')
        },

        save() {
            coupons.save(this.id, this.coupon).then((response) => {

                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors
                }
                else {
                    this.$bvModal.hide('modal-edit-coupon')
                    this.$emit('coupon-saved')
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
