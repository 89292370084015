<template>
    <div @click="() => {if (editable && !disabled) editMode = true}"
         @keydown.esc="resetNewValue"
         v-click-outside="clickedOutside"
         :class="editable ? 'min-30' : ''"
         @mouseover="() => {if ((['text', 'textarea', 'select', 'multiselect', 'users_list'].indexOf(field.type) >= 0 && fromTable && !disabled)) editMode = true}"
         @mouseleave="() => {if (['text', 'textarea', 'select', 'multiselect', 'users_list', 'links_list'].indexOf(field.type) >= 0 && fromTable && !forceShow && !disabled) editMode = false}"
    >

        <span v-if="loading"><b-spinner class="mr-1" variant="secondary" small></b-spinner></span>

        <template v-if="field.type === 'text' || field.type === 'numeric'">

            <template v-if="editMode">
                <input v-if="field.type === 'text'" type="text" v-model="newValue.value" :disabled="disabled" @keyup.enter="saveValue" @input="forceShow = true" class="form-control--mid form-control">
                <input v-if="field.type === 'numeric'" :min="field.min_value" :max="field.max_value" type="number" v-model="newValue.value" :disabled="disabled" @keyup.enter="saveValue" @input="forceShow = true" class="form-control--mid form-control">
            </template>

            <template v-else>
                <template v-if="value.value.length">
                    <template v-if="field.type === 'numeric'">
                        {{parseInt(value.value).toLocaleString()}}
                    </template>

                    <template v-else>
                        {{value.value}}
                    </template>
                </template>
            </template>

            <a @click="editMode = true" v-show="!editMode" v-if="!fromTable && !disabled && editable && !value.value.length" class="link">
                Add
            </a>

        </template>

        <template v-if="field.type === 'textarea'">
            <editor
                v-if="editMode"
                v-model="newValue.value"
                :apiKey="apiKeys.tinyMCE"
                :init="editorOptions"
                :disabled="disabled"
                model-events="focus blur"
                @onFocus="editorFocused = true"
                @onBlur="editorFocused = false"
                @input="forceShow = true"
            >
            </editor>

            <div v-show="!editMode">

                <template v-if="field.markdown">
                    <v-clamp autoresize :max-lines="3" v-if="featuresClamped"> {{value.text}}
                        <template #after>
                            <a v-if="featuresClamped" class="link-normal" @click="() => {featuresClamped = false}"> more</a>
                        </template>
                    </v-clamp>

                    <template v-else>
                        <markdown>
                            {{value.value}}
                        </markdown>

                        <a class="link-normal" @click="() => {featuresClamped = true}"> less</a>
                    </template>
                </template>

                <div v-else-if="value.value.length" v-html="value.value" v-bind:class="{'line-clamp':fromTable, 'line-clamp1':fromTable}"/>

                <template v-else>
                    <a v-if="!fromTable && editable" class="link">
                        Add
                    </a>
                </template>
            </div>
        </template>

        <template v-if="['select', 'multiselect', 'users_list', 'pipeline'].indexOf(field.type) >= 0">
            <template v-if="(editMode || !fromTable) && editable && !disabled">
                <multiselect
                    v-if="field.type === 'select' || field.type === 'pipeline'"
                    v-model="singleOption"
                    :options="field.options"
                    select-label=""
                    @input="editMode = true"
                    deselect-label=""
                    label="value"
                    track-by="value"
                    class="multiselect-mid"
                    :title="(singleOption) ? singleOption.value : ''"
                    :disabled="disabled"
                >
                </multiselect>

                <multiselect
                    v-else
                    v-model="newValue.options"
                    :multiple="true"
                    :options="field.options"
                    @input="editMode = true"
                    select-label=""
                    deselect-label=""
                    label="value"
                    track-by="value"
                    class="multiselect-mid"
                    :disabled="disabled"
                >
                </multiselect>
            </template>

            <template v-else>
                <template v-if="value.options.length">
                    <template v-for="(option, index) in value.options">
                        {{ option.value }}
                        <template v-if="index < value.options.length - 1">, </template>
                    </template>
                </template>

                <template v-else>
                    <template v-if="!fromTable && editable && !disabled">
                        <span class="link-click__text">
                            Click Here To Add Value
                        </span>
                    </template>
                </template>
            </template>
        </template>

        <div class="tags-list tags-list--light">
            <template v-if="field.type === 'file'">
                <span v-for="(file, index) in value.files" :key="index" class="tags-list__tag">
                    <div class="image-block image-block--rounded image-block--rounded--xxs">
                         <Avatar
                             :username="file.user_name"
                             :src="file.user_avatar"
                             :title="file.file_info"
                             :size="27">
                        </Avatar>
                    </div>

                    <template v-if="file.id > 0">
                        <a v-if="!forPDF" @click="downloadFile(file.id, file.file_name)" :title="file.file_info">{{ file.file_name }}</a>
                        <template v-else>
                            <a>{{ file.file_name }}</a>
                        </template>
                        <a v-if="editable && !disabled && file.user_can_delete" @click="deleteFile(file.id, index)" class="tags-list__tag__close"></a>
                    </template>

                    <template v-else>
                        <a :title="file.name">{{file.name}}</a>
                        <a v-if="editable && !disabled" @click="localValue.files.splice(index, 1)" class="tags-list__tag__close"></a>
                    </template>
                </span>

                <template v-if="editable && !disabled">
                    <a @click="$bvModal.show('upload-file-' + startupId + '-' + field.id)" class="link">Add File</a>

                    <b-modal title="Upload File" :id="'upload-file-' + startupId + '-' + field.id" size="md" hide-footer>
                        <input type="file" multiple ref="newFile" v-on:change="handleFileUpload()">
                        <br>
                        <br>
                    </b-modal>
                </template>
            </template>
        </div>

        <template v-if="field.type === 'date'">

            <template v-if="editMode">
                <date-picker v-model="rawDate"
                    format = "MM/DD/YYYY"
                    :disabled="disabled"
                    @change="saveValue"
                >
                </date-picker>
            </template>

            <template v-else>
                <template v-if="value.value">
                    {{value.value}}
                </template>
                <template v-else>
                    <template v-if="!fromTable && !disabled">
                        <a class="link">
                            Add
                        </a>
                    </template>
                </template>
            </template>

        </template>

        <template v-if="field.type === 'links_list'">
            <div class="links-controls" v-for="(link, index) in value.links" :key="index">
                <a v-if="stringHelper.isYoutubeLink(link.url)" class="youtube-url" @click.stop="showYoutubeVideo(link.url)">{{link.title}}</a>
                <a v-else :href="stringHelper.completeLink(link.url)" target="_blank" @click.stop>{{link.title}}</a>
            </div>

            <a v-if="!disabled" @click.stop="$refs.editLinks.open(value.links)" class="link-wrapper">
                <template v-if="value.links.length"><span class="link-wrapper__edit">Edit</span></template>
                <template v-else><span class="link-wrapper__add">Add</span></template>
                Links
            </a>

            <edit-links
                :index="startupId + '-' + field.id"
                ref="editLinks"
                v-on:saved="saveLinks"
            >
            </edit-links>

            <b-modal :id="'modal-show-youtube-' + startupId + '-' + field.id" modal-class="modal-transparent" :hide-header="true" :hide-footer="true" size="md">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="420" height="315" :src="currentYoutubeUrl"></iframe>
                </div>
            </b-modal>
        </template>

<!--        <template v-if="!editMode">-->
<!--            <a class="link" @click="editMode = true">Edit</a>-->
<!--        </template>-->
<!--        <template v-else>-->
<!--            <a class="link" @click="editMode = false; resetNewValue()">Cancel</a>-->
<!--            <a class="link" @click="saveValue">Save</a>-->
<!--        </template>-->
    </div>
</template>

<script>

import startups from "../../services/startups.service"
import startupProfile from "../../services/startup/profile.service"
import trackersCompanies from "../../services/trackers-companies.service"
import publicFiles from "../../services/public-files.service"
import files from "../../services/files.service"
import multiselect from 'vue-multiselect'
import editor from "@tinymce/tinymce-vue";
import DatePicker from "vue2-datepicker";
import EditLinks from "../../components/customFields/EditLinks";
import Avatar from "vue-avatar";
import markdown from 'vue-markdown'
import VClamp from "vue-clamp";

export default {
    props: {
        field: {
            type: Object,

            default() {
                return {
                    id: 0,
                    name: "",
                    type: "",
                    options: [],
                };
            }
        },

        inline: {
            type: Boolean,
            default: true,
        },

        fromTable: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Object,

            default() {
                return {
                    value: "",
                    options: [],
                    files: [],
                    links: []
                }
            },
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: true,
        },

        startupId: {
            type: Number,
            default: 0,
        },

        instantUpdate: {
            type: Boolean,
            default: true,
        },

        trackerId: Number,

        index: {
            type: Number,
            default: 0,
        },

        forPDF: {
            type: Boolean,
            default: false,
        },

        fromTrackerApplication: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        VClamp,
        multiselect,
        editor,
        DatePicker,
        EditLinks,
        Avatar,
        markdown,
    },

    data() {
        return {
            editMode: false,
            forceShow: false,

            rawDate: null,
            newValue: {
                value: "",
                options: [],
                links: [],
                files: [],
            },

            singleOption: null,
            editorFocused: false,
            watchValue: false,
            loading: false,
            currentYoutubeUrl: null,
            featuresClamped: true,
            localValue: this.value,
        }
    },

    mounted() {
        if (!this.inline) {
            this.editMode = true
        }

        this.resetNewValue()
    },

    computed: {
        editorOptions() {
            if (this.fromTable) {
                return this.defaults.editorOptionsEmpty
            }
            else {
                return this.defaults.editorOptions
            }
        },

        customFieldsService() {
            if (this.$store.getters.isStartup) {
                return startupProfile;
            } else {
                return startups;
            }
        }
    },

    watch: {
        value: {
            deep: true,
            handler()
            {
                this.localValue = this.value;

                if (this.inline) {
                    this.resetNewValue()
                }
            }
        },

        singleOption() {
            if (this.singleOption) {
                this.newValue.options = [this.singleOption]
            }
            else {
                this.newValue.options = []
            }
        },

        newValue: {
            deep: true,
            handler() {
                if (this.watchValue) {
                    if (!this.instantUpdate) {
                        this.$emit("customValueChanged", {index: this.index, value: this.newValue})
                    } else {
                        if (this.editMode && ['select', 'multiselect', 'users_list', 'pipeline'].includes(this.field.type)) {
                            this.saveValue()
                        }
                    }
                }
            }
        },

        rawDate() {
            this.newValue.value = this.dateHelper.encodeDateForServer(this.rawDate)
        },
    },

    methods: {
        clickedOutside() {
            if ((this.field.type !== 'textarea' || !this.editorFocused) && this.field.type !== 'date' && this.field.type !== 'links_list') {
                this.saveValue()
            }
        },

        resetNewValue() {
            if (!this.disabled) {
                this.watchValue = false
                this.newValue.value = this.value.value
                this.newValue.options = []
                this.singleOption = null

                if (this.field.type === 'select') {
                    if (this.value.options.length) {
                        this.singleOption = this.value.options[0]
                    }
                } else if ('options' in this.value) {
                    this.value.options.forEach(item => {
                        this.newValue.options.push({
                            id: item.id,
                            value: item.value
                        })
                    })
                }

                this.newValue.links = []

                if ('links' in this.value) {
                    this.value.links.forEach(item => {
                        this.newValue.links.push({
                            id: item.id,
                            title: item.title,
                            url: item.url,
                        })
                    })
                }

                this.newValue.files = [];
                if ('files' in this.value) {
                    this.newValue.files = [...this.value.files];
                }

                if (this.field.type === 'date') {
                    this.rawDate = this.dateHelper.encodeDate(this.value.value)
                }

                if (this.inline) {
                    this.editMode = false
                }

                this.$nextTick(() => {
                    this.watchValue = true
                })

                this.forceShow = false
            }
        },

        handleFileUpload() {
            let files = this.$refs['newFile'].files

            if (this.inline) {
                let formData = new FormData();

                for (let i in files) {
                    if (typeof files[i] === "object") {
                        formData.append('file[]', files[i])
                    }
                }

                if (this.field.main_type === 'company_diligence') {
                    this.customFieldsService.saveCustomFiles(this.startupId, this.field.id, formData).then((response) => {
                        this.$bvModal.hide('upload-file-' + this.startupId + '-' + this.field.id)
                        this.$emit("valueChanged", [this.field.id, response.data]);
                    })
                }

                if (this.field.main_type === 'tracker_diligence') {
                    trackersCompanies.saveDiligenceFiles(this.trackerId, this.startupId, this.field.id, formData).then(() => {
                        this.$bvModal.hide('upload-file-' + this.startupId + '-' + this.field.id)
                        this.$emit("valueChanged")
                    })
                }
            }
            else {
                for (let i in files) {
                    if (typeof files[i] === "object") {
                        files[i].id = 0
                        files[i].file_name = files[i].name
                        files[i].user_name = this.$store.state.user.name
                        files[i].user_avatar = this.$store.state.user.avatar
                        this.newValue.files.push(files[i])
                    }
                }
                this.$bvModal.hide('upload-file-' + this.startupId + '-' + this.field.id)
            }
        },

        saveValue() {
            if (this.editMode && this.inline && this.instantUpdate && this.field.type !== 'file') {
                this.loading = true
                let newValue = this.newValue
                this.editMode = false

                if (this.field.type === 'date') {
                    newValue.value = this.dateHelper.encodeDateForServer(this.rawDate)
                }

                if (this.field.main_type === 'company_diligence') {
                    this.customFieldsService.saveCustomValue(this.startupId, this.field.id, {newValue: newValue}).then(response => {
                        this.loading = false

                        for (let field in response.data.errors) {
                            response.data.errors[field].forEach(error => {
                                alert(error)
                            })
                        }

                        this.$emit("valueChanged", [this.field.id, response.data]);
                    })
                }

                if (this.field.main_type === 'tracker_diligence') {
                    trackersCompanies.saveDiligenceValues(this.trackerId, this.startupId, this.field.id, {newValue: newValue}).then(response => {
                        this.loading = false

                        for (let field in response.data.errors) {
                            response.data.errors[field].forEach(error => {
                                alert(error)
                            })
                        }

                        this.$emit("valueChanged")
                    })
                }
            }
        },

        downloadFile(id, name) {
            let service

            if (this.fromTrackerApplication && this.$store.state.companyType != 'enterprise') {
                service = publicFiles
            }
            else {
                service = files
            }

            service.downloadCustomFile(id).then(response => {
                if (response.data.type === 'application/pdf') {
                    window.open('/files/' + id, '_blank')
                }
                else {
                    this.fileHelper.download(response.data, name)
                }
            })
        },

        deleteFile(id, index) {
            if (confirm("Delete file?")) {
                files.deleteCustomFile(id).then((response) => {

                    if (this.inline) {
                        this.$emit("valueChanged", [this.field.id, response.data]);
                    }
                    else {
                        this.newValue.files.splice(index, 1)
                    }
                })
            }
        },

        saveLinks(links) {
            this.newValue.links = links
            this.editMode = true;
            this.saveValue();
        },

        showYoutubeVideo(url) {
            this.currentYoutubeUrl = this.stringHelper.getYoutubeEmbeddedUrl(url);
            this.$bvModal.show('modal-show-youtube-' + this.startupId + '-' + this.field.id);
        }
    }
}
</script>

<style scoped>

</style>
