import axios from '@/axios';

const RESOURCE_NAME = '/admin/connections';

export default {

    getConnections(data) {
        return axios.post(`${RESOURCE_NAME}/get-connections`, data)
    },

    getInvites(data) {
        return axios.post(`${RESOURCE_NAME}/get-invites`, data)
    },
}
