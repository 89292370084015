import axios from '@/axios';

const RESOURCE_NAME = '/admin/suggestion-keywords-blacklist';

export default {

    getKeywords(data) {
        return axios.post(`${RESOURCE_NAME}/get-keywords`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-keyword?id=${id}`)
    },

    add(data) {
        return axios.post(`${RESOURCE_NAME}/add`, data)
    },
}
