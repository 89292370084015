import Enterprises from "@/views/consoleCommands/ConsoleCommandsDashboard";

export default [
    {
        path: '/console-commands',
        component: Enterprises,
        meta: {
            access: ['admin'],
        }
    },
]
