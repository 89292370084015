import axios from '@/axios';

const RESOURCE_NAME = '/admin/merge-startups';

export default {
    getStartups(data) {
        return axios.post(`${RESOURCE_NAME}/get-startups`, data)
    },

    getStartupsWithSimilarDomains(data) {
        return axios.post(`${RESOURCE_NAME}/get-startups-with-similar-domains`, data)
    },

    getMergeData(url, mergeIds) {
        return axios.get(`${RESOURCE_NAME}/get-merge-data?url=${url}&mergeIds=${mergeIds}`)
    },

    merge(selectedId, otherIds) {
        return axios.post(`${RESOURCE_NAME}/merge`, {selectedId, otherIds})
    }
}
