<template>
    <b-modal id="modal-ecosystem-form" :title="title" @hide="onCloseModal">
        <div class="form mt-3">
            <div class="d-flex align-items-center mb-1">
                <div class="image-block image-block--rounded--lg mb-4">
                    <img v-if="this.form.logo" :src="this.form.logo" alt="img">
                </div>
                <div>
                    <a @click="showLogoUpload = true" class="btn btn-primary">Upload Logo</a>
                    <p class="modal-text m-0">Image size: 200 X 200 pixels, max file size 5 mb</p>
                </div>
            </div>

            <image-upload
                v-model="showLogoUpload"
                :width="200"
                :height="200"
                :noSquare="true"
                langType="en"
                @crop-success="logoCropped"
            >
            </image-upload>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Ecosystem Name*</h5>
                <template v-if="typeof errors.name !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
                </template>
                <b-form-input class="form-control--mid mb-3" v-model="form.name"></b-form-input>

                <div v-if="typeof lastUpdate['name'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['name'].date)}},
                    {{ lastUpdate['name'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Ecosystem URL*</h5>

                <template v-if="typeof errors.url !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                </template>

                <b-form-input class="form-control--mid mb-2" v-model="form.url"></b-form-input>

                <div v-if="typeof lastUpdate['url_domain'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['url_domain'].date)}},
                    {{ lastUpdate['url_domain'].source }})
                </div>

                <p class="modal-text">Ecosystem URLs are checked against existing ecosystems on SwitchPitch to avoid duplication</p>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Website</h5>

                <template v-if="typeof errors.website !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.website" :key="index">{{error}}</span>
                </template>

                <b-form-input class="form-control--mid mb-2" v-model="form.website"></b-form-input>

                <div v-if="typeof lastUpdate['website'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['website'].date)}},
                    {{ lastUpdate['website'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Ecosystem Type</h5>
                <select class="form-control--mid form-control" v-model="form.type">
                    <option v-for="(label, value) in constants.ecosystemTypes" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Categories*</h5>

                <template v-if="typeof errors.categories !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.categories" :key="index">{{error}}</span>
                </template>

                <multiselect
                    v-model="categories"
                    :multiple="true"
                    placeholder="Select Categories"
                    :options="$store.state.categories"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>

                <div v-if="typeof lastUpdate['categories'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['categories'].date)}},
                    {{ lastUpdate['categories'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Portfolio URL</h5>

                <template v-if="typeof errors.portfolio_url !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.portfolio_url" :key="index">{{error}}</span>
                </template>

                <b-form-input class="form-control--mid mb-2" v-model="form.portfolio_url"></b-form-input>

                <div v-if="typeof lastUpdate['portfolio_url'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['portfolio_url'].date)}},
                    {{ lastUpdate['portfolio_url'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Country*</h5>

                <template v-if="typeof errors.country_id !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.country_id" :key="index">{{error}}</span>
                </template>

                <multiselect
                    v-model="country"
                    placeholder="Select Country"
                    :options="countriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>

                <div v-if="typeof lastUpdate['country_id'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['country_id'].date)}},
                    {{ lastUpdate['country_id'].source }})
                </div>
            </div>

            <div class="form__item">
                <template v-if="form.country_id == 1">
                    <h5 class="heading5 heading5--secondary">State*</h5>
                    <multiselect
                        v-model="state"
                        placeholder="Select State"
                        :options="statesList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                    >
                    </multiselect>

                    <div v-if="typeof lastUpdate['state_id'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                        (last edit: {{dateHelper.decodeDate(lastUpdate['state_id'].date)}},
                        {{ lastUpdate['state_id'].source }})
                    </div>
                </template>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Description</h5>
                <b-form-textarea class="form-control--mid mb-3" v-model="form.description"></b-form-textarea>

                <div v-if="typeof lastUpdate['description'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['description'].date)}},
                    {{ lastUpdate['description'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Short Description</h5>
                <b-form-textarea class="form-control--mid mb-3" v-model="form.short_description"></b-form-textarea>

                <div v-if="typeof lastUpdate['short_description'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['short_description'].date)}},
                    {{ lastUpdate['short_description'].source }})
                </div>
            </div>

            <div v-if="form.type == 'event'" class="form__item">
                <div class="row">
                    <div class="col-6">
                        <h5 class="heading5 heading5--secondary">Start Date</h5>
                        <date-picker class="w-100" format = "MM/DD/YYYY" v-model="startDate"></date-picker>
                        <div v-if="typeof lastUpdate['start_date'] !== 'undefined'" style="font-size:11pt; color:grey" class="text-right">
                            (last edit: {{dateHelper.decodeDate(lastUpdate['start_date'].date)}},
                            {{ lastUpdate['start_date'].source }})
                        </div>
                    </div>

                    <div class="col-6">
                        <h5 class="heading5 heading5--secondary">End Date</h5>
                        <date-picker class="w-100" format = "MM/DD/YYYY" v-model="endDate"></date-picker>
                        <div v-if="typeof lastUpdate['end_date'] !== 'undefined'" style="font-size:11pt; color:grey" class="text-right">
                            (last edit: {{dateHelper.decodeDate(lastUpdate['end_date'].date)}},
                            {{ lastUpdate['end_date'].source }})
                        </div>
                    </div>
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Crawl Status</h5>
                <select class="form-control--mid form-control" v-model="form.status">
                    <option value=""></option>
                    <option :value="id" v-for="(name, id) in constants.ecosystemStatuses" :key="id">{{ name }}</option>
                </select>

                <div v-if="typeof lastUpdate['status'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['status'].date)}},
                    {{ lastUpdate['status'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Last Crawled</h5>
                <date-picker class="w-100" format = "MM/DD/YYYY" v-model="lastCrawled"></date-picker>

                <div v-if="typeof lastUpdate['last_crawled'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['last_crawled'].date)}},
                    {{ lastUpdate['last_crawled'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Account Type</h5>
                <select class="form-control--mid form-control" v-model="form.account_type">
                    <option v-for="(item, index) in constants.ecosystemAccountTypes" :value="index" :key="index">{{item}}</option>

                </select>
            </div>

            <div class="form__item input-checkbox">
                <input type="checkbox" id="sync_related_startups" v-model="form.sync_related_startups">
                <label for="sync_related_startups">Sync related startups from Crunchbase</label>
            </div>

            <div class="form__item input-checkbox">
                <input type="checkbox" id="hide_trackers_shared_to_all" v-model="form.hide_trackers_shared_to_all">
                <label for="hide_trackers_shared_to_all">Hide trackers shared for all</label>
            </div>

            <div class="form__item input-checkbox">
                <input type="checkbox" id="enterprise_access_for_startups" v-model="form.enterprise_access_for_startups">
                <label for="enterprise_access_for_startups">Enterprise Access for Startups</label>
            </div>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">
                <span v-if="!loading">Save</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Saving...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import ecosystems from "@/services/ecosystems.service"
import multiselect from "vue-multiselect";
import Vue from 'vue';
import locations from "@/services/locations.service";
import imageUpload from "vue-image-crop-upload";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        multiselect,
        imageUpload,
        DatePicker,
    },

    data() {
        return {
            id: 0,
            form: {
                logo: "",
                name: "",
                url: "",
                website: "",
                description: "",
                short_description: "",
                start_date: null,
                end_date: null,
                account_type: "",
                type: "",
                categories: [],
                country_id: 0,
                state_id: 0,
                status: "",
                sync_related_startups: 1,
                hide_trackers_shared_to_all: 0,
                enterprise_access_for_startups: 0,
            },

            categories: [],

            lastCrawled: null,
            startDate: null,
            endDate: null,

            lastUpdate: {},
            showLogoUpload: false,
            logoChanged: false,

            country: null,
            state: null,
            countriesList: [],
            statesList: [],

            errors: {},
            result: "",
            file: null,
            loading: false,
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Ecosystem"
            }
            else {
                return "Add Ecosystem"
            }
        },

        canSave() {
            if (this.loading) {
                return false
            }
            else {
                return this.form.name.length && this.form.url.length && this.form.country_id && this.categories.length
            }
        }
    },

    watch: {
        country() {
            if (this.country) {
                this.form.country_id = this.country.id
            } else {
                this.form.country_id = 0
            }
        },

        state() {
            if (this.state) {
                this.form.state_id = this.state.id
            }
            else {
                this.form.state_id = 0
            }
        },
    },

    mounted() {
        locations.getCountriesList().then(response => {
            this.countriesList = response.data
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
        })
    },

    methods: {
        init() {
            this.form.logo = ""
            this.form.name = ""
            this.form.url = ""
            this.form.website = ""
            this.categories = []
            this.form.portfolio_url = ""
            this.form.country_id = 0
            this.form.state_id = 0
            this.form.description = ""
            this.form.short_description = ""
            this.form.account_type = ""
            this.form.type = ""
            this.form.status = ""
            this.form.sync_related_startups = 1
            this.form.hide_trackers_shared_to_all = 0
            this.form.enterprise_access_for_startups = 0
            this.country = null
            this.state = null
            this.lastCrawled = null
            this.startDate = null
            this.endDate = null
            this.errors = {}
            this.loading = false

            if (this.id > 0) {
                ecosystems.getEdit(this.id).then(response => {
                    this.form = response.data.data
                    this.lastUpdate = response.data.last_update
                    this.startDate = this.dateHelper.decodeDateFromServer(this.form.start_date)
                    this.endDate = this.dateHelper.decodeDateFromServer(this.form.end_date)
                    this.setCountry()
                    this.setState()
                    this.setCategories()
                    this.lastCrawled = this.dateHelper.decodeDateFromServer(this.form.last_crawled)
                })
            }
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-ecosystem-form')
        },

        save() {
            this.loading = true
            this.result = ""

            if (this.logoChanged) {
                this.form.newLogo = this.form.logo
            }

            if (this.lastCrawled instanceof Date) {
                this.form.last_crawled = this.dateHelper.encodeDateForServer(this.lastCrawled)
            }
            else {
                this.form.last_crawled = null
            }

            this.form.start_date = this.dateHelper.encodeDateForServer(this.startDate)
            this.form.end_date = this.dateHelper.encodeDateForServer(this.endDate)
            this.form.categories = this.categories.map(item => item.id)

            ecosystems.save(this.id, this.form).then(response => {
                this.errors = response.data.errors
                this.result = response.data.result

                if (! Object.keys(this.errors).length) {
                    this.$bvModal.hide('modal-ecosystem-form');

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: 'Ecosystem saved'
                    })

                    this.$emit('ecosystem-created')
                }

            }).finally(() => {
                this.loading = false
            })
        },

        setCountry() {
            if (!this.country && this.form.country_id) {
                this.countriesList.forEach(item => {
                    if (item.id == this.form.country_id) {
                        this.country = item
                        return
                    }
                })
            }
        },

        setState() {
            if (!this.state && this.form.state_id) {
                this.statesList.forEach(item => {
                    if (item.id === this.form.state_id) {
                        this.state = item
                        return
                    }
                })
            }
        },

        setCategories() {
            this.categories = this.$store.state.categories.filter(item =>
                this.form.categories.some(categoryId => categoryId === parseInt(item.id))
            )
        },

        logoCropped(imageDataUrl) {
            this.form.logo = imageDataUrl
            this.showLogoUpload = false
            this.logoChanged = true
        },

        onCloseModal() {
            this.$emit('modal-closed');
        }
    }
}
</script>

<style scoped>

</style>
