import qs from 'qs'
import axiosBase from 'axios'

import store from '@/store'

const token = window.localStorage.getItem('token')

export const baseURL = process.env.VUE_APP_API_ENDPOINT

const axios = axiosBase.create({
    baseURL,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
})

if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.interceptors.response.use(
    response => {
        if (response.config.url === '/auth/login' && response.status === 200 && response.data.user?.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.user.token
        }

        return response
    },
    error => {
        if (typeof error.response !== "undefined") {
            if (error.response.status === 500) {
                alert('Server error occurred')
            }

            if (error.response.status === 401) {
                store.commit('setNeedLogout', true)
            }

            if (error.response.status === 404) {
                store.commit('setPageNotFound', true)
            }

            if (process.env.NODE_ENV === 'development') {
                console.log(error.response.data)
            }
        }
        throw error;
    }
)

export default axios
