<template>
    <b-modal id="modal-duplicates-form" modal-class="modal-w-xxxl" title="Duplicates" hide-footer>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"/>

                <Grid
                    :columns="columns"
                    :data="items"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                />
            </div>
        </div>
    </b-modal>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import PulseLoader from "vue-spinner/src/MoonLoader.vue";
import clientsService from "@/services/clients.service";

export default {
    components: {
        Grid,
        PulseLoader,
    },

    mixins: [grid],

    data() {
        return {
            type: '',

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                },
                {
                    label: 'Name',
                    name: 'name',
                },
                {
                    label: 'Startups Count',
                    name: 'own_startups_count_for_duplicate',
                },
            ],
        }
    },

    methods: {
        open(id) {
            this.items = [];
            this.$bvModal.show('modal-duplicates-form');
            this.filters = {merged_to_id: id};
            this.getList()
        },

        getList() {
            let data = this.getRequestParams();

            this.loading = true
            this.totalRowsCount = 0

            clientsService.getClients(data).then(response => {
                this.items = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>

<style scoped>

</style>
