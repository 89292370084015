<template>
    <div>
        <div class="main-table-wrapper">
            <div class="main-table overflow-auto" style="position: relative">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"/>

                <Grid
                    :columns="columns"
                    :data="items"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                />
            </div>
        </div>

        <Details
            ref="details"
        />

        <GvDetails
            ref="gvDetails"
        />
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import PulseLoader from "vue-spinner/src/MoonLoader.vue";
import crawledDataService from "@/services/crawled-data.service";
import Details from "@/components/clientsDiscovery/Details.vue";
import GvDetails from "@/components/clientsDiscovery/GvDetails.vue";

export default {
    components: {
        GvDetails,
        Details,
        Grid,
        PulseLoader,
    },

    mixins: [grid],

    data() {
        return {
            getListMethod: crawledDataService.getList,

            columns: [
                {
                    label: 'Startup ID',
                    name: 'startup_id',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Startup Name',
                    name: 'startup_name',
                },
                {
                    label: 'Website',
                    name: 'startup_website',
                    component: 'RawColumn'
                },
                {
                    label: 'Clients AI',
                    name: 'startup_clients',
                },
                {
                    label: 'Clients',
                    name: 'clients',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Partners',
                    name: 'partners',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Unknown',
                    name: 'unknown',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Process Date',
                    name: 'process_date',
                    sortable: true,
                },
                {
                    label: 'Pages Processed',
                    name: 'pages_processed',
                    sortable: true,
                },
                {
                    label: 'Status',
                    name: 'status',
                    sortable: true,
                    filter: 'single-select',
                    options: [
                        {
                            id: "new",
                            name: 'New'
                        },
                        {
                            id: "done",
                            name: 'Done'
                        },
                    ]
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                },
            ],
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        gridAction(params) {
            if (params.action === 'download-raw') {
                crawledDataService.getResponseJson(params.rowId).then(response => {
                    this.fileHelper.download(response.data, `response.json`)
                })
            } else if (params.action === 'show-details') {
                this.$refs.details.open(params.rowId);
            } else if (params.action === 'show-gv-details') {
                this.$refs.gvDetails.open(this.items.find(item => item.id === params.rowId).gv_data);
            }
        }
    }
}
</script>

<style scoped>

</style>
