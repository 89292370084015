<template>
    <b-modal id="edit-item-form" modal-class="modal-w-xxl" :title="title">
        <form class="form pb-5">
            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Type *</h5>
                <select v-model="form.type" class="selectbox selectbox-mid w-100">
                    <option v-for="(label, value) in helperTypes" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Source ID</h5>
                <input @blur="idFilled" class="form-control--mid form-control" type="text" v-model="form.source_id">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Name *</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.name">
            </div>

            <figure v-if="form.thumbnail_url">
                <img :src="form.thumbnail_url" :alt="form.name"/>
            </figure>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Notes</h5>
                <textarea class="form-control--mid form-control" v-model="form.notes"/>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Page *</h5>
                <multiselect
                    v-model="pages"
                    :multiple="true"
                    placeholder="Select Pages"
                    :options="pagesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Status *</h5>
                <select v-model="form.status" class="selectbox selectbox-mid w-100">
                    <option v-for="(label, value) in statuses" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <div class="form__item input-checkbox">
                <input id="isPrivate" type="checkbox" v-model="form.is_private">
                <label for="isPrivate">Is private</label>
            </div>

            <div v-if="form.is_private" class="form__item">
                <h5 class="heading5 heading5--secondary">Select Enterprises</h5>

                <multiselect
                    v-model="enterprises"
                    :multiple="true"
                    placeholder="Select Company"
                    :options="enterprisesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateEnterprises"
                >
                </multiselect>
            </div>

            <template v-if="form.type === 'knowledge_base'">
                <div class="form__item input-checkbox">
                    <input id="internal_article" type="checkbox" v-model="form.internal_article">
                    <label for="internal_article">Internal Article</label>
                </div>

                <editor
                    v-if="form.internal_article"
                    :apiKey="apiKeys.tinyMCE"
                    v-model="form.text"
                    :init="editorOptions"
                />
            </template>
        </form>

        <template #modal-footer>
            <primary-button
                :disabled="!canSave"
                caption="Save"
                loading-caption="Saving..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import helperItemsService from "@/services/helper-items.service";
import multiselect from "vue-multiselect"
import {helperTypes, pages, statuses} from "../../../../frontend/src/constants/helperItems";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
    components: {
        PrimaryButton,
        multiselect,
        Editor,
    },

    data() {
        return {
            id: 0,

            loading: false,
            form: {
                name: "",
                notes: "",
                type: null,
                source_id: "",
                status: null,
                thumbnail_url: null,
                is_private: null,
                internal_article: null,
                text: '',
            },

            pages: [],
            enterprises: [],
            enterprisesList: [],

            editorOptions: {
                menubar: false,
                statusbar: false,
                plugins: ['lists','link','image'],
                images_upload_handler: this.uploadImage,
                toolbar:
                    'bold italic | alignleft aligncenter alignright | bullist numlist | link | image | removeformat',
                link_assume_external_targets: 'http'
            }
        }
    },

    computed: {
        statuses() {
            return statuses
        },

        helperTypes() {
            return helperTypes
        },

        pagesList() {
            let list = [];
            for (let i in pages) {
                list.push({
                    value: i,
                    name: pages[i],
                })
            }

            return list;
        },

        title() {
            if (this.id) {
                return "Edit Help Record"
            } else {
                return "Add Help Record"
            }
        },

        canSave() {
            return this.form.name.length && this.form.type && this.form.status && this.pages.length;
        },
    },

    methods: {
        init() {
            this.form.name = "";
            this.form.notes = "";
            this.form.type = null;
            this.form.source_id = "";
            this.form.status = null;
            this.form.thumbnail_url = null;
            this.form.is_private = null;
            this.form.internal_article = null;
            this.form.text = '';
            this.pages = [];
            this.enterprises = [];

            if (this.id) {
                helperItemsService.getEdit(this.id).then(response => {
                    this.form = response.data;
                    this.enterprises = response.data.enterprises

                    response.data.pages.forEach(page => {
                        this.pages.push({
                            value: page,
                            name: pages[page],
                        })
                    })
                })
            }
        },

        open(id = 0) {
            this.id = id;

            this.init()
            this.$bvModal.show('edit-item-form')
        },

        save() {
            this.loading = true
            this.form.pages = this.pages.map(item => item.value);

            if (this.form.is_private) {
                this.form.enterprises = this.enterprises.map(item => item.id);
            } else {
                this.form.enterprises = [];
            }

            helperItemsService.save(this.id, this.form).then(response => {
                if (! Object.keys(response.data.errors).length) {
                    this.$emit('saved')
                    this.$bvModal.hide('edit-item-form')
                }
            }).finally(() => {
                this.loading = false
            })
        },

        idFilled() {
            if (this.form.type === 'video' && this.form.source_id.length) {
                helperItemsService.getVideoInfo(this.form.source_id).then(response => {
                    this.form.thumbnail_url = null;

                    if (response.data) {
                        if ('title' in response.data) {
                            this.form.name = response.data.title;
                        }

                        if ('thumbnail_url' in response.data) {
                            this.form.thumbnail_url = response.data.thumbnail_url;
                        }
                    }
                })
            }
        },

        updateEnterprises(query) {
            if (query.length) {
                helperItemsService.getEnterprisesList(query).then(response => {
                    this.enterprisesList = response.data;
                })
            } else {
                this.enterprisesList = [];
            }
        },

        uploadImage(blobInfo, resolve) {
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            helperItemsService.uploadImage(formData).then(response => {
                resolve(response.data);
            })
        }
    }
}
</script>

<style scoped>

</style>
