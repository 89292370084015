import axios from '@/axios';

const RESOURCE_NAME = '/admin/console-commands';

export default {
    getSummary() {
        return axios.get(`${RESOURCE_NAME}/get-summary`)
    },

    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getChartData(commands, period) {
        return axios.get(`${RESOURCE_NAME}/get-chart-data`, {
            params: {
                commands,
                period
            }
        })
    },

    getStackedChartData(command) {
        return axios.get(`${RESOURCE_NAME}/get-stacked-chart-data`, {
            params: {
                command,
            }
        })
    }
}
