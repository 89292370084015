import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router'
import ClickOutside from 'vue-click-outside'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import '../../frontend/src/assets/stylesheets/bootstrap.min.css'
import '../../frontend/src/assets/stylesheets/style.scss'
import '@/assets/stylesheets/style.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedinIn, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import {
    faUserSecret,
    faDollarSign,
    faShoppingCart,
    faGlobe,
    faUsers,
    faMapMarkerAlt,
    faCheckCircle,
    faTags,
    faPlus,
    faFileCsv,
    faAddressBook,
    faTrash,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faDollarSign, faShoppingCart, faGlobe, faUsers, faMapMarkerAlt, faLinkedinIn, faTwitter, faFacebookSquare, faCheckCircle, faTags, faPlus, faFileCsv, faAddressBook, faTrash, faUserPlus)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import BootstrapVue from 'bootstrap-vue'
import ArrayHelper from '../../frontend/src/helpers/array'
import StringHelper from '../../frontend/src/helpers/string'
import DateHelper from '../../frontend/src/helpers/date'
import FileHelper from '../../frontend/src/helpers/file'
import Defaults from '../../frontend/src/constants/defaults'
import Constants from '../../frontend/src/constants/constants'
import Notifications from 'vue-notification'

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)
Vue.use(Notifications)

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY})

Vue.prototype.mainDomain = process.env.VUE_APP_MAIN_DOMAIN

Vue.prototype.arrayHelper = ArrayHelper
Vue.prototype.stringHelper = StringHelper
Vue.prototype.dateHelper = DateHelper
Vue.prototype.fileHelper = FileHelper

Vue.prototype.defaults = Defaults
Vue.prototype.constants = Constants

Vue.prototype.apiKeys =
    {
        tinyMCE: process.env.VUE_APP_TINYMCE_API_KEY
    }

Vue.use(BootstrapVue)

Vue.directive('click-outside', ClickOutside)

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
