<template>
    <nav v-if="visible">
        <ul class="pagination">
            <li v-if="showPrev" class="page-item"><a class="page-link" @click="changePage(currentPage - 1)"> Previous</a></li>
            <li :class="getPageClass(1)"><a class="page-link" @click="changePage(1)">1</a></li>
            <li v-if="showLeftDots" class="page-item"><a class="page-link disabled">.  .  .</a></li>
            <li v-for="page in pages" :key="page" :class="getPageClass(page)"><a class="page-link" @click="changePage(page)">{{page}}</a></li>
            <li v-if="showRightDots" class="page-item"><a class="page-link disabled">.  .  .</a></li>
            <li :class="getPageClass(pagesCount)"><a class="page-link" @click="changePage(pagesCount)">{{pagesCount}}</a></li>
            <li v-if="showNext" class="page-item"><a class="page-link" @click="changePage(currentPage + 1)"> Next</a></li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "TablePager",
    props : {
        pagesCount: Number,
        initPage: Number,
    },
    data: function() {
        return {
            visible: false,
            showPrev: false,
            showNext: false,
            showRightDots: false,
            showLeftDots: false,
            currentPage: this.initPage,
            pages: [],
        }
    },

    mounted() {
        this.update()
        this.currentPage = this.initPage
    },

    watch: {
        pagesCount()  {
            this.update()
        },

        initPage()  {
            this.currentPage = this.initPage
            this.update()
        },
    },

    methods: {
        update() {
            if (this.pagesCount > 1) {

                this.showPrev = this.currentPage > 1;
                this.showNext = this.currentPage < this.pagesCount;

                if (this.currentPage < this.pagesCount) {
                    this.showNext = true;
                }

                var left_page = this.currentPage - 1;
                var right_page = this.currentPage + 1;

                if (left_page < 1) {
                    left_page = 1;
                    right_page = right_page - (this.currentPage - 1) + 1;
                }

                if (right_page > this.pagesCount) {
                    right_page = this.pagesCount;
                    left_page = left_page - (right_page - this.pagesCount) - 1;
                }

                if (left_page < 1) {
                    left_page = 1;
                }

                var currentPage = left_page;

                this.pages = [];
                while (currentPage <= right_page) {
                    if (currentPage > 1 && currentPage < this.pagesCount) {
                        this.pages.push(currentPage);
                    }
                    currentPage++;
                }

                this.showLeftDots = left_page > 2;

                this.showRightDots = right_page < this.pagesCount - 1;

                this.visible = true;
            }
            else {
                this.visible = false;
            }
        },

        getPageClass: function (page) {
            var classes = {};
            classes['page-item'] = true;
            if (page == this.currentPage) {
                classes['active'] = true;
            }
            return classes;
        },

        changePage: function (page) {
            if (this.currentPage != page) {
                this.currentPage = page;
                this.$emit('changePage', this.currentPage);
            }
        }
    }
}
</script>
