<template>
    <div>

        <div class="topbar">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header>
                        <button class="button" @click="addCoupon">Add coupon</button>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="coupons"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>

        <edit-coupon
            ref="editCoupon"
            v-on:coupon-saved="getList"
        >
        </edit-coupon>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import coupons from "@/services/coupons.service"
import EditCoupon from "@/components/coupons/EditCoupon"


export default {
    components: {
        Grid,
        EditCoupon,
    },

    mixins: [grid],

    data() {
        return {
            coupons: [],

            columns: [
                {
                    label: 'Code',
                    name: 'code',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Descripiton',
                    name: 'description',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Duration',
                    name: 'duration',
                    sortable: true,
                },
                {
                    label: 'Expire',
                    name: 'exp_date',
                    component: 'Date',
                    filter: 'date-range',
                    sortable: true,
                },
                {
                    label: 'Usage Limit',
                    name: 'usage_limit',
                    sortable: true,
                },
                // {
                //     label: 'Plan',
                //     name: 'plan_id',
                //     filter: 'single-select',
                //     options: [
                //         {
                //             id: 'basic',
                //             name: 'Basic'
                //         },
                //         {
                //             id: 'premium',
                //             name: 'Premium'
                //         },
                //     ],
                //     sortable: true,
                // },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            actualColumns: [],

            filters: {},
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            coupons.getCoupons({columns, sort, currentPage, pageLength, filters: filters}).then(response => {
                this.coupons = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        addCoupon() {
            this.$refs.editCoupon.open(0)
        },

        gridAction(params) {
            if (params.action == 'edit') {
                this.$refs.editCoupon.open(params.rowId)
            }
        },
    }
}
</script>

<style scoped>

</style>
