export const defaultColumns = [
    {
        label: 'ID',
        name: 'id',
        filter: 'text',
        sortable: true,
    },
    {
        label: 'Name',
        name: 'name',
        filter: 'text',
        component: 'RawColumn',
        sortable: true,
    },
    {
        label: 'URL domain',
        name: 'url_domain',
        filter: 'text',
        component: 'RawColumn',
        sortable: true,
    },
    {
        label: 'Short Descripiton',
        name: 'short_description',
        filter: 'text',
        sortable: true,
    },
    {
        label: 'Created at',
        name: 'created_at',
        component: 'Date',
        filter: 'date-range',
        sortable: true,
    },
    {
        label: 'Country',
        name: 'country_name',
        filter: 'single-select',
        options: [],
        sortable: true,
    },
    {
        label: 'Startups',
        name: 'startups_count',
        sortable: true,
    },
    {
        label: 'Tracked Startups',
        name: 'tracked_startups_count',
        sortable: true,
    },
    {
        label: 'Rounds',
        name: 'funding_rounds_count',
        sortable: true,
        component: 'Link',
    },
    {
        label: 'Crawl Status',
        name: 'status',
        filter: 'single-select',
        options: [],
        sortable: true,
    },
    {
        label: 'Approve Status',
        name: 'approve_status',
        filter: 'single-select',
        options: [],
        sortable: true,
    },
    {
        label: 'Account Type',
        name: 'account_type',
        filter: 'single-select',
        options: [],
        sortable: true,
    },
    {
        label: 'Last Crawled',
        name: 'last_crawled',
        sortable: true,
    },
    {
        label: 'Rel CB synced',
        name: 'last_cb_relations_sync_date',
        sortable: true,
    },
    {
        label: 'Admin email',
        name: 'admin_email',
        filter: 'text',
    },
    {
        label: 'Enterprise Access for Startups',
        name: 'enterprise_access_for_startups',
    },
    {
        name: 'actions',
        label: 'Actions',
        component: 'Actions',
    }
]
