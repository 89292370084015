export const defaultColumns = [
    {
        label: 'ID',
        name: 'link',
        filter: 'text',
        component: 'RawColumn',
        sortable: true,
    },
    {
        label: 'Name',
        name: 'name',
        filter: 'text',
        sortable: true,
    },
    {
        label: 'URL domain',
        name: 'url_domain',
        filter: 'text',
        component: 'RawColumn',
        sortable: true,
    },
    {
        label: 'Site status',
        name: 'website_status',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: 'Live'
            },
            {
                id: 2,
                name: 'Down'
            }
        ],
        sortable: true,
    },
    {
        label: 'Short Description',
        name: 'short_description',
        filter: 'text',
        sortable: true,
    },
    {
        label: 'Website Keywords',
        name: 'website_keywords',
        filter: 'text',
        sortable: true,
    },
    {
        label: 'Created at',
        name: 'created_at',
        component: 'Date',
        filter: 'date-range',
        sortable: true,
    },
    {
        label: 'Registered at',
        name: 'registered_at',
        component: 'Date',
        filter: 'date-range',
        sortable: true,
    },
    {
        label: 'Enterprise ID',
        name: 'enterprise_id',
        filter: 'text',
        sortable: true,
    },
    {
        label: 'Is public',
        name: 'is_public',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: 'Yes'
            },
            {
                id: 2,
                name: 'No'
            }
        ],
        sortable: true,
    },
    {
        label: 'Is approved',
        name: 'is_approved',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: 'Yes'
            },
            {
                id: 2,
                name: 'No'
            }
        ],
        sortable: true,
    },
    {
        label: 'Hidden',
        name: 'hidden',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: 'Yes'
            },
            {
                id: 2,
                name: 'No'
            }
        ],
        sortable: true,
    },
    {
        label: 'SP Inactive',
        name: 'sp_inactive',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: 'Yes'
            },
            {
                id: 2,
                name: 'No'
            }
        ],
        sortable: true,
    },
    {
        label: 'Crunch status',
        name: 'crunch_status',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: '1'
            },
            {
                id: 2,
                name: '2'
            },
            {
                id: 3,
                name: '3'
            },
            {
                id: -1,
                name: 'Not synced'
            },
        ],
        sortable: true,
    },
    {
        label: 'KG Status',
        name: 'diffbot_kg_status',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: '1'
            },
            {
                id: 2,
                name: '2'
            },
            {
                id: -1,
                name: 'Not synced'
            },
        ],
        sortable: true,
    },
    {
        label: 'Ppx Status',
        name: 'ppx_status',
        filter: 'single-select',
        options: [
            {
                id: 1,
                name: '1'
            },
            {
                id: 2,
                name: '2'
            },
            {
                id: -1,
                name: 'Not synced'
            },
        ],
        sortable: true,
    },
    {
        label: 'Admin email',
        name: 'admin_email',
        filter: 'text',
    },
    {
        label: 'Contacts',
        name: 'contacts',
        component: 'Link'
    },
    {
        name: 'actions',
        label: 'Actions',
        component: 'Actions',
    }
]
