<template>
    <b-modal id="modal-edit-client" title="Edit Client" modal-class="modal-w-md">

        <form class="form">

            <div class="form__item input-checkbox">
                <input id="is_not_company" type="checkbox" v-model="form.is_not_company">
                <label for="is_not_company">Is Not Company</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="hidden" type="checkbox" v-model="form.hidden">
                <label for="hidden">Hidden</label>
            </div>

            <div class="form__item input-checkbox">
                <input id="ignore_duplicates" type="checkbox" v-model="form.ignore_duplicates">
                <label for="ignore_duplicates">Ignore Duplicates</label>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Merged To</h5>

                <multiselect
                    v-model="mergedTo"
                    placeholder="Select Client"
                    :options="clientsList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateClientsList"
                >
                </multiselect>
            </div>

        </form>

        <template #modal-footer>
            <primary-button
                caption="Save"
                loading-caption="Saving..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import clientsService from "@/services/clients.service";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import multiselect from "vue-multiselect";

export default {
    components: {
        multiselect,
        PrimaryButton
    },

    data() {
        return {
            form: {
                is_not_company: 0,
                ignore_duplicates: 0,
                hidden: 0,
            },

            clientsList: [],

            mergedTo: null,

            loading: false,
            id: 0,
        }
    },

    methods: {
        init() {
            this.form.is_not_company = 0;
            this.form.hidden = 0;
            this.form.ignore_duplicates = 0;
            this.mergedTo = null;

            if (this.id) {
                clientsService.getEdit(this.id).then(response => {
                    let {merged_to, ...form} = response.data;
                    this.form = form;
                    this.mergedTo = merged_to;
                })
            }
        },

        open(id) {
            this.id = id;
            this.init()
            this.$bvModal.show('modal-edit-client')
        },

        save() {
            if (this.mergedTo) {
                this.form.merged_to_id = this.mergedTo.id;
            } else {
                this.form.merged_to_id = null;
            }

            clientsService.save(this.id, this.form).then(() => {
                this.$bvModal.hide('modal-edit-client')
                this.$emit('saved');
            })
        },

        updateClientsList(query) {
            clientsService.getListWithRelations(query).then(response => {
                this.clientsList = response.data.filter(item => item.id !== this.id);
            })
        },
    }
}
</script>

<style scoped>

</style>
