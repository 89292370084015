import Startups from "@/views/startups/Startups";
import Merge from "@/views/startups/Merge";
import MergeStartups from "@/views/startups/MergeStartups";
import CrawlingData from "@/views/startups/CrawlingData";
import CrawlingActiveJobs from "@/views/startups/CrawlingActiveJobs";
import MergeBySimilarDomains from "@/views/startups/MergeBySimilarDomains.vue";

export default [
    {
        path: '/startups',
        component: Startups,
        meta: {
            access: ['admin', 'startup_manager'],
        }
    },
    {
        path: '/merge',
        component: Merge,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/merge-by-similar-domains',
        component: MergeBySimilarDomains,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/merge-startups',
        component: MergeStartups,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/startups-crawling',
        component: CrawlingData,
        meta: {
            access: ['admin', 'startup_manager'],
        }
    },
    {
        path: '/crawling-active-jobs',
        component: CrawlingActiveJobs,
        meta: {
            access: ['admin', 'startup_manager'],
        }
    },
]
