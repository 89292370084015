import Users from "@/views/users/Users";

export default [
    {
        path: '/users',
        component: Users,
        meta: {
            access: ['admin'],
        }
    },
]