<template>
    <b-modal id="modal-add-startup" title="Add Startups">
        <div class="input-radio mb-2">
            <input type="radio" v-model="addType" value="singleAdd" id="addonestartup">
            <label for="addonestartup">Add one startup</label>
        </div>
        <div class="input-radio">
            <input type="radio" v-model="addType" value="multipleAdd" id="uploadstartup">
            <label for="uploadstartup">Upload multiple startups with .csv</label>
        </div>

        <template v-if="addType == 'singleAdd'">
            <div class="form mt-3">

                <h5 class="heading5 heading5--secondary">Startup Name</h5>
                <template v-if="typeof errors.startup_name !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.startup_name" :key="index">{{error}}</span>
                </template>
                <b-form-input class="form-control--mid mb-3" v-model="name"></b-form-input>

                <h5 class="heading5 heading5--secondary">Startup URL</h5>
                <template v-if="typeof errors.url !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                </template>
                <b-form-input class="form-control--mid mb-2" v-model="url"></b-form-input>

                <p class="modal-text">Startup URLs are checked against existing startups on SwitchPitch to avoid duplication</p>
            </div>
        </template>

        <template v-else>
            <div class="form">
            <template v-if="typeof errors.file !== 'undefined'">
                <span class="form__error" v-for="(error, index) in errors.file" :key="index">{{error}}</span>
            </template>

            <p v-if="!file" class="modal-text mt-3">Download and use <a :href="baseURL + '/files/add_startup.csv'" target="_blank">this file</a> for best results</p>
                <div class="input-file mb-3">
                    <template v-if="!file">
                        <label for="input-file__upload" class="mr-2">Choose File</label>
                        <input type="file" ref="newFile" id="input-file__upload" accept=".csv" v-on:change="handleFileUpload()">
                    </template>

                    <div v-else class="tags-list tags-list--light">
                        <span class="tags-list__tag mt-3">
                        {{file.name}}
                        <a @click="file = null" class="tags-list__tag__close"></a>
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canAdd" @click="add">
                <span v-if="!loading">Add</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import startups from "@/services/startups.service"
import Vue from 'vue';
import { baseURL } from '@/axios'

export default {
    props: {
        ecosystemId: {
            type: Number,
            default: null,
        }
    },

    data() {
        return {
            addType: "singleAdd",
            name: "",
            url: "",

            errors: {},
            result: "",
            file: null,
            loading: false,
            baseURL
        }
    },

    computed: {
        canAdd() {
            if (this.loading) {
                return false
            }
            else {
                if (this.addType == 'singleAdd') {
                    return this.name.length && this.url.length
                } else {
                    return this.file !== null
                }
            }
        }
    },

    methods: {
        open() {
            this.errors = {}
            this.$bvModal.show('modal-add-startup')
        },

        handleFileUpload() {
            if (this.$refs['newFile'].files.length) {
                this.file = this.$refs['newFile'].files[0]
            }
        },

        add() {
            this.loading = true
            this.errors = {}
            this.result = ""

            if (this.addType == 'singleAdd') {
                let data = {
                    name: this.name,
                    url: this.url,
                    ecosystem_id: this.ecosystemId,
                }

                startups.addSingleStartup(data).then(response => {
                    this.errors = response.data.errors
                    this.result = response.data.result

                    if (! Object.keys(this.errors).length) {
                        this.name = ""
                        this.url = ""

                        this.$bvModal.hide('modal-add-startup');

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data.result
                        })

                        this.$emit('startup-created')
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
            else {
                let formData = new FormData();
                formData.append('file', this.file)
                formData.append('ecosystem_id', this.ecosystemId)

                startups.addMultipleStartups(formData).then(response => {
                    this.loading = false
                    this.errors = response.data.errors

                    if (!Object.keys(this.errors).length) {
                        this.file = null
                        this.$bvModal.hide('modal-add-startup');

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            text: response.data.result
                        })

                        this.$emit('startup-created')
                    }
                }).catch(() => {
                    this.loading = false
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
