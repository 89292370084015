<template>
    <div>
        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :columns="columns"
                    :data="enterprises"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                    v-on:changeFilters="changeFilters"
                    v-on:action="gridAction"
                >
                </Grid>

            </div>
        </div>

        <hours-spent
            ref="hoursSpent"
            v-on:spent-hours-removed="getList">
        </hours-spent>

        <hours-added
            ref="hoursAdded"
            v-on:added-hours-removed="getList">
        </hours-added>

        <add-hours-spent
            ref="addHoursSpent"
            v-on:time-added="getList"
        >
        </add-hours-spent>

        <add-hours
            ref="addHours"
            v-on:hours-added="getList"
        >
        </add-hours>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import analyst from "@/services/analyst.service"
import HoursSpent from "@/components/analyst/HoursSpent.vue"
import HoursAdded from "@/components/analyst/HoursAdded.vue"
import AddHoursSpent from "@/components/analyst/AddHoursSpent.vue"
import AddHours from "@/components/analyst/AddHours.vue"
//import Vue from "vue";

export default {
    components: {
        Grid,
        HoursAdded,
        HoursSpent,
        AddHoursSpent,
        AddHours,
    },

    mixins: [grid],

    data() {
        return {
            enterprises: [],

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Account Type',
                    name: 'account_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'free',
                            name: 'Free'
                        },
                        {
                            id: 'paid',
                            name: 'Premuim'
                        },
                        {
                            id: 'test',
                            name: 'Test'
                        },
                        {
                            id: 'pilot',
                            name: 'Pilot'
                        },
                        {
                            id: 'trial',
                            name: 'Trial'
                        }
                    ],
                    sortable: true,
                },
                {
                    label: 'Analyst Hours',
                    name: 'analyst_hours',
                    component: 'Link',
                    sortable: true,
                },
                {
                    label: 'Analyst Plan',
                    name: 'analyst_plan',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'bronze',
                            name: 'Bronze'
                        },
                        {
                            id: 'silver',
                            name: 'Silver'
                        },
                        {
                            id: 'gold',
                            name: 'Gold'
                        },
                        {
                            id: 'platinum',
                            name: 'Platinum'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'Last purchase',
                    name: 'last_purchase',
                    sortable: true,
                },
                {
                    label: 'Time Spent',
                    name: 'time_spent',
                    component: 'Link',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            filters.view_type = this.viewType

            analyst.getEnterprises({columns, sort, currentPage, pageLength, filters: filters}).then(response => {
                this.enterprises = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        gridAction(params) {
            if (params.action == 'show-hours-added') {
                this.$refs.hoursAdded.open(params.rowId)
            }

            else if (params.action == 'show-hours-spent') {
                this.$refs.hoursSpent.open(params.rowId)
            }

            else if (params.action == 'add-hours-spent') {
                this.$refs.addHoursSpent.open(params.rowId)
            }

            else if (params.action == 'add-hours') {
                this.$refs.addHours.open(this.enterprises.find(item => item.id === params.rowId))
            }
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },
    }
}
</script>

<style scoped>

</style>
