<template>
    <b-modal id="console-commands-logs" hide-footer :title="commandName" modal-class="modal-w-xxl">
        <div class="form__item input-checkbox">
            <input id="not-empty" type="checkbox" v-model="notEmpty">
            <label for="not-empty">Not Empty</label>
        </div>

        <table-wrapper :loading="loading">
            <Grid
                :columns="columns"
                :data="items"
                :sort="sort"
                :page-length="parseInt(pageLength)"
                :total-rows-count="totalRowsCount"
                :currentPage="currentPage"
                v-on:changePage="changePage"
                v-on:changeSort="changeSort"
            />
        </table-wrapper>

        <SingleChart v-if="chartLoaded" :chart-options="chartOptions"/>>
    </b-modal>
</template>

<script>
import TableWrapper from "@/components/TableWrapper.vue";
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import consoleCommandsService from "@/services/console-commands.service";
import SingleChart from "@/components/SingleChart.vue";

export default {
    name: "Details",
    components: {SingleChart, Grid, TableWrapper},
    mixins: [grid],

    data() {
        return {
            commandName: "",
            notEmpty: false,
            items: [],
            columns: [
                {
                    label: 'Date Start',
                    name: 'date_start',
                    sortable: true,
                },
                {
                    label: 'Date End',
                    name: 'date_end',
                    sortable: true,
                },
                {
                    label: 'Date Complete',
                    name: 'date_complete',
                    sortable: true,
                },
                {
                    label: 'Num Processed',
                    name: 'num_processed',
                    sortable: true,
                },
                {
                    label: 'Num Updated',
                    name: 'num_updated',
                    sortable: true,
                },
            ],

            chartOptions: {
                title: {
                    text: this.commandName,
                },

                chart: {
                    type: 'column'
                },

                xAxis: {
                    categories: [],
                },

                plotOptions: {
                    column: {
                        stacking: 'normal',
                    }
                },

                series: [],
            },

            chartLoaded: false,
            loading: false,
        }
    },

    watch: {
        notEmpty() {
            this.getList();
            this.getChartData();
        }
    },

    mounted() {
        this.sort = {
            field: "date_end",
            dir: "desc",
        }
    },

    methods: {
        open(commandName) {
            this.commandName = commandName;
            this.$bvModal.show('console-commands-logs');
            this.getList();
            this.getChartData();
            this.chartLoaded = false;
        },

        getList() {
            this.loading = true;

            let columns = this.columns.map(item => item.name)
            let currentPage = this.currentPage
            let pageLength = this.pageLength

            consoleCommandsService.getList({columns, currentPage, pageLength, sort: this.sort, command_name: this.commandName, not_empty: this.notEmpty}).then(response => {
                this.items = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false;
            })
        },

        getChartData() {
            consoleCommandsService.getStackedChartData(this.commandName).then(response => {
                let [series, categories] = response.data;
                this.chartOptions.series = series;
                this.chartOptions.xAxis.categories = categories;
                this.chartLoaded = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
