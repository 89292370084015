import axios from '@/axios';

const RESOURCE_NAME = '/admin/enterprises';

export default {

    getEnterprises(data) {
        return axios.post(`${RESOURCE_NAME}/get-enterprises`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    getData(id) {
        return axios.get(`${RESOURCE_NAME}/get-data?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    syncCB(id) {
        return axios.post(`${RESOURCE_NAME}/sync-cb?id=${id}`)
    },

    addMultipleEnterprises(data) {
        return axios.post(`${RESOURCE_NAME}/add-multiple-enterprises`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    invite(data) {
        return axios.post(`${RESOURCE_NAME}/invite`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-enterprise?id=${id}`)
    },

    getRelations(id) {
        return axios.get(`${RESOURCE_NAME}/get-relations?id=${id}`)
    },

    convertToStartup(id) {
        return axios.post(`${RESOURCE_NAME}/convert-to-startup?id=${id}`)
    },

    search(search) {
        return axios.get(`${RESOURCE_NAME}/search?search=${search}`);
    },

    searchWithTrackers(search) {
        return axios.get(`${RESOURCE_NAME}/search-with-trackers?search=${search}`);
    },

    getAgencies() {
        return axios.get(`${RESOURCE_NAME}/get-agencies`)
    },

    exportStartupsWithCustomValues(id) {
        return axios.get(`${RESOURCE_NAME}/export-startups-with-custom-values?id=${id}`)
    },

    exportStartupsStages(id) {
        return axios.get(`${RESOURCE_NAME}/export-startups-stages?id=${id}`)
    },
}
