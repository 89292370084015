<template>
    <b-modal id="modal-gv-details-form" modal-class="modal-w-xxxl" title="GV details" hide-footer>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table" style="position: relative">
                <Grid
                    :columns="columns"
                    :data="items"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                />
            </div>
        </div>
    </b-modal>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";

export default {
    components: {
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            columns: [
                {
                    label: 'Image',
                    name: 'img',
                    component: 'ImageColumn',
                },
                {
                    label: 'Image URL',
                    name: 'img_url',
                },
                {
                    label: 'Name',
                    name: 'name',
                }
            ],

            allItems: [],
        }
    },

    methods: {
        open(items) {
            this.allItems = items;
            this.currentPage = 1;
            this.totalRowsCount = this.allItems.length;
            this.$bvModal.show('modal-gv-details-form');
            this.getList();
        },

        getList() {
            this.items = this.allItems.slice((this.currentPage - 1) * this.pageLength, this.currentPage * this.pageLength);
        },
    }
}
</script>

<style scoped>

</style>
