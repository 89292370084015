import axios from '@/axios';

const RESOURCE_NAME = '/admin/clients';

export default {
    getClients(data) {
        return axios.post(`${RESOURCE_NAME}/get-clients`, data)
    },

    getList(query) {
        return axios.get(`${RESOURCE_NAME}/get-list?query=${query}`)
    },

    getListWithRelations(query) {
        return axios.get(`${RESOURCE_NAME}/get-list?query=${query}&with_relations=1`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, {data})
    },

    getRelatedStartups(id, type, data) {
        return axios.post(`${RESOURCE_NAME}/get-related-startups?id=${id}&type=${type}`, data)
    },

    refreshRelation(id) {
        return axios.post(`${RESOURCE_NAME}/refresh-relation?id=${id}`)
    }
}
