import axios from '@/axios';

const RESOURCE_NAME = '/admin/blacklisted-clients';

export default {

    getClients(data) {
        return axios.post(`${RESOURCE_NAME}/get-clients`, data)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, {data})
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-client?id=${id}`)
    },
}
