<template>
    <div>
        <main class="main main--dark main--dashboard">
            <div class="content">
                <table class="table">
                    <thead>
                    <tr>
                        <td>Command</td>
                        <td>Date Start</td>
                        <td>Date End</td>
                        <td>Date Complete</td>
                        <td>Num Processed</td>
                        <td>Num Updated</td>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="command in commands" :key="command.name" :class="{'alert-danger': command.date_end && !command.date_complete}">
                        <td><a class="link" @click="openDetails(command.name)">{{command.name}}</a></td>
                        <td>{{command.date_start}}</td>
                        <td>{{command.date_end}}</td>
                        <td>{{command.date_complete}}</td>
                        <td>{{command.num_processed}}</td>
                        <td>{{command.num_updated}}</td>
                    </tr>
                    </tbody>
                </table>

                <DatePicker v-model="period" range/>

                <ConsoleCommandChart
                    v-for="(commands, name) in chartGroups" :key="name"
                    :commands="commands"
                    :group-name="name"
                    :period="period"
                />
            </div>
        </main>

        <Details ref="details"/>
    </div>
</template>

<script>
import consoleCommandsService from "@/services/console-commands.service";
import ConsoleCommandChart from "@/components/consoleCommands/ConsoleCommandChart.vue";
import Details from "@/components/consoleCommands/Details.vue";
import DatePicker from "vue2-datepicker";

export default {
    name: "ConsoleCommandsDashboard",
    components: {Details, ConsoleCommandChart, DatePicker},
    data() {
        return {
            commands: [],
            chartGroups: {
                'Resync Startups': ['crunchbase/sync-all-not-synced', 'diffbot/sync-all-not-synced', 'crunchbase/re-sync-old', 'crunchbase/sync-not-found-startups','crunchbase/sync-trackers'],
                'New Startups': ['crunchbase/get-new-startups', 'crunchbase/sync-ecosystem-startups-new'],
                'Sync Ecosystems': ['crunchbase/update-new-ecosystems', 'crunchbase/update-all-ecosystems'],
                'Crawling': ['diffbot/crawl-keywords', 'diffbot/get-crawl-results', 'ecosystem-crawl/sync-ecosystems'],
                'Notifications': ['notifications/now', 'notifications/day', 'notifications/week'],
                'Import': ['import/import-clients', 'import/import-clients-from-ai', 'import/import-features-from-ai', 'import/import-videos-from-ai'],
                'Email/Calendar': ['emails/check-email', 'emails/check-calendar'],
                'Tools': ['auto-scout/run', 'clients/merge-clients', 'startups/calculate-irl-score', 'startups/notify-no-website-startups', 'startups/populate-founder', 'tools/notify-about-pending-ecosystems', 'tools/send-confidential-items-report'],
            },

            period: [this.dateHelper.addMonth(new Date(), -1), new Date()],
        }
    },

    mounted() {
        this.getSummary();
    },

    methods: {
        getSummary() {
            consoleCommandsService.getSummary().then(response => {
                this.commands = response.data
            })
        },

        openDetails(commandName) {
            this.$refs.details.open(commandName)
        }
    }
}
</script>

<style scoped>

</style>
