import axios from '@/axios';

const RESOURCE_NAME = '/admin/crawled-data';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getResponseJson(id) {
        return axios.get(`${RESOURCE_NAME}/get-response-json?id=${id}`)
    },

    getDetails(id, data) {
        return axios.post(`${RESOURCE_NAME}/get-details?id=${id}`, data)
    },
}
