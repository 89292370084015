<template>
    <b-modal id="modal-details-form" modal-class="modal-w-xxxl" title="Details" hide-footer>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table" style="position: relative">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"/>

                <Grid
                    :columns="columns"
                    :data="items"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                />
            </div>
        </div>
    </b-modal>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import PulseLoader from "vue-spinner/src/MoonLoader.vue";
import crawledDataService from "@/services/crawled-data.service";

export default {
    components: {
        Grid,
        PulseLoader,
    },

    mixins: [grid],

    data() {
        return {
            id: 0,
            getListMethod: crawledDataService.getList,

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Label',
                    name: 'label',
                    filter: 'single-select',
                    options: [
                        {
                            id: "client",
                            name: 'Client'
                        },
                        {
                            id: "partner",
                            name: 'Partner'
                        },
                        {
                            id: "unknown",
                            name: 'Unknown'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'Source',
                    name: 'source',
                    filter: 'text',
                    sortable: true,
                    component: 'RawColumn'
                },
                {
                    label: 'Type',
                    name: 'discovery_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: "text",
                            name: 'Text'
                        },
                        {
                            id: "gv",
                            name: 'GV'
                        },
                    ],
                    sortable: true,
                },
                {
                    label: 'Created At',
                    name: 'created_at',
                    sortable: true,
                },
            ],
        }
    },

    methods: {
        open(id) {
            this.items = [];
            this.id = id;
            this.$bvModal.show('modal-details-form');
            this.filters = {};
            this.getList()
        },

        getList() {
            let data = this.getRequestParams();

            this.loading = true
            this.totalRowsCount = 0

            crawledDataService.getDetails(this.id, data).then(response => {
                this.items = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>

<style scoped>

</style>
