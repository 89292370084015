<template>
    <div>

        <div class="topbar pl-sm-5">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header class="topbar__key__header form-control-wrapper flex-wrap pl-0">
                        <input class="form-control form-control--big w-100 w-sm-auto mv-50 mr-sm-3 mb-3 mb-sm-0" v-model="newKeyword" @keyup.enter="addKeyword">
                        <button class="button w-100 w-sm-auto" @click="addKeyword">Add keyword</button>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="keywords"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of keywords: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import keywordsWhitelist from "@/services/keywords-whitelist.service"

export default {
    components: {
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            newKeyword: "",

            keywords: [],
            selectedIds: [],

            columns: [
                {
                    label: 'Keyword',
                    name: 'keyword',
                    filter: 'text',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)

            keywordsWhitelist.getKeywords({columns, currentPage, pageLength, filters}).then(response => {
                this.keywords = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action === 'delete') {
                this.deleteKeyword(params.rowId)
            }
        },

        deleteKeyword(id) {
            if (confirm("Delete keyword?")) {
                keywordsWhitelist.delete(id).then(() => {
                    this.getList()
                })
            }
        },

        addKeyword() {
            if (this.newKeyword.length) {
                keywordsWhitelist.add({keyword: this.newKeyword}).then(() => {
                    this.newKeyword = ""
                    this.getList()
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
