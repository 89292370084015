<template>
    <div>
        <div class="topbar">
            <div class="tabs tabs-primary">
                <b-nav tabs>
                    <b-nav-item @click="viewType = 'startups'" :active="viewType === 'startups'">
                        Startups
                    </b-nav-item>

                    <b-nav-item @click="viewType = 'startup-accounts'" :active="viewType === 'startup-accounts'">
                        Startup Accounts
                    </b-nav-item>
                </b-nav>
            </div>
            <br>
        </div>

        <StartupsIndex
            :view-type="viewType"
        />
    </div>
</template>

<script>
import StartupsIndex from "@/components/startups/StartupsIndex.vue";

export default {
    components: {
        StartupsIndex,
    },

    data() {
        return {
            viewType: 'startups',
        }
    },
}
</script>

<style scoped>

</style>
