import axios from '@/axios';

const RESOURCE_NAME = '/admin/files';

export default {

    downloadAttachment(id) {
        return axios.get(`${RESOURCE_NAME}/download-attachment?id=${id}`, {
            responseType: 'blob',
        })
    },
}
