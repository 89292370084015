export default {
    methods: {
        debounce (callback, timer = 500) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer)
            }
            this.$_debounceTimer = setTimeout(() => {
                callback()
            }, timer)
        }
    },
    created () {
        this.$_debounceTimer = null
    }
}
