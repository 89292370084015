<template>
    <div>
        <div class="topbar">
            <div class="input-checkbox pt-1">
                <input id="filterWithRelations" class="ml-2" type="checkbox" v-model="filterWithRelations">
                <label for="filterWithRelations">With Relations</label>
            </div>
        </div>

        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"></pulse-loader>

                <Grid
                    :columns="columns"
                    :data="startups"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :addCellData="addStartupData"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import mergeStartups from "@/services/merge-startups.service"
import constants from "../../../../frontend/src/constants/constants";
import PulseLoader from "vue-spinner/src/MoonLoader.vue";

export default {
    computed: {
        constants() {
            return constants
        }
    },
    components: {
        Grid,
        PulseLoader
    },

    mixins: [grid],

    data() {
        return {
            startups: [],
            mergeIds: ['', ''],
            filterWithRelations: false,

            columns: [
                {
                    label: 'Domain',
                    name: 'domain',
                    sortable: true,
                },
                {
                    label: 'Startup IDs',
                    name: 'ids',
                },
                {
                    label: 'Startup Domains',
                    name: 'domains',
                },
                {
                    label: 'Count',
                    sortable: true,
                    name: 'cnt',
                },
                {
                    name: 'actions',
                    label: '',
                    component: 'Actions',
                }
            ],

            addStartupData: {},
            filters: {},
        }
    },

    watch: {
        filterWithRelations() {
            this.getList()
        },
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            this.loading = true;
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort
            let filters = {
                with_relations: this.filterWithRelations
            };

            mergeStartups.getStartupsWithSimilarDomains({columns, sort, currentPage, pageLength, filters}).then(response => {
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false;
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action === 'merge') {
                let mergeIds = this.startups[params.rowId].ids.split(',');
                window.open('/merge-startups?id=' + mergeIds.join('&id=') + '&redirectTo=merge-by-similar-domains', '_blank').focus();
            }
        },
    }
}
</script>

<style scoped>

</style>
