<template>
    <div>

        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :columns="columns"
                    :data="categories"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>

        <b-modal id="modal-map-categories" :hide-footer="true" modal-class="modal-w-md" title="Map Categories">
            <div class="form pb-5">

                <div class="form__item">
                    <multiselect
                        v-model="mappedCategories"
                        :multiple="true"
                        placeholder="Select Categories"
                        :options="cbCategories"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                    >
                    </multiselect>
                </div>

                <div class="text-center">
                    <b-button variant="primary" size="lg" @click="save">Save</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import diffbot from "@/services/diffbot.service"
import categories from "@/services/categories.service"
import multiselect from "vue-multiselect";

export default {
    components: {
        Grid,
        multiselect
    },

    mixins: [grid],

    data() {
        return {
            categories: [],
            currentId: 0,
            mappedCategories: [],
            cbCategories: [],

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                    filter: 'text',
                },
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                },
                {
                    label: 'CB Categories',
                    name: 'cb_categories',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            filters: {},
        }
    },

    mounted() {
        this.getList()

        categories.getList().then(response => {
            this.cbCategories = response.data
        })
    },

    methods: {
        getList() {
            let columns = this.columns.map(item => item.name)
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)

            diffbot.getCategories({columns, currentPage, pageLength, filters}).then(response => {
                this.categories = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action == 'map-categories') {
                this.currentId = params.rowId

                diffbot.getMappedCategories(this.currentId).then(response => {
                    this.mappedCategories = response.data
                    this.$bvModal.show('modal-map-categories')
                })
            }
        },

        save() {
            let mappedCategories = this.mappedCategories.map(item => item.value)

            diffbot.saveMappedCategories(this.currentId, mappedCategories).then(() => {
                this.$bvModal.hide('modal-map-categories')
                this.getList()
            })
        },
    }
}
</script>

<style scoped>

</style>
