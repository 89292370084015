import EcosystemConnectionRequests from "@/views/tools/EcosystemConnectionRequests";
import EnterpriseConnectionRequests from "@/views/tools/EnterpriseConnectionRequests";
import DiffbotCategories from "@/views/tools/DiffbotCategories";
import ClientsDiscovery from "@/views/tools/ClientsDiscovery";
import Clients from "@/views/tools/Clients";
import BlacklistedClients from "@/views/tools/BlacklistedClients";
import ClientsBlacklistedKeywords from "@/views/tools/BlacklistedClientsWildcard.vue";
import HelperItems from "@/views/tools/HelperItems";
import ReleaseNotes from "@/views/tools/ReleaseNotes.vue";
import UsersSearches from "@/views/tools/UsersSearches.vue";

export default [
    {
        path: '/invite-ecosystem-requests',
        component: EcosystemConnectionRequests,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/invite-enterprise-requests',
        component: EnterpriseConnectionRequests,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/diffbot-categories',
        component: DiffbotCategories,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/clients-discovery',
        component: ClientsDiscovery,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/blacklisted-clients',
        component: BlacklistedClients,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/blacklisted-clients-wildcard',
        component: ClientsBlacklistedKeywords,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/clients',
        component: Clients,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/helper-items',
        component: HelperItems,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/release-notes',
        component: ReleaseNotes,
        meta: {
            access: ['admin'],
        }
    },
    {
        path: '/users-search-history',
        component: UsersSearches,
        meta: {
            access: ['admin'],
        }
    },
]
