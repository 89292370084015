<template>
    <b-modal id="modal-enterprise-relations" :hide-footer="true" modal-class="modal-w-md" title="Enterprise Relations">
        <div class="pb-3">

            <div v-for="(relationType, index) in relations" :key="index">
                <h5 class="heading5 heading5--secondary">{{relationType.name}}: {{relationType.count}}</h5>
            </div>

            <div class="text-center" v-if="loaded">
                <b-button variant="danger" class="mt-3 w-100 w-sm-auto" size="lg" @click="deleteEnteprise">Delete Enterprise</b-button>

                <b-button v-if="!hasStartupAccount" variant="primary" class="mt-3 ml-sm-2 w-100 w-sm-auto" size="lg" @click="convert">Convert to Startup</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import enterprises from "@/services/enterprises.service"

export default {
    data() {
        return {
            id: 0,
            hasStartupAccount: false,
            relations: [],
            loaded: false,
        }
    },

    methods: {
        init() {
            this.relations = []
            this.hasStartupAccount = true

            enterprises.getRelations(this.id).then(response => {
                this.relations = response.data.relations
                this.hasStartupAccount = response.data.has_startup_account
                this.loaded = true
            })
        },

        open(id) {
            this.id = id
            this.loaded = false
            this.init()
            this.$bvModal.show('modal-enterprise-relations')
        },

        close() {
            this.$bvModal.hide('modal-enterprise-relations')
        },

        deleteEnteprise() {
            this.$emit('delete-enterprise', this.id)
            this.$bvModal.hide('modal-enterprise-relations')
        },

        convert() {
            this.$emit('convert', this.id)
            this.$bvModal.hide('modal-enterprise-relations')
        }
    }
}
</script>

<style scoped>

</style>