<template>
    <b-modal title="Edit Links" :id="'edit-links-'+index" size="md">
        <div v-for="(link, index) in links" :key="index" class="form form-modal links-section">
            <b-row class="mb-2">
                <b-col cols="6">
                    <b-form-input @input="forceShow = true" class="form-control--mid mb-1 " :disabled="!link.user_can_delete && link.id > 0" v-model="links[index].title" placeholder="Title*"></b-form-input>
                </b-col>
                <b-col cols="6" class="d-flex align-items-center">
                    <b-form-input @input="forceShow = true" class="form-control--mid mb-1" :disabled="!link.user_can_delete && link.id > 0" v-model="links[index].url" placeholder="URL*"></b-form-input>
                    <a v-if="link.user_can_delete" @click="links.splice(index, 1)" class="link-delete ml-2"></a>
                </b-col>
            </b-row>
        </div>

        <a class="link" @click="links.push({id: 0, title: '', url: ''})">+Add <template v-if="links.length">Another </template>Link</a>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save" :disabled="!canSave">Save</b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        index: String
    },

    data() {
        return {
            links: []
        }
    },

    computed: {
        canSave() {
            let hasEmpty = false

            this.links.forEach(link => {
                if (! (link.title.length && link.url.length)) {
                    hasEmpty = true
                    return
                }
            })

            return !hasEmpty
        }
    },

    methods: {
        open(links) {
            this.links = this.arrayHelper.cloneArray(links)
            this.$bvModal.show('edit-links-'+this.index)
        },

        save() {
            this.$bvModal.hide('edit-links-'+this.index)
            this.$emit('saved', this.links)
        }
    }
}
</script>

<style scoped>

</style>
