<template>
    <b-modal id="funding-rounds-modal" modal-class="funding-rounds-modal modal-w-xl" title="Funding Rounds" @hide="onCloseModal" hide-footer>
        <div>
            <h5 class="heading5 heading5--secondary">Funding Type</h5>

            <multiselect
                v-model="filters.type"
                :multiple="true"
                placeholder="Select Funding Rounds"
                :options="typeOptions"
                select-label=""
                deselect-label=""
                class="multiselect-mid pl-2 mb-3"
            />
        </div>

        <div v-if="loading" class="loading__spinner">
            <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
        </div>
            
        <div class="main-table">
            <table>
                <thead>
                    <tr>
                        <th>
                            Funding Type
                        </th>
                        <th>
                            Series
                        </th>
                        <th>
                            Announced on
                        </th>
                        <th>
                            Raised
                        </th>
                        <th>
                            Startup Name
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="round in rounds" :key="round.id" class="pr-4">
                        <td>
                            {{round.funding_type}}
                        </td>
                        <td>
                            {{round.series}}
                        </td>
                        <td>
                            {{round.announced_on}}
                        </td>
                        <td>
                            {{round.raised}}
                        </td>
                        <td>
                            <a v-if="round.startup_link" :href="round.startup_link" target="_blank">{{round.startup_name}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <pager v-if="rounds.length" :pages-count="pagesCount" :init-page="currentPage" @changePage="changePage"/>
    </b-modal>
</template>

<script>

    import ecosystems from '@/services/ecosystems.service';
    import multiselect from "vue-multiselect";

    export default {
        components: {
            multiselect,
            Pager: () => import("@/components/TablePager"),
        },
        data() {
            return {
                ecosystemId: 0,
                loading: false,
                rounds: [],
                currentPage: 1,
                pageLength: 10,
                totalCount: 0,
                filters: {
                    type: [],
                },
            }
        },
        watch: {
            filters: {
                deep: true,
                handler() {
                    this.getRounds();
                }
            }
        },
        computed: {
            pagesCount() {
                return  Math.ceil(this.totalCount / this.pageLength)
            },

            typeOptions() {
                let typeOptions = [];

                for (let i in this.constants.fundingTypesForEdit) {
                    typeOptions.push(i);
                }

                return typeOptions;
            },
        },
        methods: {
            open(ecosystemId) {
                this.rounds = []
                this.ecosystemId = ecosystemId;
                this.getRounds();
                this.$bvModal.show('funding-rounds-modal');
            },

            getRounds() {
                this.loading = true

                ecosystems.getFundingRounds(
                    this.ecosystemId,
                    this.filters,
                    this.currentPage,
                    this.pageLength,
                ).then(response => {
                    this.loading = false;
                    this.rounds = response.data.data;
                    this.totalCount = parseInt(response.data.total_count)
                    this.currentPage = parseInt(response.data.current_page)
                })
            },

            changePage(page) {
                this.currentPage = page;
                this.getRounds();
            },

            onCloseModal() {
                this.rounds = [];
                this.currentPage = 1;
                this.totalCount = 0;
            },
        }
    }
</script>
