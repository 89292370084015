import axios from '@/axios';

const RESOURCE_NAME = '/admin/diffbot';

export default {

    getCategories(data) {
        return axios.post(`${RESOURCE_NAME}/get-categories`, data)
    },

    getMappedCategories(categoryId) {
        return axios.get(`${RESOURCE_NAME}/get-mapped-categories?category_id=${categoryId}`)
    },

    saveMappedCategories(categoryId, mappedCategories) {
        return axios.post(`${RESOURCE_NAME}/save-mapped-categories?category_id=${categoryId}`, {mappedCategories: mappedCategories})
    },
}
