import Enterprises from "@/views/enterprises/Enterprises";

export default [
    {
        path: '/enterprises',
        component: Enterprises,
        meta: {
            access: ['admin'],
        }
    },
]