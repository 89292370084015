import axios from '@/axios';

const RESOURCE_NAME = '/admin/connection-requests';

export default {

    getRequests(data) {
        return axios.post(`${RESOURCE_NAME}/get-requests`, data)
    },

    getRequest(id) {
        return axios.get(`${RESOURCE_NAME}/get-request?id=${id}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-request?id=${id}`)
    },

    setProcessed(id) {
        return axios.post(`${RESOURCE_NAME}/set-processed?id=${id}`)
    },

    setRejected(id) {
        return axios.post(`${RESOURCE_NAME}/set-rejected?id=${id}`)
    },

}
