import axios from '@/axios';

const RESOURCE_NAME = '/admin/user';

export default {

    getCurrentUserData() {
        return axios.get(`${RESOURCE_NAME}/get-current-user-data`)
    },
}
