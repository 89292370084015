<template>
    <StartupsIndex
        view-type="ecosystem"
        :ecosystem-id="id"
    />
</template>

<script>
import StartupsIndex from "@/components/startups/StartupsIndex.vue";

export default {
    components: {
        StartupsIndex,
    },

    data() {
        return {
            id: parseInt(this.$route.params.id),
        }
    },
}
</script>

<style scoped>

</style>
