import axios from '@/axios';

const RESOURCE_NAME = '/public-files';

export default {

    downloadCustomFile(id) {
        return axios.get(`${RESOURCE_NAME}/download-custom-file?id=${id}`, {
            responseType: 'blob',
        })
    },
}
