export default {
    startupLogo: 'default_startup_logo.svg',

    editorOptions: {
        menubar: false,
        plugins: ['lists','link'],
        statusbar: false,
        toolbar:
            'bold italic | alignleft aligncenter alignright | bullist numlist | link | removeformat',
        link_assume_external_targets: 'http'
    },

    editorOptionsEmpty: {
        menubar: false,
        toolbar: 'bold italic underline',
        statusbar: false,
    },
};
