import axios from '@/axios';

const RESOURCE_NAME = '/admin/blacklisted-clients-wildcard';

export default {

    getKeywords(data) {
        return axios.post(`${RESOURCE_NAME}/get-keywords`, data)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, {data})
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-keyword?id=${id}`)
    },
}
