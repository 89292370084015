import axios from '@/axios';

const RESOURCE_NAME = '/locations';

export default {

    getCountriesList() {
        return axios.get(`${RESOURCE_NAME}/get-countries-list`)
    },

    getStatesList() {
        return axios.get(`${RESOURCE_NAME}/get-states-list`)
    },
}
