<template>
    <div>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="trackers"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of shared trackers: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>

        <b-modal id="show-users" title="Users" hide-footer>
            <ul class="list-dotted">
                <li v-for="(user, index) in users" :key="index">
                    <span>{{user.name}} <template v-if="user.role == 'Manager'">(Manager)</template></span>
                    <a class="link ml-2" @click="impersonate(user.id)">Impersonate & open tracker</a>
                </li>
            </ul>
            <div class="mb-5">
                
            </div>
        </b-modal>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import trackers from "@/services/trackers.service"
import users from "@/services/users.service";

export default {
    components: {
        Grid,
    },

    mixins: [grid],

    data() {
        return {
            trackers: [],

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                    filter: 'text',
                },
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                },
                {
                    label: 'Description',
                    name: 'description',
                    filter: 'text',
                },
                {
                    label: 'Share Type',
                    name: 'access_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'all',
                            name: 'All'
                        },
                        {
                            id: 'categories',
                            name: 'Categories'
                        },
                        {
                            id: 'connections',
                            name: 'Connections'
                        },
                        {
                            id: 'custom',
                            name: 'Custom'
                        }
                    ],
                },
                {
                    label: 'Enterprise',
                    name: 'enterprise_name',
                    filter: 'text',
                },
                {
                    label: 'Ecosystems',
                    name: 'ecosystems_names',
                    filter: 'text',
                },
                {
                    label: 'Categories',
                    name: 'categories_names',
                    filter: 'text',
                },
                {
                    label: 'Startups',
                    name: 'startups_count',
                },
                {
                    label: 'Created at',
                    name: 'created_at',
                    component: 'Date',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            sort: {},

            filters: {},
            users: [],
            currentTrackerId: 0,
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)

            trackers.getSharedTrackers({columns, currentPage, pageLength, filters: filters}).then(response => {
                this.trackers = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action == 'users') {

                this.currentTrackerId = params.rowId
                trackers.getUsers(this.currentTrackerId).then(response => {
                    this.users = response.data
                    this.$bvModal.show('show-users')
                })
            }
        },

        impersonate(id) {
            users.getAdminToken(id).then(response => {
                let token = response.data
                let link = this.mainDomain + '/impersonate/' + this.$store.state.token + '/' + token + '?tracker_id=' + this.currentTrackerId
                window.open(link, '_blank')
            })
        }
    }
}
</script>

<style scoped>

</style>
