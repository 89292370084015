<template>
    <b-modal id="modal-edit-keyword" title="Edit keyword" modal-class="modal-w-md">

        <form class="form">

            <div class="form__item">
                <h6 class="heading6 mb-1">Keyword</h6>
                <b-form-input type="text" class="form-control--mid mb-3" v-model="form.keyword"></b-form-input>
            </div>

            <div class="form__item input-checkbox">
                <input id="wildcard" type="checkbox" v-model="form.wildcard">
                <label for="wildcard">Wildcard</label>
            </div>

        </form>

        <template #modal-footer>
            <primary-button
                :disabled="!canSave"
                caption="Save"
                loading-caption="Saving..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import keywordsBlacklistService from "@/services/keywords-blacklist.service";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";

export default {
    components: {
        PrimaryButton
    },

    data() {
        return {
            form: {
                keyword: "",
                wildcard: 0,
            },

            loading: false,
            id: 0,
        }
    },

    computed: {
        canSave() {
            return this.form.keyword.length;
        }
    },

    methods: {
        init() {
            this.form.keyword = "";
            this.form.wildcard = 0;

            if (this.id) {
                keywordsBlacklistService.getEdit(this.id).then(response => {
                    this.form.keyword = response.data.keyword
                    this.form.wildcard = response.data.wildcard
                })
            }
        },

        open(id) {
            this.id = id;
            this.init()
            this.$bvModal.show('modal-edit-keyword')
        },

        save() {
            keywordsBlacklistService.save(this.id, this.form).then(() => {
                this.$bvModal.hide('modal-edit-keyword')
                this.$emit('saved');
            })
        },
    }
}
</script>

<style scoped>

</style>
