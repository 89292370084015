<template>
    <b-modal id="modal-hours-added" :hide-footer="true" modal-class="modal-w-md" :title="'Analyst hours for ' + enterpriseName">
        <div class="pb-3">

            <div v-for="(item, index) in items" :key="index">
                {{item.plan}}
                {{item.hours}}
                {{item.created_at}}

                <a @click="remove(item.id)" class="link">Remove</a>
            </div>
        </div>
    </b-modal>
</template>

<script>
import analyst from "@/services/analyst.service"

export default {
    data() {
        return {
            enterpriseId: 0,
            enterpriseName: "",
            items: [],
        }
    },

    methods: {
        init() {
            this.items = []
            this.getHoursAdded()
        },

        getHoursAdded() {
            analyst.getHoursAdded(this.enterpriseId).then(response => {
                this.items = response.data.items
                this.enterpriseName = response.data.enterprise_name
            })
        },

        open(id) {
            this.enterpriseId = id
            this.init()
            this.$bvModal.show('modal-hours-added')
        },

        close() {
            this.$bvModal.hide('modal-hours-added')
        },

        remove(id) {
            if (confirm("Remove record?")) {
                analyst.removeHoursAdded(id).then(() => {
                    this.getHoursAdded()
                    this.$emit('added-hours-removed')
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
