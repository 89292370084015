<template>
    <div class="topbar">
        <div class="tabs tabs-primary">
            <b-nav tabs>
                <b-nav-item to="/blacklisted-clients" :active="viewType === 'exact'">
                    Exact Match
                </b-nav-item>

                <b-nav-item to="/blacklisted-clients-wildcard" @click="viewType = 'wildcard'">
                    Wildcard
                </b-nav-item>
            </b-nav>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        viewType: String
    }
}
</script>

<style scoped>

</style>
