<template>
    <form class="form pb-5">
        <p class="modal-text d-flex">
            <a v-if="fromStartup" @click="$emit('back')" class="link-back-black mb-2"></a>
            Edit Funding Rounds
        </p>

        <div v-for="(round, index) in rounds" class="overview-item" :key="index">
            <header class="overview-item__header align-items-center">
                <div v-if="round.from_cb" class="overview-item__name">{{rounds[index].decoded_funding_type}}</div>
                <select v-else v-model="rounds[index].decoded_funding_type" class="selectbox" :disabled="round.from_cb">
                    <option value="">Select Round</option>
                    <option v-for="(label, value) in constants.fundingTypesForEdit" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
                
                <div v-if="round.from_cb" class="overview-item__synced">Synced from Crunchbase</div>
                
                <div class="ml-auto">
                    <a @click="rounds.splice(index, 1)" v-if="!round.from_cb" class="link-delete-red">Remove</a>
    
                    <div v-else class="input-checkbox">
                        <input :id="'round-' + index" type="checkbox" v-model="rounds[index].hidden_by_user">
                        <label :for="'round-' + index">
                            Hidden
                        </label>
                    </div>
                </div>
            </header>
            
            <div class="overview-item__content">
                <div>
                    <span class="form__error" v-if="rounds[index].errors.length">{{rounds[index].errors.join(', ')}}</span>
                </div>
    
                <div class="col-6">
                    <!--<h5 class="heading5 heading5--secondary">Funding Type <template v-if="!round.from_cb">*</template></h5>-->
                    <!--<div v-if="round.from_cb">-->
                    <!--    {{rounds[index].decoded_funding_type}}-->
                    <!--</div>-->
                    <!---->
                    <!--<select v-else v-model="rounds[index].decoded_funding_type" class="multiselect__input selectbox selectbox-mid" :disabled="round.from_cb">-->
                    <!--    <option value=""></option>-->
                    <!--    <option v-for="(label, value) in constants.fundingTypesForEdit" :key="value" :value="value">-->
                    <!--        {{label}}-->
                    <!--    </option>-->
                    <!--</select>-->
                </div>
                
                <div class="row">
                    <div class="col-12 col-sm-6 mb-3">
                        <h5 class="heading5">Announced on <template v-if="!round.from_cb">*</template></h5>
                        <date-picker class="w-100" format = "MM/DD/YYYY" v-model="rounds[index].announceDate" :disabled="round.from_cb"></date-picker>
                    </div>
        
                    <div class="col-12 col-sm-6 mb-3">
                        <h5 class="heading5 heading5--secondary">Raised</h5>
                        <input class="form-control--mid form-control" type="number" v-model="rounds[index].raised" :disabled="round.from_cb">
                    </div>
        
                </div>
                <div class="">
                    <h5 class="heading5 heading5--secondary">Investors</h5>
    
                    <multiselect
                        class="multiselect--complex multiselect-mid"
                        v-model="rounds[index].investorsArray"
                        :multiple="true"
                        :options="investorsList"
                        select-label=""
                        :disabled="round.from_cb"
                        deselect-label=""
                        label="name"
                        track-by="name"
                        placeholder="Start typing..."
                        @search-change="getInvestors"
                    >
                    </multiselect>

                </div>
            </div>
        </div>

        <div class="mb-3">
            <a class="link" @click="addRound">Add Round</a>
        </div>

        <div class="text-center">
            <b-button variant="primary" size="lg" @click="save">Save</b-button>
        </div>
    </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import multiselect from "vue-multiselect";

export default {
    props: {
        id: Number,
        fromStartup: Boolean,
        startupsService: Object,
        investorsService: Object,
    },

    components: {
        DatePicker,
        multiselect
    },

    data() {
        return {
            rounds: [],

            investorsList: [],
        }
    },

    methods: {
        init() {
            this.rounds = []

            this.startupsService.getFundingRounds(this.id).then(response => {
                response.data.forEach(item => {
                    if (!(item.from_cb || item.decoded_funding_type in this.constants.fundingTypesForEdit)) {
                        item.decoded_funding_type = null;
                    }

                    item.announceDate = this.dateHelper.decodeDateFromServer(item.announced_on)

                    item.investorsArray = []
                    item.investors.forEach(investor => {
                        item.investorsArray.push({
                            name: investor.name,
                            id: investor.id,
                        })
                    })
                    item.errors = []

                    this.rounds.push(item)
                })
            })
        },

        getInvestors(search) {
            if (search.length) {
                this.investorsService.getInvestorsList(search).then(response => {
                    this.investorsList = response.data
                })
            }
            else {
                this.investorsList = []
            }
        },

        addRound() {
            this.rounds.push(
                {
                    id: 0,
                    decoded_funding_type: '',
                    announceDate: null,
                    raised: '',
                    from_cb: false,
                    investorsArray: [],
                    errors: [],
                }
            )
        },

        save() {
            let hasErrors = false
            let rounds = this.rounds.filter(item => !item.from_cb);
            
            for (let i in rounds) {
                if (!rounds[i].from_cb) {
                    rounds[i].errors = []
                    rounds[i].investors = rounds[i].investorsArray.map(item => item.id);

                    if (!rounds[i].decoded_funding_type) {
                        rounds[i].errors.push('Funding Type not filled')
                        hasErrors = true
                    }

                    if (rounds[i].announceDate instanceof Date) {
                        rounds[i].announced_on = this.dateHelper.encodeDateForServer(rounds[i].announceDate)
                    } else {
                        rounds[i].errors.push('Announced Date not filled')
                        hasErrors = true
                    }
                }
            }

            if (!hasErrors) {
                this.startupsService.saveFundingRounds(this.id, {rounds}).then((response) => {
                    this.$emit('saved', response.data);
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
