<template>
    <div>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="items"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                    v-on:changeFilters="changeFilters"
                    v-on:action="gridAction"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of clients: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>

        <edit-client
            ref="editClientForm"
            @saved="getList"
        />

        <startups
            ref="startups"
        />

        <duplicates
            ref="duplicates"
        />
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import clientsService from "@/services/clients.service"
import EditClient from "@/components/clients/EditClient";
import Startups from "@/components/clients/Startups";
import Duplicates from "@/components/clients/Duplicates.vue";

export default {
    components: {
        Duplicates,
        EditClient,
        Grid,
        Startups
    },

    mixins: [grid],

    data() {
        return {
            newClient: "",

            columns: [
                {
                    label: 'ID',
                    name: 'id',
                    filter: 'text',
                },
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                },
                {
                    label: 'Related To',
                    name: 'relation',
                    filter: 'single-select',
                    component: 'Link',
                    options: [
                        {
                            id: 'none',
                            name: 'None'
                        },
                        {
                            id: 'any',
                            name: 'Any'
                        },
                        {
                            id: 'enterprise',
                            name: 'Enterprise'
                        },
                        {
                            id: 'ecosystem',
                            name: 'Ecosystem'
                        },
                        {
                            id: 'startup',
                            name: 'Startup'
                        },
                    ]
                },
                {
                    label: 'Is Not Company',
                    name: 'is_not_company',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'yes',
                            name: 'Yes'
                        },
                        {
                            id: 'no',
                            name: 'No'
                        },
                    ]
                },
                {
                    label: 'Hidden',
                    name: 'hidden',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'yes',
                            name: 'Yes'
                        },
                        {
                            id: 'no',
                            name: 'No'
                        },
                    ]
                },
                {
                    label: 'Ignore Duplicates',
                    name: 'ignore_duplicates',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'yes',
                            name: 'Yes'
                        },
                        {
                            id: 'no',
                            name: 'No'
                        },
                    ]
                },
                {
                    label: 'Merged To',
                    name: 'merged_to',
                },
                {
                    label: 'Duplicates Count',
                    name: 'duplicates_count',
                    component: 'Link',
                    sortable: true,
                },
                {
                    label: 'Own Startups Count',
                    name: 'own_startups_count',
                    component: 'Link',
                    sortable: true,
                },
                {
                    label: 'Merged Startups Count',
                    name: 'merged_startups_count',
                    component: 'Link',
                    sortable: true,
                },
                {
                    label: 'Categories',
                    name: 'categories',
                },
                {
                    label: 'AI response',
                    name: 'ai_response',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],

            getListMethod: clientsService.getClients
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        gridAction(params) {
            if (params.action === 'edit') {
                this.$refs.editClientForm.open(params.rowId);
            } else if (params.action === 'own_startups') {
                this.$refs.startups.open(params.rowId, 'own');
            } else if (params.action === 'merged_startups') {
                this.$refs.startups.open(params.rowId, 'merged');
            } else if (params.action === 'duplicates') {
                this.$refs.duplicates.open(params.rowId);
            } else if (params.action === 'refresh') {
                if (confirm('Refresh Relation?')) {
                    clientsService.refreshRelation(params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
